import { Color } from "@common/styles/Colors";
import { Input } from "antdv5";
import { InputProps } from "antdv5/lib";
import classNames from "classnames";
import React, { HTMLProps } from "react";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import Typography from "../Typography/Typography";
import styles from "./InputV3.module.scss";

type Props = {
	dataCy?: string;
	errorMessage?: string;
	enterButton?: boolean;
	label?: string;
	labelColor?: Color;
	onClear?: () => void;
	wrapperProps?: HTMLProps<HTMLDivElement>;
} & InputProps;

const InputV3 = ({
	errorMessage,
	enterButton,
	label,
	labelColor = "text-primary-v2",
	onClear,
	wrapperProps,
	...props
}: Props) => {
	return (
		<div
			{...wrapperProps}
			className={classNames(styles.container, {
				[styles.disabled]: props.disabled,
				[styles.error]: props.status === "error",
				[styles.enterButton]: enterButton,
				[styles.borderless]: props.variant === "borderless",
				[styles.withPrefix]: !!props.prefix,
			})}
		>
			{label && (
				<Typography
					variant="body"
					medium
					color={labelColor}
					style={{ marginBottom: 4 }}
				>
					{label}
				</Typography>
			)}
			<Input
				{...props}
				allowClear={
					props.allowClear
						? {
								clearIcon: (
									<GenemodIcon
										name="circle-cancel"
										color="text-tertiary-v2"
										onClick={onClear}
									/>
								),
						  }
						: undefined
				}
			/>
			{errorMessage && (
				<Typography
					variant="footnote"
					color="red"
					style={{ marginTop: 3 }}
				>
					{errorMessage}
				</Typography>
			)}{" "}
		</div>
	);
};

export default InputV3;
