import React from "react";
import {
	Checkbox,
	GenemodDot,
	GenemodIcon,
	Popover,
	Typography,
	UserAvatar,
} from "@common/components";
import {
	Avatar,
	Box,
	ITEM_TYPES,
	Rack,
	SearchFilterOption,
	TableItem,
	isRackFromTableItem,
} from "@common/types";
import styles from "./ColumnsGenerator.module.scss";
import {
	FreezerLayers,
	useRepository,
} from "@containers/Freezer/Repository/RepositoryContext";
import { TableEventListeners } from "antd/es/table/interface";
import { TimestampColumnTitle } from "../../TimestampColumnTitle/TimestampColumnTitle";
import moment from "moment";
import { useParams } from "@helpers/URLParams";
import { StatusColumnTitle } from "../../StatusColumnTitle/StatusColumnTitle";
import { ColorCssVarMap } from "@common/styles/Colors";
import { TypeColumnTitle } from "../../TypeColumTitle";
import { LocationColumnTitle } from "../../LocationColumnTitle/LocationColumnTitle";
import { RackLocationTooltip } from "../../LocationTooltiop";
import AssignLocationPopup from "@containers/Freezer/components/AssignLocationPopup";
import { ResponsiveTableColumns } from "@common/components/Table/ResponsiveTable";
import { ColumnProps } from "antdv5/es/table";
import { on } from "events";
import { ExpiresOnColumnTitle } from "../../ExpiresOnColumnTitle/ExpiresOnColumnTitle";
import { Layer } from "@common/components/LayerSystemContainer/LayerSystemContainer";

type CommonColumnProps = {
	key: string;
	width: number | string;
	onCell: (item: TableItem) => TableEventListeners;
	displayIsArchive?: boolean;
	title?: string | JSX.Element;
	type?: FreezerLayers;
};

const disableOnHeaderCellColumns = [
	"location",
	"type",
	"added_at",
	"created_at",
	"updated_at",
	"status",
	"expires_on",
];

export function useRepositoryTableColumnGenerator() {
	// Retrieve parameters and methods from your hooks
	const params = new URLSearchParams(location.search);
	const { setParamOnURL, updateMultipleParamOnURL, resetParamsOnURL } =
		useParams();
	const { selectedItems, setSelectedItems, data, selectedLayer, filters } =
		useRepository();
	const {
		addedAtGte,
		addedAtLte,
		createdBy,
		updatedAtGte,
		updatedAtLte,
		updatedBy,
		expiresOnGte,
		expiresOnLte,
		statusIn,
		sortValuesIndex,
		itemItemType,
		boxItemType,
		itemGrouptItemType,
	} = filters;

	const itemTypeLayersFilter: { [key in FreezerLayers]?: number[] } = {
		ITEM: itemItemType,
		ITEMGROUP: itemGrouptItemType,
		BOX: boxItemType,
	};

	// Generate column for created timestamp
	const createdAtColumnGenerator = ({
		key,
		width,
		onCell,
	}: CommonColumnProps) => ({
		key,
		dataIndex: key,
		sorter: true,
		title: (
			<TimestampColumnTitle
				type="Created"
				timestampLte={addedAtLte}
				timestampGte={addedAtGte}
				setTimestampGte={(value) => setParamOnURL("addedAtGte", value)}
				setTimestampLte={(value) => setParamOnURL("addedAtLte", value)}
				timestampCheckboxes={createdBy}
			/>
		),
		width,
		render: (data: string, obj: TableItem) => (
			<div className={styles.spacing}>
				{obj.created_by ? (
					<UserAvatar user={obj.created_by as Avatar} />
				) : null}
				{moment(data).format("LL")}
			</div>
		),
		onCell,
	});

	const updatedAtColumnGenerator = ({
		width,
		onCell,
	}: Omit<CommonColumnProps, "key">) => ({
		key: "updated_at",
		dataIndex: "updated_at",
		sorter: true,
		title: (
			<TimestampColumnTitle
				type="Updated"
				timestampLte={updatedAtLte}
				timestampGte={updatedAtGte}
				setTimestampGte={(value) =>
					setParamOnURL("updatedAtGte", value)
				}
				setTimestampLte={(value) =>
					setParamOnURL("updatedAtLte", value)
				}
				timestampCheckboxes={updatedBy}
			/>
		),
		width,
		render: (data: string, obj: TableItem) => (
			<div className={styles.spacing}>
				{obj.updated_by ? (
					<UserAvatar user={obj.updated_by as Avatar} />
				) : null}
				{moment(data).format("LL")}
			</div>
		),
		onCell,
	});

	const expiresAtColumnGenerator = ({
		width,
		onCell,
	}: Omit<CommonColumnProps, "key">) => ({
		key: "expiration_date",
		title: (
			<ExpiresOnColumnTitle
				expiresOnGte={expiresOnGte}
				expiresOnLte={expiresOnLte}
				setExpiresOnLte={(value) => {
					setParamOnURL("expiresOnLte", value);
				}}
				setExpiresOnGte={(value) => {
					setParamOnURL("expiresOnGte", value);
				}}
			/>
		),
		width: width,
		dataIndex: "expiration_date",
		render: (data: any) => {
			return data ? moment(data).format("LL") : "-";
		},
		sorter: true,
		onCell,
	});

	// Generate column for status
	const statusColumnGenerator = ({
		width,
		onCell,
	}: Omit<CommonColumnProps, "key">) => ({
		key: "status",
		sorter: true,
		dataIndex: "is_archived",
		title: (
			<StatusColumnTitle
				statuses={[]}
				statusCheckboxes={statusIn}
				updateMultipleParamOnURL={updateMultipleParamOnURL}
				resetParamOnURL={resetParamsOnURL}
			/>
		),
		render: (is_archived: boolean) => (
			<div className={styles.spacing}>
				{is_archived ? (
					<>
						<GenemodDot color={ColorCssVarMap["neutral-gray"]} />
						<Typography>Archived</Typography>
					</>
				) : (
					<>
						<GenemodDot color={ColorCssVarMap["green"]} />
						<Typography>Active</Typography>
					</>
				)}
			</div>
		),
		onCell,
		width,
	});

	const itemTypeColumnGenerator = ({
		key,
		dataIndex,
		title,
		fieldName,
		filterOptions,
		width,
		type: layer,
	}: CommonColumnProps & {
		title: string;
		dataIndex: string;
		fieldName: string;
		filterOptions: SearchFilterOption[];
	}) => ({
		key,
		dataIndex,
		title: (
			<TypeColumnTitle
				title={title}
				fieldName={fieldName}
				typeCheckboxes={layer ? itemTypeLayersFilter[layer] || [] : []}
				types={filterOptions || []}
			/>
		),
		width,
		render: (data: any) => {
			if (typeof data === "number" && data in ITEM_TYPES) {
				return <Typography>{(ITEM_TYPES as any)[data]}</Typography>;
			} else {
				const itemType = filterOptions.find(
					({ value }: any) => value === data
				);
				return <Typography>{itemType?.name}</Typography>;
			}
		},
	});

	const boxDimensionColumnGenerator = ({
		width,
	}: Omit<CommonColumnProps, "key">) => ({
		key: "dimension",
		title: "Dimension",
		dataIndex: "dimension",
		render: (_: any, obj: TableItem) => {
			const box = obj as Box;
			return (
				<Typography>
					{box.rows} x {box.columns}
				</Typography>
			);
		},
		width,
	});

	const commonNameGenerator = ({
		key,
		displayIsArchive,
		width,
		title,
		onCell,
	}: CommonColumnProps) => ({
		key: key,
		dataIndex: key,
		title: title,
		width: width,
		render: (name: string, item: TableItem) => {
			return (
				<div className={styles.nameColumn}>
					{displayIsArchive && item.is_archived && (
						<GenemodIcon name="archive" size="medium" />
					)}
					<Typography>{name}</Typography>
				</div>
			);
		},
		sorter: true,
		onCell,
	});

	const getCheckboxValue = (): boolean | "complicated" => {
		const currentCollection = data[selectedLayer as FreezerLayers]?.results;
		if (!currentCollection) return false;

		const isSelectedAll = selectedItems.length === currentCollection.length;
		const isSelectedSome =
			selectedItems.length > 0 &&
			selectedItems.length !== currentCollection.length;

		return isSelectedSome ? "complicated" : isSelectedAll;
	};

	const onChangeCheckbox = (val: boolean | "complicated") => {
		if ((val as boolean) === true && selectedLayer) {
			setSelectedItems(data[selectedLayer]?.results || []);
		} else {
			setSelectedItems([]);
		}
	};

	const checkBoxColumnGenerator = ({
		onChange,
		titleValue,
		selected,
		width,
		setSelected,
		onSelect,
	}: {
		onChange?: (val: boolean | "complicated") => void;
		titleValue?: boolean | "complicated";
		selected?: TableItem[];
		width: number | string;
		setSelected?: React.Dispatch<React.SetStateAction<TableItem[]>>;
		onSelect?: (obj: TableItem) => void;
	}) => {
		const selectedObjects = selected || selectedItems;
		const setSelectedObjects = setSelected || setSelectedItems;
		const onChangeCheck = onChange || onChangeCheckbox;
		const value = titleValue || getCheckboxValue();

		return {
			key: "checkbox",
			title: () => {
				return (
					<div className={styles.checkboxTable}>
						<Checkbox
							value={value}
							onChange={onChangeCheck}
							dataCy="selectAll"
						/>
					</div>
				);
			},
			dataIndex: "",
			render: (_: any, obj: TableItem) => {
				return (
					<div className={styles.checkboxTable}>
						<Checkbox
							value={selectedObjects.some((o) => o.id === obj.id)}
							onChange={(val) => {
								if ((val as boolean) === true) {
									if (onSelect) {
										onSelect(obj);
									} else {
										setSelectedObjects((prev) => [
											...prev,
											obj,
										]);
									}
								} else {
									const arrayCopy = [...selectedObjects];
									setSelectedObjects(
										arrayCopy.filter((i) => i.id !== obj.id)
									);
								}
							}}
						/>
					</div>
				);
			},
			fixed: "left",
			width,
		};
	};

	const rackDimensionColumnGenerator = ({
		width,
		onCell,
	}: Omit<CommonColumnProps, "key">) => ({
		key: "dimension",
		sorter: false,
		dataIndex: "dimension",
		title: "Dimension",
		render: (_: any, rack: any) => {
			if (rack.rows && rack.columns) {
				return (
					<Typography>{`${rack.rows}x${rack.columns}`}</Typography>
				);
			} else {
				return "";
			}
		},
		onCell,
		width,
	});

	const rackAvailabilityColumnGenerator = ({
		width,
		onCell,
	}: Omit<CommonColumnProps, "key">) => ({
		key: "availability",
		sorter: true,
		dataIndex: "availability",
		title: "Availability",
		width,
		onCell,
	});

	const locationColumnGenerator = ({
		width,
		locations,
		onLocationSelect,
		allowAssignLocation,
		assignLocationPopupOpen,
		handleAssignLocationPopupOpen,
	}: {
		width: number | string;
		locations: SearchFilterOption[];
		onLocationSelect?: (
			itemId: number,
			locationId: number,
			tableItem: TableItem | null
		) => void;
		allowAssignLocation?: boolean;
		assignLocationPopupOpen?: number;
		handleAssignLocationPopupOpen?: (
			visible: boolean,
			itemId: number
		) => void;
	}) => ({
		key: "location",
		title: () => (
			<LocationColumnTitle
				locations={locations}
				placeholder="Find shelf"
			/>
		),
		dataIndex: "location_data",
		render: (data: any, item: TableItem) => {
			item = item as any as Rack;
			if (item.is_archived && !item.location_data) return "-";
			const isAssignLocationOpen = assignLocationPopupOpen === item.id;
			return (
				<div>
					{item.location_data && (
						<RackLocationTooltip item={item} data={data} />
					)}
					{allowAssignLocation &&
						isRackFromTableItem(item) &&
						onLocationSelect &&
						handleAssignLocationPopupOpen &&
						!item.location && (
							<Popover
								trigger="click"
								content={
									isAssignLocationOpen ? (
										<AssignLocationPopup
											onSelect={(shelfId, shelf) => {
												onLocationSelect(
													item.id,
													shelfId,
													shelf
												);
											}}
										/>
									) : undefined
								}
								visible={isAssignLocationOpen}
								onVisibleChange={(visible) =>
									handleAssignLocationPopupOpen(
										visible,
										item.id
									)
								}
							>
								<div className={styles.locationContainer}>
									<div className={styles.noLocation}>-</div>
									<Typography
										className={styles.assignLocation}
										color="button-text"
									>
										Assign Location
									</Typography>
								</div>
							</Popover>
						)}
				</div>
			);
		},
		width,
	});

	const addCommonHeaderCellToColumns = ({
		columns,
		onResize,
	}: {
		columns: ResponsiveTableColumns<TableItem>[];
		onResize?: (index: number) => void;
	}): ResponsiveTableColumns<TableItem>[] =>
		columns.map((col, index) => ({
			...col,
			onHeaderCell: (col) =>
				({
					width: col.width,
					onResize: onResize ? onResize(index) : undefined,
					onClick: (e: any) => {
						// Helper function to check if the target is inside a parent with a given selector
						function isInside(
							target: Element,
							selector: string,
							maxDepth = 4
						) {
							let depth = 0;
							while (target && depth < maxDepth) {
								if (target.matches(selector)) {
									return true;
								}
								if (target.parentElement) {
									target = target.parentElement;
								}
								depth++;
							}
							return false;
						}

						const isExcluded = disableOnHeaderCellColumns.includes(
							col.key as string
						);
						const targetAsElement = e.target as Element;
						const fieldName = col.key;
						if (
							(!isExcluded ||
								isInside(
									targetAsElement,
									"span.ant-table-column-sorter"
								) ||
								targetAsElement.matches(
									"div.ant-table-column-sorters"
								) ||
								targetAsElement.matches(".active-ordering")) &&
							fieldName !== "checkbox"
						) {
							const currentOrdering = params.get("ordering");
							if (fieldName !== currentOrdering) {
								setParamOnURL("ordering", fieldName as string);
								setParamOnURL("sortValuesIndex", "1");
								return;
							}
							// Shift val to next value
							const values = [true, false, null];
							const nextValue = Number(sortValuesIndex) + 1;
							setParamOnURL(
								"sortValuesIndex",
								nextValue.toString()
							);
							if (Number(sortValuesIndex) === values.length - 1) {
								setParamOnURL("sortValuesIndex", "0");
							}
						}
					},
				} as TableEventListeners),
		}));

	return {
		createdAtColumnGenerator,
		updatedAtColumnGenerator,
		expiresAtColumnGenerator,
		statusColumnGenerator,
		itemTypeColumnGenerator,
		rackDimensionColumnGenerator,
		commonNameGenerator,
		rackAvailabilityColumnGenerator,
		boxDimensionColumnGenerator,
		checkBoxColumnGenerator,
		locationColumnGenerator,
		addCommonHeaderCellToColumns,
	};
}
