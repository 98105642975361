import React from "react";
import { Sort, Typography, GenemodIcon } from "@components";
import {
	LocalStorageKey,
	useLocalStorage,
} from "@helpers/Hooks/UseLocalStorageHook";
import styles from "./ListGridContainer.module.scss";
import classNames from "classnames";
import { ValuesOf } from "@helpers/TypeHelpers";
import GridViewToggle from "../GridViewToggle";
import { UserSettingsKey } from "@common/types/UserPreference";
import { usePreferences } from "@helpers/Hooks/UsePreferencesHook";

export type Layout = "GRID" | "LIST";

export type ListGridContainerProps<T extends Record<string, number>> = {
	title: React.ReactNode;
	name: React.ReactNode;
	addOptions?: {
		title: string;
		onClick?: () => void;
	};
	sortOptions?: {
		storageKey?: UserSettingsKey;
		sortList: T;
		defaultSort?: ValuesOf<T>;
		onChange?: (sort: ValuesOf<T>) => void;
	};
	layoutOptions?: {
		storageKey?: UserSettingsKey;
		onChange?: (layout: Layout) => void;
	};
	getContent: (sort: ValuesOf<T>, layout: Layout) => React.ReactNode;
	// todo: filter options?
};

// Common component
export default function ListGridContainer<T extends Record<string, number>>({
	title,
	name,
	addOptions,
	sortOptions,
	layoutOptions,
	getContent,
}: ListGridContainerProps<T>) {
	const showAddButton = !!addOptions;
	const showSort = !!sortOptions;
	const showLayout = !!layoutOptions;

	const [_sort, setSort] = usePreferences(sortOptions?.storageKey || "DUMMY");
	const sort =
		(_sort as ValuesOf<T>) ||
		sortOptions?.defaultSort ||
		(0 as ValuesOf<T>);

	const [_layout, setLayout] = usePreferences(
		layoutOptions?.storageKey || "DUMMY"
	);
	const layout = (_layout as Layout) || "GRID";

	const onLayoutClicked = (localLayout: Layout) => {
		layoutOptions?.onChange?.(localLayout);
		setLayout(localLayout);
	};

	// Convert to enum style object (ie: { 0: "a", a: 0})
	// Stringifying values manually because there seems to be a bug in Chrome where the computed property of the value is being stringified as [object Object].
	const sortList = Object.entries(sortOptions?.sortList || {}).reduce(
		(obj, [key, value]) => {
			return {
				...obj,
				[key]: value + "",
				[value + ""]: key,
			};
		},
		{}
	);

	return (
		<>
			<div className={styles.listGridPanel}>
				<div className={styles.header}>
					<Typography variant="caption">{title}</Typography>
					<div
						className={classNames(styles.subheader, styles.flexrow)}
					>
						<div className={styles.nameAndIcon}>
							<Typography
								ellipsis
								className={styles.nameCol}
								variant="largeTitle"
								bold
							>
								{name}
							</Typography>
							{showAddButton && (
								<GenemodIcon
									name="plus-circle"
									size="large"
									hoverEffect
									onClick={() => addOptions?.onClick?.()}
								/>
							)}
						</div>

						<div
							className={classNames(
								styles.sortAndLayoutViewCol,
								styles.flexrow
							)}
							style={{ gap: "16px" }}
						>
							{showSort && (
								<Sort
									sortList={sortList}
									defaultValue={sort}
									onChange={(v) => {
										const value = v as typeof sort;
										sortOptions?.onChange?.(value);
										setSort(value);
									}}
								/>
							)}
							{showLayout && (
								<GridViewToggle
									isGrid={layout === "GRID"}
									onGrid={() => onLayoutClicked("GRID")}
									onList={() => onLayoutClicked("LIST")}
								/>
							)}
						</div>
					</div>
				</div>
				<div className={styles.content}>{getContent(sort, layout)}</div>
			</div>
		</>
	);
}

export const LISTGRIDCONTAINER_DEMO = () => {
	const SORT = {
		"Recently created": 0,
		"Category modified": 1,
		"Name (a-z)": 2,
	} as const;
	return (
		<ListGridContainer<typeof SORT>
			title="Test list grid"
			name={<div style={{ color: "green" }}>Test name</div>}
			addOptions={{
				title: "Add nothing!",
			}}
			sortOptions={{
				sortList: SORT,
			}}
			layoutOptions={{}}
			getContent={(sort, layout) => {
				return (
					<div>
						Sort: {sort} Layout: {layout}
					</div>
				);
			}}
		/>
	);
};
