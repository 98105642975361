import React from "react";
import { FreezerLayers, useRepository } from "../../RepositoryContext";
import {
	ResponsiveTable,
	Typography,
	LayerSystemContainer,
	SearchBar,
	EmptyState,
} from "common/components";
import { ResponsiveTableColumns } from "@common/components/Table/ResponsiveTable";
import {
	Box,
	Category,
	ITEM_GROUP_CONTAINERS,
	Rack,
	TableItem,
	isRack,
	isItem,
	Item,
	ItemGroup,
} from "@common/types";
import { TableEventListeners } from "antd/lib/table";
import { useRepositoryTableColumnGenerator } from "../Tables/ColumnsGenerator/ColumnsGenerator";
import classNames from "classnames";
import tableSyles from "../Tables/TableStyles.module.scss";
import { TypeColumnTitle } from "../TypeColumTitle";
import FreezerTabStyles from "../FreezerTab.module.scss";
import { LocationColumnTitle } from "../LocationColumnTitle/LocationColumnTitle";
import { LocationTooltip } from "../LocationTooltiop";
import EMPTY_STATE_IMG_LIGHT from "../../assets/repository-empty-illus-light.png";
import EMPTY_STATE_IMG_DARK from "../../assets/repository-empty-illus-dark.png";
import { useSearchParams } from "@helpers/URLParams";
import { useRouteMatch } from "react-router-dom";
import { FREEZER_PATHS } from "@root/routes";
import { useOrganizationRouter } from "@root/AppRouter";
import { useItemTypeQuery, useItemTypesQuery } from "@redux/inventory/Item";

export function useUnarchiveTables(): {
	[key in FreezerLayers]: {
		[key: string]: ResponsiveTableColumns<TableItem>;
	};
} {
	const { restorableResults, filters } = useRepository();
	const {
		commonNameGenerator,
		rackAvailabilityColumnGenerator,
		locationColumnGenerator,
		rackDimensionColumnGenerator,
		boxDimensionColumnGenerator,
		updatedAtColumnGenerator,
		createdAtColumnGenerator,
		expiresAtColumnGenerator,
		itemTypeColumnGenerator,
	} = useRepositoryTableColumnGenerator();

	const onCell = (item: TableItem): TableEventListeners => {
		return {
			onClick: () => {
				console.log("CLIK");
			},
		};
	};

	const nameColumnGenerator = (type: FreezerLayers) =>
		commonNameGenerator({
			key: "name",
			title: `${type.charAt(0) + type.slice(1).toLowerCase()} name`,
			displayIsArchive: false,
			width: 400,
			onCell,
		});

	return {
		FREEZER: {},
		SHELF: {},
		RACK: {
			rackName: nameColumnGenerator("RACK"),
			location: locationColumnGenerator({
				width: 192,
				locations: (restorableResults.RACK as any)?.filter_options
					?.location__shelf,
				allowAssignLocation: false,
			}),
			availability: rackAvailabilityColumnGenerator({
				width: 140,
				onCell,
			}),
			updated_at: updatedAtColumnGenerator({ width: 192, onCell }),
			created_at: createdAtColumnGenerator({
				key: "created_at",
				width: 192,
				onCell,
			}),
			dimension: rackDimensionColumnGenerator({ width: 140, onCell }),
		},
		CATEGORY: {
			categoryName: nameColumnGenerator("CATEGORY"),
			location: locationColumnGenerator({
				width: 192,
				locations: (restorableResults.CATEGORY as any)?.filter_options
					?.location__shelf,
				allowAssignLocation: false,
			}),
			updated_at: updatedAtColumnGenerator({ width: 192, onCell }),
			created_at: createdAtColumnGenerator({
				key: "created_at",
				width: 192,
				onCell,
			}),
			box_numbers: {
				key: "boxes_number",
				sorter: true,
				dataIndex: "boxes_number",
				title: "# of boxes",
				width: 140,
			},
			item_group_numbers: {
				key: "item_groups_number",
				sorter: true,
				dataIndex: "item_groups_number",
				title: "# of item groups",
				width: 140,
			},
		},
		ITEMGROUP: {
			boxName: nameColumnGenerator("ITEMGROUP"),
			item_type: itemTypeColumnGenerator({
				key: "item_type",
				dataIndex: "item_type",
				title: "Item type",
				fieldName: "itemGrouptItemType",
				filterOptions:
					(restorableResults.ITEMGROUP as any)?.filter_options
						.item_type || [],
				width: 140,
				onCell,
				type: "ITEMGROUP",
			}),
			updated_at: updatedAtColumnGenerator({
				width: 192,
				onCell,
			}),
			created_at: createdAtColumnGenerator({
				key: "created_at",
				width: 192,
				onCell,
			}),
			item_count: {
				key: "item_count",
				sorter: true,
				dataIndex: "item_count",
				title: "# of items",
				width: 140,
			},
			container_type: {
				key: "container_type",
				sorter: true,
				dataIndex: "container_type",
				title: (
					<TypeColumnTitle
						title="Container"
						fieldName="containerType"
						typeCheckboxes={filters.containerType}
						types={
							(restorableResults.ITEMGROUP as any)?.filter_options
								.container_type || []
						}
					/>
				),
				render: (data) => {
					return (
						<Typography>
							{
								ITEM_GROUP_CONTAINERS[
									data as keyof typeof ITEM_GROUP_CONTAINERS
								]
							}
						</Typography>
					);
				},
				width: 190,
			},
			container_volume: {
				key: "container_volume",
				sorter: true,
				dataIndex: "container_volume",
				title: "Container volume",
				width: 190,
			},
			description: {
				key: "description",
				sorter: true,
				dataIndex: "description",
				title: "Description",
				width: 190,
			},
		},
		BOX: {
			boxName: nameColumnGenerator("BOX"),
			availability: {
				key: "availability",
				dataIndex: "availability",
				title: "Availability",
				width: 140,
				render: (availability) => (
					<Typography>{availability}</Typography>
				),
				sorter: true,
			},
			item_type: itemTypeColumnGenerator({
				key: "item_type",
				dataIndex: "item_type",
				title: "Item type",
				fieldName: "types",
				filterOptions:
					(restorableResults.BOX as any)?.filter_options?.item_type ||
					[],
				width: 140,
				onCell,
				type: "BOX",
			}),
			updated_at: updatedAtColumnGenerator({
				width: 192,
				onCell,
			}),
			created_at: createdAtColumnGenerator({
				key: "created_at",
				width: 192,
				onCell,
			}),
			dimension: boxDimensionColumnGenerator({
				width: 140,
				onCell,
			}),
		},
		ITEM: {
			name: nameColumnGenerator("ITEM"),
			location: {
				key: "location",
				title: () => (
					<LocationColumnTitle
						locations={
							(restorableResults.ITEM as any)?.filter_options
								?.location__box_location__box || []
						}
						placeholder="Find box"
					/>
				),
				dataIndex: "location_data",
				render: (data, item) => {
					return (
						<div>
							{item.location_data ? (
								<LocationTooltip
									item={item}
									data={data}
									layer="ITEM"
								/>
							) : (
								<Typography>{" - "}</Typography>
							)}
						</div>
					);
				},
				width: 192,
			},
			type: itemTypeColumnGenerator({
				key: "key",
				dataIndex: "item_type",
				title: "Item type",
				fieldName: "boxItemType",
				filterOptions:
					(restorableResults.ITEM as any)?.filter_options
						?.item_type || [],
				width: 140,
				onCell,
				type: "ITEM",
			}),
			updated_at: updatedAtColumnGenerator({
				width: 192,
				onCell,
			}),
			created_on: createdAtColumnGenerator({
				key: "added_at",
				width: 192,
				onCell,
			}),
			expires_on: expiresAtColumnGenerator({
				width: 192,
				onCell,
			}),
			concentration: {
				key: "concentration",
				title: "Concentration",
				width: 140,
				dataIndex: "concentration",
				render: (data, obj) => {
					return data ? data : "-";
				},
				sorter: true,
				onCell,
			},
			vol_mass: {
				key: "volume",
				title: "Vol/Mass",
				dataIndex: "volume",
				width: 140,
				sorter: true,
				onCell,
			},
		},
	};
}

function UnarchiveTable({
	layer,
}: {
	layer: Omit<FreezerLayers, "FREEZER" | "SHELF">;
}) {
	const { appendBaseUrl } = useOrganizationRouter();
	const freezerRoute = useRouteMatch<{ id: string }>({
		path: appendBaseUrl(FREEZER_PATHS.CONTENTS),
	});
	const tables = useUnarchiveTables();
	const {
		restorableResults,
		isRestorableLoading: isLoading,
		selectedLayer,
		search,
		setSearch,
		handleSearchText,
		debounceSearch,
		selectedItems,
		setSelectedItems,
		selectedParent,
	} = useRepository();
	const { addCommonHeaderCellToColumns, checkBoxColumnGenerator } =
		useRepositoryTableColumnGenerator();
	const layerData = (restorableResults as any)[layer as any]?.results || [];

	const onAddCheckBox = (item: TableItem) => {
		if (selectedLayer === "BOX") {
			if (!selectedParent) return;
			const is_rack = isRack(selectedParent as Rack | Category);
			const box = item as Box;

			// If its a rack, the relocation happens when you organize the boxes on the rack
			if (!is_rack) {
				setSelectedItems([
					...selectedItems,
					{
						...box,
						location: {
							...box.location,
							rack_location: null,
							category: selectedParent.id,
						},
					},
				]);
				return;
			}
		}
		if (selectedLayer === "ITEMGROUP") {
			const itemGroup = item as ItemGroup;
			setSelectedItems([
				...selectedItems,
				{
					...itemGroup,
					location: {
						...itemGroup.location,
						freezer: Number(freezerRoute?.params.id),
						category: Number(selectedParent?.id) as number,
					},
				},
			]);
			return;
		}
		if (selectedLayer === "RACK") {
			if (!selectedParent) return;
			const rack = item as Rack;
			setSelectedItems([
				...selectedItems,
				{
					...rack,
					location: {
						...rack.location,
						freezer: selectedParent.freezer,
						shelf: selectedParent.id,
					},
				},
			] as any);
			return;
		}

		setSelectedItems([...selectedItems, item]);
	};

	const checkBoxColumn = checkBoxColumnGenerator({
		width: 32,
		onSelect: onAddCheckBox,
	});

	const cols: ResponsiveTableColumns<TableItem>[] = [checkBoxColumn as any];

	const columns = cols.concat(Object.values((tables as any)[layer as any]));

	const mergeColumns = addCommonHeaderCellToColumns({
		columns: columns,
	});

	const selectedIds = selectedItems.map((item) => item.id);
	return (
		<>
			<div className="flex items-center justify-between py-2">
				<Typography>{`Select up to ${layerData.length} items: ${selectedItems.length} Selected`}</Typography>
				<div className={FreezerTabStyles.searchInputContainer}>
					<LayerSystemContainer>
						<SearchBar
							iconPosition="left"
							placeholder={`Search by ${selectedLayer?.toLowerCase()} name`}
							value={search}
							onChange={(val) => {
								setSearch(val);
								if (val.length === 0 || val.trim()) {
									debounceSearch(val);
								}
							}}
							onSelect={() => handleSearchText(search)}
							disableSuggestions
							wrapperProps={{
								style: {
									width: "320px",
									height: "36px",
								},
							}}
							dataCy="repository-search"
						/>
					</LayerSystemContainer>
				</div>
			</div>
			<div className="mb-8">
				<ResponsiveTable
					loading={isLoading}
					rowClassName={(column) =>
						classNames({
							"responsive-table-row": true,
							[tableSyles.selectedRow]: selectedIds.includes(
								column.id
							),
						})
					}
					columns={mergeColumns}
					dataSource={layerData}
					bordered
					locale={{
						emptyText: isLoading ? (
							<div style={{ height: "40vh" }} />
						) : (
							<EmptyState
								img={{
									dark: EMPTY_STATE_IMG_DARK,
									light: EMPTY_STATE_IMG_LIGHT,
								}}
								imgStyle={{
									width: "480px",
									height: "220px",
									margin: "16px 0 32px",
								}}
								title={"No elements to be restored"}
								dataCy="no-elements-to-be-restored"
								descriptionColor="text-tertiary"
								centerTitle
							/>
						),
					}}
					className={tableSyles.table}
				/>
			</div>
		</>
	);
}

export default UnarchiveTable;
