import { shouldShowTime } from "@common/components/CustomDatePickerV2/CustomDatePickerV2";
import { CustomDatePickerV2 } from "@components";
import cn from "classnames";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Range, Transforms } from "slate";
import { ReactEditor, useSelected } from "slate-react";
import { MentionInlineDisplay } from "../../../Mention/Mention";
import { DayEditor, DayElement } from "../../formats/Day";
import styles from "./index.module.scss";

type NewDayProps = {
	node: DayElement;
	children: React.ReactNode;
	editor: DayEditor;
	focusAfterRender?: boolean;
};

/**
 * Component for setting protocol days
 */
export const NewDay = forwardRef<HTMLSpanElement, NewDayProps>((props) => {
	const dateFormat = "MM/DD/YYYY";
	const editor = props.editor;
	const isSelected = useSelected();
	const { node, focusAfterRender = false } = props;
	const [open, setOpen] = useState(focusAfterRender);
	const datepickerRef = useRef<HTMLElement>();
	const datepickerContainerRef = useRef<HTMLElement>();
	const dayPermission =
		editor.experimentIsEditable && editor.permissions.date;
	const [validDate, setValidDate] = useState<Dayjs | null>(
		dayjs(node.date) || null
	);
	const inputRef = useRef<HTMLInputElement>(null);
	const componentRef = useRef<HTMLDivElement>(null);

	const handleSetDate = (date: Dayjs | null) => {
		const newDate = date ? date : null;
		editor.setDate(node.id, newDate ? moment(newDate.toDate()) : null);
		setValidDate(newDate);
		setOpen(false);
	};

	const getDate = () => {
		return dayjs(validDate).format(
			`dddd, MMMM D, YYYY${shouldShowTime(validDate) ? " hh:mm A" : ""}`
		);
	};

	const handleVisible = (v: boolean) => {
		if (!dayPermission) return;
		setOpen(v);
	};

	// useEffect(() => {
	// 	handleVisible(true);
	// }, []);

	// Auto-focus the datepicker input field so that users can use Escape to close the datepicker
	useEffect(() => {
		// Refocus the editor when closing the datepicker
		if (!open) {
			ReactEditor.focus(editor);
			// Return selection to before the datepicker was open
			if (!editor.selection && editor.blurSelection) {
				Transforms.select(editor, editor.blurSelection);
			}
		} else if (open && datepickerRef.current) {
			// PM-335: The selection needs to be set to null while
			// the datepicker is open. Inserting nodes while the datepicker is open
			// will invalidate the blurSelection and mess up the refocus logic above
			editor.selection = null;
			datepickerRef.current.focus();
		}
	}, [editor, open, datepickerRef]);

	// Allow user to open the datepicker by pressing enter when it is within the selection
	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			if (
				componentRef.current &&
				!componentRef.current.contains(e.target as Node)
			) {
				return;
			}
			if (e.key === "Escape") {
				setOpen(false);
				return;
			}
			if (
				isSelected &&
				editor.selection &&
				Range.isCollapsed(editor.selection)
			) {
				if (e.key === "Enter") {
					handleVisible(true);
					ReactEditor.blur(editor);
					setTimeout(() => {
						inputRef.current?.focus();
					}, 100);
				} else {
					// prevent to insert any texts on Day node.
					e.stopPropagation();
					e.preventDefault();
				}
			}
		};

		window.addEventListener("keydown", listener);
		return () => {
			window.removeEventListener("keydown", listener);
		};
	});

	useEffect(() => {
		const mouseDownListener = (e: MouseEvent) => {
			if (!open) return;
			if (datepickerContainerRef.current?.contains(e.target as Node)) {
				return;
			}
			handleVisible(false);
		};

		if (open) {
			window.addEventListener("mousedown", mouseDownListener);
		}

		return () => {
			window.removeEventListener("mousedown", mouseDownListener);
		};
	}, [open]);

	useEffect(() => {
		if (node.date) {
			setValidDate(dayjs(node.date));
		} else {
			setValidDate(null);
		}
	}, [node.date]);

	return (
		<span ref={componentRef}>
			<MentionInlineDisplay
				className={cn({
					[styles.empty]: !validDate,
					[styles.static]: !dayPermission,
				})}
				active={open || isSelected}
				onClick={() => {
					if (!open) handleVisible(true);
				}}
				visibilityProps={{
					visible: open,
					onVisibleChange: () => {},
				}}
				overlay={
					<div
						className={cn(styles.datepickerContainer, {
							[styles.datepickerContainer__open]: open,
						})}
						contentEditable={false}
						onClick={(e) => e.stopPropagation()}
					>
						<CustomDatePickerV2
							forwardRef={datepickerRef}
							containerRef={datepickerContainerRef}
							allowClear
							open
							onChange={handleSetDate}
							popupClassName={styles.dropdown}
							value={validDate}
						/>
					</div>
				}
				content={<>{node.date ? getDate() : "Set date"}</>}
				icon={"new-day"}
			/>
			<div className={styles.cursor}>{props.children}</div>
		</span>
	);
});
