import { Tag } from "@common/components/Metadata/Tags";
import { ConsumableSearchItem } from "./ConsumableSearch";
import { Item } from "./Item";
import { CustomFields } from "./Metadata";
import { MetadataUUID } from "./MetadataUUID";

export type LinkedFile = {
	file: string;
	file_id: number;
	parent_project_id: number;
	parent_project: string;
	parent_folder: string | null;
	parent_folder_id: number | null;
};

export type ProjectLink = {
	id: string;
	file: LinkedFile;
};

export type ExperimentMetadata = {
	uuid: MetadataUUID;
	organization_prefix: string;
	labels: string[];
	custom_fields: CustomFields;
	tags: Tag[];
	mentioned_projects: ProjectLink[];
	backlinks: ProjectLink[];
	items: Item[];
	consumables: ConsumableSearchItem[];
};
