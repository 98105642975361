import { NominalId } from "@helpers/TypeHelpers";
import {
	Box,
	Consumable,
	FurnitureCategory,
	Avatar,
	ISOString,
	PaginatedSearchQuery,
	ItemGroup,
} from ".";

// ~~~~~~~~~~~~~~~~~~~~ COMMON ~~~~~~~~~~~~~~~~~~~~
export type StockStatus = "IN_STOCK" | "RUNNING_LOW" | "REORDERED";

export type RunningLowResults = {
	freezer: number;
	consumable: number;
};

export type CommonAlertsResult = {
	name: string;
	alert_threshold: number;
	quantity: number;
	reordered: boolean;
	stock_status: StockStatus;
	org_user: number;
	organization: number;
	created_at: ISOString;
	update_at: ISOString;
	updated_by: number;
};

export type StockAlertsRunningLowResult = {
	count: number;
};

export type AlertSearchParams = PaginatedSearchQuery & {
	stock_status: StockStatus[];
	ordering: string | null;
};

// ~~~~~~~~~~~~~~~~~~~~ FREEZER ~~~~~~~~~~~~~~~~~~~~

export type FreezerAlertsResultId = NominalId<"FreezerAlertsResultId">;

export type FreezerAlertScope = "BOX" | "ALL_FREEZERS" | "ITEM_GROUP";

export type FreezerAlertsResult = CommonAlertsResult & {
	id: FreezerAlertsResultId;
	box: Box;
	item_group: ItemGroup;
	scope: FreezerAlertScope;
	org_user: number | null;
};

export type FreezerAlertsCreateDto = {
	name?: string;
	box?: number | null;
	item_group?: number | null;
	alert_threshold?: number;
	org_user?: number | null;
};

export type FreezerAlertsEditDto = FreezerAlertsCreateDto & {
	id: FreezerAlertsResultId;
};

export const freezerAlertsResultToEditDto = (
	result: FreezerAlertsResult
): FreezerAlertsEditDto => ({
	id: result.id,
	name: result.name,
	box: result.box?.id,
	item_group: result.item_group?.id,
	alert_threshold: result.alert_threshold,
	org_user: result.org_user,
});

export type FreezerAlertSubtableResult = {
	quantity: number;
	id: number;
	name: string;
	freezer: number;
	is_box: boolean;
};

export type FreezerAlertScopeSearchResult = {
	path: {
		box_id: number;
		box: string;
		item_group_id: number;
		item_group: string;
		rack: string;
		shelf: string;
		freezer_id: number;
		freezer: string;
	};
	count: number;
};

// ~~~~~~~~~~~~~~~~~~~~ CONSUMABLE ~~~~~~~~~~~~~~~~~~~~

export type FreezerAlertBoxSearchQuery = PaginatedSearchQuery & {
	stock_name?: string;
	box_id?: number;
};

export type FreezerAlertBoxSearchResult = Box & {
	quantity: number;
	location_data: {
		freezer: string;
		rack: string | null;
		shelf: string;
		category: string | null;
	};
};

export type FreezerAlertItemGroupSearchResult = ItemGroup & {
	quantity: number;
	location_data: {
		freezer: string;
		rack: string | null;
		shelf: string;
		category: string | null;
	};
};

export type ConsumableAlertsResultId = NominalId<"ConsumableAlertsResultId">;

export type ConsumableAlertScopeType = "FURNITURE_CATEGORY" | "ALL_SPACES";

export type ConsumableAlertsResult = CommonAlertsResult & {
	id: ConsumableAlertsResultId;
	consumable: Consumable; // todo: remove
	furniture_category: FurnitureCategory;
	scope: ConsumableAlertScopeType;
};

export type ConsumableAlertsCreateDto = {
	name: string;
	furniture_category?: number | null;
	alert_threshold?: number;
	org_user?: number | null;
};

export type ConsumableAlertsEditDto = ConsumableAlertsCreateDto & {
	id: ConsumableAlertsResultId;
};

export const consumableAlertsResultToEditDto = (
	result: ConsumableAlertsResult
): ConsumableAlertsEditDto => ({
	id: result.id,
	name: result.name,
	furniture_category: result.furniture_category?.id,
	alert_threshold: result.alert_threshold,
	org_user: result.org_user,
});

export type ConsumableAlertSubtableResult = {
	quantity: number;
	id: number;
	name: string;
	parent_space: number;
	parent_furniture: number;
};

export type ConsumableAlertScopeSearchResult = {
	id: number;
	owner: Avatar;
	last_modified_by: Avatar;
	parent_space: number;
	quantity: number;
	path: {
		space: {
			id: number;
			name: string;
		};
		furniture: {
			id: number;
			name: string;
		};
	};
	name: string;
	description: string;
	created_at: ISOString;
	updated_at: ISOString;
	parent_furniture: number;
	org: number;
};
