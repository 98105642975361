import {
	DropDown,
	GenemodIcon,
	Notification,
	Popover,
	Typography,
	UserAvatar,
} from "@common/components";
import { useGenemodSlate } from "@common/components/Editor/hooks/UseGenemodSlateHook";
import { IconName } from "@common/components/GenemodIcon/GenemodIconRegistry";
import {
	ExperimentDocumentLinkId,
	ExperimentId,
	ExperimentTable,
	GenemodDocumentUUID,
	Notification as NotificationType,
	OrganizationUser,
	OrganizationUserId,
	STATUS_HIERARCHY,
	WorkspaceUser,
	getFullNameFromAvatar,
} from "@common/types";
import { ExperimentFile } from "@common/types/Folder";
import { truncArgs } from "@helpers/Formatters";
import {
	useDebouncedCallback,
	useSafeWindowEventListener,
} from "@helpers/Hooks";
import {
	MentionNotificationSourceType,
	SendNotificationFunctionType,
	useSendMentionNotification,
} from "@helpers/Hooks/UseNotificationHook";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import { flattenUnion } from "@helpers/TypeHelpers";
import { useParams } from "@helpers/URLParams";
import {
	useExperimentDocumentTableQuery,
	useExperimentDocumentTablesQuery,
	useExperimentLinkAddMutation,
	useExperimentLinkDeleteMutation,
	useGetExperimentFileQuery,
	useGetRecentlyOpenedExperimentFilesQuery,
	useSearchExperimentFilesQuery,
} from "@redux/ProjectManagement/PmApiSlice";
import { useGetWorkspaceUsersQuery } from "@redux/team/TeamApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useOrganizationRouter } from "@root/AppRouter";
import { Menu } from "antd";
import { Dropdown, MenuProps } from "antdv5";
import { ItemType } from "antdv5/es/menu/hooks/useItems";
import classNames from "classnames";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "slate";
import { ReactEditor } from "slate-react";
import { DAY_TYPE, DayElement } from "../Editor/formats/Day";
import {
	MentionContext,
	MinimalMentionEditor,
	mentionHelpers,
} from "../Editor/plugins/withMentionsPlugin";
import { CustomEditor } from "../Editor/types";
import styles from "./Mention.module.scss";

export type MentionTypeData =
	| {
			type: "experiment";
			id: ExperimentId;
	  }
	| {
			type: "table";
			id: GenemodDocumentUUID;
	  }
	| {
			type: "person";
			id: OrganizationUserId;
	  };

type MentionsProps = {
	context: MentionContext;
	active: boolean;
	onClick: () => void;
	typeData: MentionTypeData;
	notificationId?: number;
	experimentDocumentLink?: ExperimentDocumentLinkId;
};

export function Mention({
	context,
	typeData,
	active,
	onClick,
	notificationId,
	experimentDocumentLink,
}: MentionsProps): JSX.Element | null {
	const { type, id } = typeData;
	if (type === "experiment") {
		return (
			<ExperimentMention
				context={context}
				id={id}
				active={active}
				onClick={onClick}
				experimentDocumentLink={experimentDocumentLink}
			/>
		);
	}

	if (type === "table") {
		return (
			<TableMention
				context={context}
				id={id}
				active={active}
				onClick={onClick}
			/>
		);
	}

	return (
		<UserMention
			context={context}
			id={id}
			active={active}
			onClick={onClick}
			notificationId={notificationId}
		/>
	);
}

const useWindowLocation = () => {
	const [location, setLocation] = useState<Location | null>(null);
	useEffect(() => {
		setLocation(window.location);
	}, []);
	return location;
};

type ExperimentMentionProps = Omit<MentionsProps, "id" | "typeData"> & {
	id: ExperimentId;
	experimentDocumentLink?: ExperimentDocumentLinkId;
};

const ExperimentMention = ({
	id,
	active,
	onClick,
	experimentDocumentLink,
}: ExperimentMentionProps) => {
	const [removeExperimentLink] = useExperimentLinkDeleteMutation();
	const { data: experiment } = useGetExperimentFileQuery(
		Number(id) as ExperimentId
	);
	const location = useWindowLocation();

	const link = `${location?.origin}${location?.pathname}?fileId=${experiment?.id}&projectId=${experiment?.parent_project}`;

	const handleCopyLink = () => {
		navigator.clipboard.writeText(link);
		Notification.success({ message: "Link copied." });
	};

	// useEffect(() => {
	// 	const listener = (e: KeyboardEvent) => {
	// 		if (e.key === "Backspace" && experimentDocumentLink) {
	// 			removeExperimentLink(experimentDocumentLink);
	// 		}
	// 	};

	// 	window.addEventListener("keydown", listener);

	// 	return () => {
	// 		window.removeEventListener("keydown", listener);
	// 	};
	// }, [])

	return (
		<MentionInlineDisplay
			active={active}
			onClick={onClick}
			content={
				experiment
					? experiment.name?.replace(/\n/g, " ")
					: "Deleted experiment"
			}
			icon="experiment-link"
			// disablePopup={!experiment}
			overlay={
				<div className={styles.linkPopup}>
					<Typography
						color="link-primary"
						variant="label"
						semibold
						className={styles.linkText}
					>
						<a
							href={link}
							target="_blank"
							rel="noopener noreferrer"
							onClick={() => window.open(link, "_blank")}
						>
							{link}
						</a>
					</Typography>
					<GenemodIcon
						style={{ display: "inline-block" }}
						size="large"
						name="copy-link"
						onClick={handleCopyLink}
						hoverEffect
					/>
				</div>
			}
		/>
	);
};

type TableMentionProps = Omit<MentionsProps, "id" | "typeData"> & {
	id: GenemodDocumentUUID;
};

const TableMention = ({ id, active, onClick }: TableMentionProps) => {
	const { data } = useExperimentDocumentTableQuery(id ?? skipToken);
	const location = useWindowLocation();
	const { appendBaseUrl } = useOrganizationRouter();

	const experimentLink = `projects/project/active?fileId=${data?.experiment?.file_id}&tableId=${data?.table_id}&projectId=${data?.experiment?.parent_project}`;
	const protocolLink = `projects/protocol?protocolId=${data?.protocol?.protocol_id}&version=${data?.protocol?.protocol_version}&tableId=${data?.table_id}&edit=true`;
	const link = `${location?.origin}${appendBaseUrl(
		data?.experiment ? experimentLink : protocolLink
	)}`;

	const handleCopyLink = () => {
		navigator.clipboard.writeText(link);
		Notification.success({ message: "Link copied." });
	};

	return (
		<MentionInlineDisplay
			active={active}
			onClick={onClick}
			content={
				data ? data.table_name?.replace(/\n/g, " ") : "Deleted table"
			}
			icon="table-link"
			// disablePopup={!experiment}
			overlay={
				<div className={styles.linkPopup}>
					<Typography
						color="link-primary"
						variant="label"
						semibold
						className={styles.linkText}
					>
						{
							<a
								href={link}
								target="_blank"
								rel="noopener noreferrer"
								onClick={(event) => {
									event.stopPropagation();
									window.open(link, "_blank");
								}}
							>
								{link}
							</a>
						}
					</Typography>
					<GenemodIcon
						style={{ display: "inline-block" }}
						size="large"
						name="copy-link"
						onClick={handleCopyLink}
						hoverEffect
					/>
				</div>
			}
		/>
	);
};

type UserMentionProps = Omit<MentionsProps, "id" | "typeData"> & {
	id: OrganizationUserId;
};

const UserMention = ({
	context,
	id,
	active,
	onClick,
	notificationId,
}: UserMentionProps) => {
	const { activeTeamMembers } = useCurrentTeamMembers();
	const user = activeTeamMembers.find((u) => u.id === id)?.user;
	return (
		<MentionInlineDisplay
			notificationId={notificationId}
			active={active}
			onClick={onClick}
			content={
				user ? (
					<span style={{ textDecoration: "underline" }}>
						{getTruncatedName(user.first_name, user.last_name)}
					</span>
				) : (
					"Deleted user"
				)
			}
			icon="mention-at"
			overlay={<UserMentionPopover id={id} />}
		/>
	);
};

const UserMentionPopover = ({ id }: { id: OrganizationUserId | string }) => {
	const { activeTeamMembers } = useCurrentTeamMembers();
	const teamMember = activeTeamMembers.find((u) => u.id === id)?.user;
	if (!teamMember) return <></>;
	return (
		<div className={styles.userMentionPopoverContainer}>
			<UserAvatar
				user={teamMember}
				size={24}
				avatarStyle={{ flex: "0 0 24px" }}
			/>
			<div style={{ marginTop: -5, marginLeft: 12 }}>
				<Typography variant="regular" color="text-secondary-v2">
					{getFullNameFromAvatar(teamMember)}
				</Typography>
				<Typography variant="regular" color="text-tertiary-v2">
					{teamMember.email}
				</Typography>
			</div>
		</div>
	);
};

export type MentionNotificationOptions = {
	type: MentionNotificationSourceType;
	onSelect: (
		send: SendNotificationFunctionType,
		teamMember: WorkspaceUser | undefined,
		editor: CustomEditor
	) => void;
};

type MentionInputProps = {
	display: string;
	context: MentionContext;
	onSelect: (orgUserId: number | undefined) => void;
	notificationOptions?: MentionNotificationOptions;
	children: React.ReactNode;
};

export function MentionInput({
	display,
	context,
	onSelect,
	notificationOptions = {
		type: "EXPERIMENT_MENTION",
		onSelect: (send, teamMember, editor) => {
			if (teamMember && editor.experimentId && editor.experimentName) {
				send({
					object_name: editor.experimentName,
					object_id: editor.experimentId,
					receiver: teamMember.org_user.id,
				});
			}
		},
	},
	children,
}: MentionInputProps): JSX.Element {
	const { type, onSelect: onSelectOption } = notificationOptions;
	const editor = useGenemodSlate();
	const send = useSendMentionNotification(type);

	const { data: paginatedWorkspaceUsersData } = useGetWorkspaceUsersQuery({
		page: 1,
		page_size: 999,
	});

	const mentionWorkspaceUsers = paginatedWorkspaceUsersData?.results
		.filter((wu) => wu.org_user.status === STATUS_HIERARCHY.Active)
		.filter((wu) =>
			`${wu.org_user.user.first_name} ${wu.org_user.user.last_name}`
				.toLowerCase()
				.includes(display.toLowerCase())
		);

	const [hoverIndex, setHoverIndex] = useState(0);
	const selectedId = mentionWorkspaceUsers?.[hoverIndex]?.id || undefined;

	const internalOnSelect = (teamMember: WorkspaceUser | undefined) => {
		onSelect(teamMember?.org_user.id);
		onSelectOption(send, teamMember, editor);
	};

	useEffect(() => {
		setHoverIndex(0);
	}, [display]);

	useEffect(() => {
		// Scrolling to the center of the selected option so that dropdown is scrollable with arrow key
		const menuEl = document.getElementById(`mentionOption-${hoverIndex}`);
		menuEl?.scrollIntoView({ block: "center" });
	}, [hoverIndex]);

	useSafeWindowEventListener("keydown", (ev) => {
		if (ev.key === "ArrowDown") {
			if (hoverIndex < (mentionWorkspaceUsers?.length || 0) - 1) {
				setHoverIndex((hoverIndex) => hoverIndex + 1);
			}
		} else if (ev.key === "ArrowUp") {
			if (hoverIndex > 0) {
				setHoverIndex((hoverIndex) => hoverIndex - 1);
			}
		} else if (ev.key === "Enter" || ev.key === "Tab") {
			internalOnSelect(mentionWorkspaceUsers?.[hoverIndex]);
		}
	});

	const menu1 = (
		<Menu
			selectedKeys={selectedId ? [selectedId?.toString()] : []}
			className={styles.menu}
		>
			<Typography
				style={{ fontSize: "11px", marginLeft: "16px" }}
				color="text-tertiary"
			>
				Mention
			</Typography>

			{mentionWorkspaceUsers?.length === 0 && (
				<Typography
					style={{
						marginLeft: "16px",
						marginTop: "9px",
						marginBottom: "4px",
					}}
					variant="label"
					color="text-tertiary"
				>
					No results found.
				</Typography>
			)}
			{mentionWorkspaceUsers?.map((teamMember: WorkspaceUser, index) => {
				return (
					<Menu.Item
						onClick={() => internalOnSelect(teamMember)}
						key={`${teamMember.id}-${index}`}
						id={`mentionOption-${index}`}
						style={{ display: "flex", gap: "12px" }}
						className={classNames({
							[styles.hoverSelect]: index === hoverIndex,
						})}
					>
						<UserAvatar
							user={teamMember.org_user.user}
							size={24}
							avatarStyle={{ flex: "0 0 24px" }}
						/>
						<Typography
							className={styles.userRowName}
							color="text-primary"
						>
							{getTruncatedName(
								teamMember.org_user.user?.first_name,
								teamMember.org_user.user?.last_name
							)}
						</Typography>
					</Menu.Item>
				);
			})}
		</Menu>
	);
	return (
		<DropDown
			overlay={menu1}
			placement="bottomLeft"
			visible={true}
			getPopupContainer={() => document.body}
		>
			<span className={styles.mentionContainer}>
				<GenemodIcon
					iconClassName={styles.searchIcon}
					name="search"
					size="default"
					color="text-tertiary"
					style={{
						display: "contents",
					}}
					contentEditable={false}
					hideInnerDiv
				/>
				{children}
			</span>
		</DropDown>
	);
}

const getTruncatedName = (firstName: string, lastName: string) =>
	truncArgs`${firstName} ${lastName}`(11);

type MultiMentionElementInput = {
	type: "all" | "experiment" | "date" | "person" | "table";
	currentText: string;
	children?: React.ReactNode | React.ReactNode[];
	editor: MinimalMentionEditor;
	notificationOptions?: MentionNotificationOptions;
};

export const MultiMentionElementInput = ({
	type,
	currentText: _currentText,
	children,
	editor,
	notificationOptions,
}: MultiMentionElementInput) => {
	const send = useSendMentionNotification(
		notificationOptions?.type || "EXPERIMENT_MENTION"
	);
	const [addExperimentLink] = useExperimentLinkAddMutation();
	const { insertMention } = mentionHelpers(editor);
	const [currentText, setCurrentText] = useState(_currentText);
	useDebouncedCallback(() => setCurrentText(_currentText), 200, [
		_currentText,
	]);
	const { data: paginatedWorkspaceUsersData } = useGetWorkspaceUsersQuery({
		page: 1,
		page_size: 999,
		search: currentText,
	});

	const workspaceUsers = paginatedWorkspaceUsersData?.results.filter(
		(usr) => usr.org_user.status === STATUS_HIERARCHY.Active
	);
	const [showMore, setShowMore] = useState(false);
	const teamMembers =
		currentText.trim().length || showMore
			? paginatedWorkspaceUsersData?.results || []
			: paginatedWorkspaceUsersData?.results.slice(0, 3) || [];
	const { data: suggestedExperimentFiles } =
		useGetRecentlyOpenedExperimentFilesQuery();
	const { data: _experiments } = useSearchExperimentFilesQuery({
		search: currentText,
	});
	const { data: _experimentTables } = useExperimentDocumentTablesQuery({
		search: currentText,
	});
	const suggestedFiles =
		(suggestedExperimentFiles?.length || 0) > 0
			? suggestedExperimentFiles?.slice(0, 4)
			: _experiments?.results.slice(0, 4);

	const recentExperimentTables = _experimentTables?.slice(0, 4) || [];

	const experiments =
		currentText.trim().length || showMore
			? _experiments?.results || []
			: suggestedFiles || [];

	const experimentTables =
		currentText.trim().length || showMore
			? _experimentTables || []
			: recentExperimentTables;

	const allDropdownElements = (() => {
		const teamMemberElements = teamMembers.map(
			(member) => `person-${member.org_user.id}`
		);
		const experimentElements = experiments.map(
			(exp) => `experiment-${exp.id}`
		);
		const experimentTable = experimentTables?.map(
			(table) => `table-${table.table_document_id}`
		);
		if (type === "experiment") {
			return experimentElements;
		}
		if (type === "person") {
			return teamMemberElements;
		}
		if (type === "date") {
			return ["date"];
		}

		return [
			"date",
			...teamMemberElements,
			...experimentElements,
			...experimentTable,
		];
	})();
	const [hoverIndex, setHoverIndex] = useState<number | null>(null);
	const selectedId =
		hoverIndex !== null ? allDropdownElements[hoverIndex] : null;

	const handleInsertUserMention = (selectedId: string, personId: number) => {
		if (notificationOptions) {
			notificationOptions.onSelect(
				send,
				teamMembers.find((m) => m.org_user.id === personId),
				editor as CustomEditor
			);
			insertMention({ selectedId: selectedId });
		} else {
			// default notification mention to experiment
			const { experimentId, experimentName } = editor as CustomEditor;
			if (!experimentId) return;
			(
				send({
					object_name: experimentName,
					object_id: experimentId,
					receiver: personId,
				}) as any
			)
				.unwrap()
				.then((data: NotificationType) => {
					insertMention({
						selectedId: selectedId,
						notificationId: data.id,
					});
				});
		}
	};

	const handleInsertExperimentMention = (
		currentDocument: GenemodDocumentUUID,
		experimentId: ExperimentId
	) => {
		addExperimentLink({
			document: currentDocument,
			linked_document: experimentId,
		})
			.unwrap()
			.then((data) => {
				insertMention({
					selectedId: `experiment-${experimentId}`,
					experiment_document_link: data.id,
				});
			});
	};

	useSafeWindowEventListener("keydown", (ev) => {
		if (ev.key === "ArrowDown") {
			setHoverIndex((prev) => {
				if (prev !== null) {
					if (prev + 1 > allDropdownElements.length - 1) return 0;
					return prev + 1;
				}
				return 0;
			});
		} else if (ev.key === "ArrowUp") {
			setHoverIndex((prev) =>
				prev ? prev - 1 : allDropdownElements.length - 1
			);
		} else if (ev.key === "Enter" || ev.key === "Tab") {
			if (!selectedId) return;
			if (selectedId === "date") {
				insertDay();
				return;
			}
			if (selectedId.includes("experiment")) {
				const { documentId } = editor as CustomEditor;
				handleInsertExperimentMention(
					documentId,
					Number(selectedId.split("-")[1]) as ExperimentId
				);
				return;
			}
			if (selectedId.includes("person")) {
				const [_, id] = selectedId.split("-");
				const personId = Number(id);
				handleInsertUserMention(selectedId, personId);
				return;
			}
		}
	});

	useEffect(() => {
		const selectedElement = document.querySelector(".selected");
		if (selectedElement) {
			selectedElement.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
		if (selectedId) {
			const idType = selectedId.split(
				"-"
			)[0] as MultiMentionElementInput["type"];
			setCurrentType(iconMap[idType]);
		}
	}, [selectedId]);

	const iconMap: {
		[key in MultiMentionElementInput["type"]]: {
			icon: IconName;
			placeholder: string;
		};
	} = {
		all: {
			icon: "search",
			placeholder: "Mention a person, file, or date...",
		},
		experiment: {
			icon: "experiment-link",
			placeholder: "Mention experiment",
		},
		person: { icon: "mention-at", placeholder: "Mention person" },
		date: { icon: "wall-calendar", placeholder: "Mention date" },
		table: { icon: "table-link", placeholder: "Mention table" },
	};

	const [currentType, setCurrentType] = useState(iconMap[type]);

	function getNewDayNode(): DayElement {
		return {
			type: DAY_TYPE,
			date: moment().toISOString(),
			id: nanoid(),
			children: [{ text: "" }],
		};
	}

	const insertDay = () => {
		ReactEditor.focus(editor);
		const { selection } = editor;
		if (!selection) return;

		// Replace in table data
		const [tableDataNode] = Editor.nodes(editor, {
			at: selection,
			match: (n) => flattenUnion(n).type === "table-data-v2",
		});
		const [mentionInput] = Editor.nodes(editor, {
			at: selection,
			match: (n) => flattenUnion(n).type === "mention-input",
		});
		if (tableDataNode && mentionInput) {
			editor.replaceNodes(getNewDayNode(), { at: mentionInput[1] });
			return;
		}

		// Replace elsewhere
		editor.replaceNodes(getNewDayNode(), { at: mentionInput[1] });
	};

	const renderDate = (key: string, date: string): ItemType => {
		return {
			key,
			label: (
				<div
					className={classNames(styles.menuDate, {
						selected: selectedId === "date",
					})}
				>
					<GenemodIcon name="wall-calendar" color="text-tertiary" />
					<Typography variant="label">{date}</Typography>
					<Typography variant="label" color="text-tertiary">
						- Today
					</Typography>
				</div>
			),
			onClick: () => {
				insertDay();
			},
		};
	};

	const renderUser = (key: string, user: OrganizationUser) => {
		return {
			key: `person-${key}`,
			label: (
				<UserAvatar
					user={user.user}
					displayName
					displayNameVariant="label"
					className={classNames({
						selected: selectedId === `person-${key}`,
					})}
					nameClassName={styles.avatarName}
				/>
			),
			onClick: () => {
				handleInsertUserMention(`person-${key}`, user.id);
			},
		};
	};

	const renderExperiment = (key: string, experiment: ExperimentFile) => {
		return {
			key: `experiment-${key}`,
			label: (
				<div
					className={classNames(styles.menuExperiment, {
						selected: selectedId === `experiment-${key}`,
					})}
				>
					<GenemodIcon name="experiment" color="text-primary-v2" />
					<div style={{ width: 300 }}>
						<Typography variant="label" ellipsis>
							{experiment.name}
						</Typography>
						<Typography variant="caption" ellipsis>
							{experiment.parent_project_name}
						</Typography>
					</div>
				</div>
			),
			onClick: () => {
				insertMention({ selectedId: `experiment-${key}` });
				handleInsertExperimentMention(
					(editor as CustomEditor).documentId,
					parseInt(key, 10) as ExperimentId
				);
			},
		};
	};

	const renderExperimentTable = (key: string, table: ExperimentTable) => {
		return {
			key: `table-${key}`,
			label: (
				<div
					className={classNames(styles.menuExperiment, {
						selected: selectedId === `table-${key}`,
					})}
				>
					<GenemodIcon
						name="table"
						fill="layer-01"
						color="text-primary-v2"
					/>
					<div style={{ width: 300 }}>
						<Typography variant="label" ellipsis>
							{table.table_name}
						</Typography>
						<Typography variant="caption" ellipsis>
							{table.experiment?.name || table.protocol?.name}
						</Typography>
					</div>
				</div>
			),
			onClick: () => {
				insertMention({
					selectedId: `table-${table?.table_document_id}`,
				});
			},
		};
	};

	const getItems = (): MenuProps["items"] => {
		if (type === "experiment") {
			const items: MenuProps["items"] = [
				...experiments.map((exp) =>
					renderExperiment(exp.id.toString(), exp as ExperimentFile)
				),
			];
			if (!showMore && (_experiments?.count || 0) > 4) {
				items.push(
					{
						type: "divider",
					},
					{
						key: "showmore",
						label: (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: 4,
								}}
							>
								<GenemodIcon
									name="meatballs"
									color="text-tertiary-v2"
								/>{" "}
								<Typography color="text-tertiary-v2">
									{(_experiments?.count || 0) - 4} more result
									{(_experiments?.count || 0) - 4 > 1 && "s"}
								</Typography>
							</div>
						),
						onClick: () => setShowMore(true),
					}
				);
			}
			return items;
		}

		if (type === "table") {
			const items: MenuProps["items"] = [
				...experimentTables.map((table) =>
					renderExperimentTable(table.table_document_id, table)
				),
			];
			if (!showMore && (_experimentTables?.length || 0) > 4) {
				items.push(
					{
						type: "divider",
					},
					{
						key: "showmore",
						label: (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: 4,
								}}
							>
								<GenemodIcon name="meatballs" />{" "}
								<Typography color="text-tertiary">
									{(_experimentTables?.length || 0) - 4} more
									result
									{(_experimentTables?.length || 0) - 4 > 1 &&
										"s"}
								</Typography>
							</div>
						),
						onClick: () => setShowMore(true),
					}
				);
			}
			return items;
		}

		if (type === "person") {
			const items: MenuProps["items"] = [
				...teamMembers.map((user) =>
					renderUser(user.org_user.id.toString(), user.org_user)
				),
			];
			if (!showMore && (workspaceUsers?.length || 0) > 3) {
				items.push(
					{
						type: "divider",
					},
					{
						key: "showmore",
						label: (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: 4,
								}}
							>
								<GenemodIcon name="meatballs" />{" "}
								<Typography color="text-tertiary">
									{(workspaceUsers?.length || 0) - 3} more
									result
									{(workspaceUsers?.length || 0) - 3 > 1 &&
										"s"}
								</Typography>
							</div>
						),
						onClick: () => setShowMore(true),
					}
				);
			}
			return items;
		}

		const noResultsItem = (key: string) => {
			return [
				{
					key: `noresults-${key}`,
					label: (
						<Typography variant="label" color="text-tertiary">
							No results
						</Typography>
					),
					disabled: true,
				},
			];
		};

		const items: MenuProps["items"] = [
			{
				type: "group",
				label: (
					<Typography bold color="text-tertiary" variant="label">
						Date
					</Typography>
				),
				children: [renderDate("date", moment().format("LL"))],
			},
			{
				type: "divider",
			},
			{
				type: "group",
				label: (
					<Typography bold color="text-tertiary" variant="label">
						People
					</Typography>
				),
				children: teamMembers.length
					? teamMembers.map((member) =>
							renderUser(
								member.org_user.id.toString(),
								member.org_user
							)
					  )
					: noResultsItem("people"),
			},
			{
				type: "divider",
			},
			{
				type: "group",
				label: (
					<Typography bold color="text-tertiary" variant="label">
						Files
					</Typography>
				),
				children: experiments.length
					? experiments.map((experiment) =>
							renderExperiment(
								experiment.id.toString(),
								experiment as ExperimentFile
							)
					  )
					: noResultsItem("files"),
			},
			{
				type: "divider",
			},
			{
				type: "group",
				label: (
					<Typography bold color="text-tertiary" variant="label">
						Table
					</Typography>
				),
				children: experimentTables.length
					? experimentTables.map((experimentTable) =>
							renderExperimentTable(
								experimentTable.table_document_id,
								experimentTable
							)
					  )
					: noResultsItem("tables"),
			},
		];

		return items;
	};

	return (
		<div className={styles.blockWrapper}>
			<Dropdown
				menu={{
					items: getItems(),
					selectedKeys: selectedId ? [selectedId] : [],
				}}
				open
				overlayClassName={classNames(styles.multiMentionDropdown, {
					[styles.smallVersion]: type === "experiment" && !showMore,
				})}
			>
				<div className={styles.multiMention}>
					<GenemodIcon name={currentType.icon} />
					{children}
					{_currentText.length === 0 && (
						<Typography
							color="text-tertiary"
							className={styles.currentTypePlaceholder}
						>
							<div contentEditable={false}>
								{currentType.placeholder}
							</div>
						</Typography>
					)}
				</div>
			</Dropdown>
		</div>
	);
};

type MentionInlineDisplayProps = {
	notificationId?: number;
	className?: string;
	active: boolean;
	onClick: React.MouseEventHandler<HTMLSpanElement>;
	content: React.ReactNode;
	icon: IconName;
	overlay?: React.ReactNode;
	invertIcon?: boolean;
	visibilityProps?: {
		visible: boolean;
		onVisibleChange: (visible: boolean) => void;
	};
};
export const MentionInlineDisplay = ({
	notificationId,
	className,
	active,
	onClick,
	content,
	icon,
	overlay,
	invertIcon,
	visibilityProps,
}: MentionInlineDisplayProps) => {
	const [focused, setFocused] = useState<boolean>(false);
	const notificationElement = useRef<HTMLDivElement | null>(null);
	const { getParam, params } = useParams();

	useEffect(() => {
		const notificationParam = getParam("notificationId");
		if (!notificationParam) return;
		if (parseInt(notificationParam) === notificationId) {
			setTimeout(() => {
				if (!notificationElement.current) return;
				setFocused(true);
				notificationElement.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}, 750);
		}
	}, [params, notificationId, notificationElement.current]);

	return (
		<Popover
			trigger="hover"
			placement="bottomLeft"
			overlayClassName={classNames(styles.popover, {
				[styles.disablePopover]: !overlay,
			})}
			content={overlay}
			mouseEnterDelay={0.2}
			getPopupContainer={() => document.body}
			{...(visibilityProps || {})}
		>
			<div
				className={classNames(className, styles.mentionWrapper)}
				contentEditable={false}
				id={
					notificationId
						? `notification-${notificationId}`
						: undefined
				}
				ref={notificationElement}
			>
				<span
					contentEditable={false}
					className={classNames(styles.mentionContainer, {
						[styles.active]: active,
						[styles.hover]: focused,
						[styles.invertedIcon]: invertIcon,
					})}
					onClick={onClick}
					style={{ cursor: "default" }}
				>
					<GenemodIcon
						name={icon}
						style={{ cursor: "default" }}
						contentEditable={false}
					/>{" "}
					<span>{content}</span>
				</span>
			</div>
		</Popover>
	);
};
