import React, { forwardRef, useState } from "react";
import Draggable, { DraggableData } from "react-draggable";
import { BaseElement, Editor, Transforms } from "slate";
import { useFocused, useSelected } from "slate-react";
import {
	API,
	axios,
	GenemodIcon,
	Notification,
	UploadAttachmentModal,
} from "@common/components";
import { Block } from "../../nodes";
import "./ImageNode.scss";
import cn from "classnames";
import { nanoid } from "nanoid";
import { useDocumentImageQuery } from "@redux/ProjectManagement/PmApiSlice";
import { useAppDispatch } from "@redux/store";
import { useGenemodSlate } from "@common/components/Editor/hooks/UseGenemodSlateHook";
import { DocumentImage, GenemodDocumentUUID } from "@common/types";
import { EditorCommands } from "@components/Editor/helpers/editorCommons";

export const DataGenemodImage = "data-genemod-image";

const MAX_IMAGE_WIDTH = 712;
const MIN_IMAGE_WIDTH = 48;
const MIN_IMAGE_HEIGHT = 48;

export type ImageNodeElement = BaseElement & {
	type: "image";
	url: string;
	id: number;
	uploadUUID: string;
	width: number;
};

export const ImageNode = {
	...Block,
	type: "image",
	id: -1,
	uploadUUID: "",
	width: MAX_IMAGE_WIDTH,

	render(props: any, node: any) {
		return (
			<ResizeableImage {...props.attributes} node={node}>
				{props.children}
			</ResizeableImage>
		);
	},

	getNewImageNode() {
		return {
			id: nanoid(),
			type: this.type,
			width: this.width,
		};
	},
	insertNewImage(editor: Editor, uploadUUID: string) {
		Transforms.insertNodes(editor, {
			...this.getNewImageNode(),
			uploadUUID: uploadUUID,
			children: [{ text: "" }],
		});
		this.focusEditor(editor);
	},
	setSize(editor: Editor, id: number, width: number) {
		Transforms.setNodes(editor, { width: width } as any, {
			at: [],
			match: (n) =>
				(n as any).type === ImageNode.type && (n as any).id === id,
		});
	},
	plugin(editor: Editor) {
		const editorCommands = new EditorCommands();
		const {
			normalizeNode,
			deleteBackward,
			deleteFragment,
			insertFragment,
			isVoid,
		} = editor;
		editor.normalizeNode = (entry: any) => {
			editorCommands.normalizeNode(
				entry,
				this.type,
				editor,
				normalizeNode
			);
		};

		editor.insertFragment = (fragment: any) =>
			editorCommands.insertFragment(
				fragment,
				this.type,
				editor,
				insertFragment
			);

		editor.deleteFragment = () =>
			editorCommands.deleteFragment(this.type, editor, deleteFragment);

		editor.deleteBackward = (unit) =>
			editorCommands.deleteBackward(
				unit,
				this.type,
				editor,
				deleteBackward
			);

		editor.isVoid = (element) =>
			editorCommands.isVoid(element, this.type, isVoid);

		return editor;
	},
};

/** resizeable image component for Experiment */
const ResizeableImage = forwardRef((props: any, ref: any) => {
	const editor = useGenemodSlate();
	const { node } = props;
	const [width, setWidth] = useState(0);
	const focused = useFocused() && useSelected();
	const [showIndicator, setShowIndicator] = useState(false);

	// We fetch images rather than using document.images so that images
	// added by other users during live editing will always be fetched
	const { data: imageData } = useDocumentImageQuery(node.uploadUUID);

	const imagePermission =
		editor.experimentIsEditable && editor.permissions.image;
	// return whether this node is selected or not
	const isSelected = () => {
		const [...match] = Editor.nodes(editor, {
			match: (n) =>
				(n as any).type === node.type && (n as any).id === node.id,
		});
		return !!match.length;
	};

	// get current node (slate) position in editro
	const getNodePosition = () => {
		const [imageNodes] = Editor.nodes(editor, {
			at: [],
			match: (n: any) => n.type === ImageNode.type && n.id === node.id,
		});
		return imageNodes[1];
	};

	const renderDraggableIcon = (position: "left" | "right") =>
		imagePermission && (
			<div className="draggable-icon-container">
				<Draggable
					axis="none"
					onDrag={(_e, data: DraggableData) => {
						if (data.deltaX !== 0) {
							const dir = position === "right" ? 1 : -1;
							// onlyif there are movement on x-axis
							const w = width + dir * data.deltaX * 2;
							const h =
								ref.current?.height + dir * data.deltaX * 2;
							if (
								w > MAX_IMAGE_WIDTH ||
								w < MIN_IMAGE_WIDTH ||
								h < MIN_IMAGE_HEIGHT
							) {
								// out of resize boundary
								return;
							}
							setWidth(w);
						}
					}}
					onStart={() => {
						setWidth(node.width);
					}}
					onStop={() => {
						setWidth(0);
						// only update the node when dragging is finished
						ImageNode.setSize(editor, node.id, width);
					}}
				>
					<GenemodIcon
						className="draggable-icon"
						size="large"
						name="drag-handle-resize"
					/>
				</Draggable>
			</div>
		);

	return (
		<div {...props} ref={ref}>
			<div
				className={cn("experiment-image-container", {
					["experiment-image-container_focused"]:
						isSelected() || focused,
				})}
				style={{ userSelect: "none" }}
				contentEditable={false}
			>
				{renderDraggableIcon("left")}
				{isSelected() && (
					<div
						className="experiment-image-cover"
						style={{
							width: (width ? width : node.width) + 16,
							height: "calc(100% + 16px)",
							pointerEvents: "none",
						}}
					/>
				)}
				<img
					className="experiment-image"
					data-genemod-image={DataGenemodImage}
					src={imageData?.upload}
					alt="genemod-experiment-image"
					width={width ? width : node.width}
					onDragEnter={(e) => {
						const types = e.dataTransfer.types;
						if (types.length > 0 && types[0] === "Files") {
							// only show indicator, if image file is dragged
							setShowIndicator(true);
						}
					}}
					onDragLeave={() => {
						setShowIndicator(false);
					}}
					onDropCapture={(e) => {
						// prevent to run editor.insertData
						e.stopPropagation();
						const pos = getNodePosition();
						const after = Editor.after(editor, pos);
						if (after) {
							Transforms.select(editor, after);
						}
						setShowIndicator(false);
					}}
					onClick={() => {
						const pos = getNodePosition();
						Transforms.select(editor, pos);
					}}
				/>
				{renderDraggableIcon("right")}
			</div>
			{showIndicator && <div className="experiment-indicator-bar" />}
			{props.children}
		</div>
	);
});

/** insert Image Modal  */
export function InsertImageModal(props: {
	visible: boolean;
	documentId: GenemodDocumentUUID;
	onCancel: () => void;
	onUploadCompleted: (uuid: string) => void;
}): JSX.Element {
	const dispatch = useAppDispatch();
	const [uploadingRate, setUploadingRate] = useState(0.0);

	// called after image is attached to the modal
	const handleUploadCompleted = async (imageData: any) => {
		// const image = await postAttachmentToDB(imageData);
		const config = {
			onUploadProgress: function (progressEvent: any) {
				const percentCompleted = Math.floor(
					(progressEvent.loaded / progressEvent.total) * 100
				);
				setUploadingRate(percentCompleted);
			},
		};
		const data = new FormData();
		data.append("upload", imageData);
		const route = API.pm
			.documents()
			.get(props.documentId)
			.imageUpload()
			.getRoute();
		axios.post<DocumentImage>(route, data, config).then((response) => {
			if ("error" in response) {
				Notification.warning({
					message:
						"Failed to upload the image. Try again or contact us if it continues.",
				});
			} else {
				props.onUploadCompleted(response.data.uuid);
			}
		});
	};

	return (
		<UploadAttachmentModal
			visible={props.visible}
			type="image"
			onCancel={props.onCancel}
			uploadCompleted={handleUploadCompleted}
			uploadingRate={uploadingRate}
		/>
	);
}
