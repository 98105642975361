import React, { HTMLProps, useState } from "react";
import { Workspace, WorkspaceId } from "@common/types";
import TeamCard from "@containers/AdminEnterprisePortal/components/InviteUsersModal/TeamCard";
import { useGetPersonalWorkspacesQuery } from "@redux/team/TeamApi";
import classNames from "classnames";
import TeamAvatarGroup from "../TeamAvatarGroup";
import styles from "./WorkspaceSelector.module.scss";
import { useCurrentWorkspaceUuid } from "@helpers/Hooks/UseCurrentWorkspaceUuid";
import {
	GenemodIcon,
	Modal,
	Typography,
	Tooltip,
	CheckboxV2,
} from "@components";

type Props = {
	values: WorkspaceId[];
	setValues: (value: WorkspaceId[]) => void;
} & Omit<HTMLProps<HTMLDivElement>, "value">;

/**
 * Component to select workspaces (not in a modal, for settings panels)
 */
export const WorkspaceSelector = ({
	values,
	setValues,
	...props
}: Props): JSX.Element => {
	const [collapsed, setCollapsed] = useState(true);

	return (
		<div {...props} className={classNames(styles.main, props.className)}>
			<div
				className={styles.flex}
				onClick={() => setCollapsed(!collapsed)}
			>
				<div className={styles.arrow}>
					<GenemodIcon
						className={classNames({
							[styles.arrowExpanded]: !collapsed,
						})}
						name="chevron-right"
						color="text-secondary"
						fill="text-secondary"
					/>
				</div>
				<CurrentWorkspaceSelectionDisplay values={values} />
			</div>
			<div
				className={classNames(styles.collapsed, {
					[styles.expanded]: !collapsed,
				})}
			>
				<WorkspaceSelectionList
					values={values}
					setValues={setValues}
					alwaysIncludeCurrentWorkspace={true}
				/>
			</div>
		</div>
	);
};

type WorkspaceFreezerModalProps = {
	/** whether to show the modal */
	visible: boolean;
	/** when user exits the modal */
	onCancel: () => void;
	/** hide the ok button */

	hideOkButton?: boolean;
	/** hide the cancel button */
	hideCancelButton?: boolean;
	selectedVal: WorkspaceId[];
	setSelectedVal: (value: WorkspaceId[]) => void;
};

/**
 * Modal to select workspace, used in freezer/space creation modals.
 */
export const WorkspaceSelectorModal = ({
	visible,
	onCancel,
	hideOkButton,
	hideCancelButton,
	selectedVal,
	setSelectedVal,
}: WorkspaceFreezerModalProps): JSX.Element => {
	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={592}
			hideCancelButton={hideCancelButton}
			hideOkButton={hideOkButton}
		>
			<div style={{ display: "flex", marginBottom: "24px" }}>
				<div
					className="verticleCenter"
					style={{
						marginTop: "5px",
						marginRight: "8px",
					}}
				>
					<GenemodIcon
						name="arrow-forward"
						style={{
							transform: "scaleX(-1)",
						}}
						onClick={onCancel}
					/>
				</div>
				<Typography variant="headline5" color="text-primary" medium>
					Grant freezer access to:
				</Typography>
			</div>

			<WorkspaceSelectionList
				values={selectedVal}
				setValues={setSelectedVal}
				alwaysIncludeCurrentWorkspace={false}
			/>
		</Modal>
	);
};

type CurrentWorkspaceSelectionDisplayProps = {
	values: WorkspaceId[];
};

export const CurrentWorkspaceSelectionDisplay = ({
	values,
}: CurrentWorkspaceSelectionDisplayProps): JSX.Element => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
			}}
		>
			<Typography variant="body" medium color="text-secondary-v2">
				{values.length} workspace{values.length === 1 ? "" : "s"} can
				access:
			</Typography>
			<TeamAvatarGroup
				avatarSize={32}
				style={{ marginLeft: 12 }}
				workspaceIds={values}
			/>
		</div>
	);
};

type WorkspaceSelectionListProps = {
	values: WorkspaceId[];
	setValues: (value: WorkspaceId[]) => void;
	alwaysIncludeCurrentWorkspace: boolean;
};

const WorkspaceSelectionList = ({
	values,
	setValues,
	alwaysIncludeCurrentWorkspace,
}: WorkspaceSelectionListProps): JSX.Element => {
	const { data: workspacesData } = useGetPersonalWorkspacesQuery({
		get_all: true,
	});
	const currentWorkspaceId = useCurrentWorkspaceUuid();
	const currentWorkspace = { id: currentWorkspaceId } as Workspace;
	const allWorkspaces = workspacesData || [];
	let displayWorkspaces = allWorkspaces;
	if (!alwaysIncludeCurrentWorkspace) {
		displayWorkspaces = displayWorkspaces.filter(
			(workspace) => workspace.id !== currentWorkspaceId
		);
	}
	const everyWorkspaceIsChecked = displayWorkspaces.every(({ id }) =>
		values?.find((v) => v === id)
	);
	const isSomeWorkspaceChecked = displayWorkspaces.some(({ id }) =>
		values?.find((v) => v === id)
	);

	const disableIfOnlyOneWorkspaceChecked =
		alwaysIncludeCurrentWorkspace && values.length === 1;

	return (
		<div>
			<div
				style={{
					padding: "13px 12px 13px 8px",
					backgroundColor: "var(--background)",
				}}
				className={styles.flex}
			>
				<CheckboxV2
					style={{
						width: "min-content",
					}}
					value={
						everyWorkspaceIsChecked
							? true
							: isSomeWorkspaceChecked
							? "complicated"
							: false
					}
					onChange={(checked) =>
						setValues(
							checked
								? allWorkspaces.map((w) => w.id)
								: [currentWorkspace.id]
						)
					}
				/>
				<Typography variant="body" medium color="text-secondary-v2">
					Select all
				</Typography>
			</div>
			<div
				style={{
					backgroundColor: "var(--border-subtle)",
					height: 2,
					width: "100%",
				}}
			></div>
			{displayWorkspaces.map((workspace) => {
				const value = values.some(
					(_workspace) => _workspace === workspace.id
				);
				const disabled = value && disableIfOnlyOneWorkspaceChecked;
				return (
					<Tooltip
						placement="top"
						title="At least one workspace must be selected"
						hideTooltip={!disabled}
						key={workspace.id}
					>
						<div
							className={classNames(styles.flex, styles.teamCard)}
						>
							<CheckboxV2
								value={value}
								style={{ width: "min-content" }}
								className={classNames({
									[styles.disabledCheckbox]: disabled,
								})}
								onChange={(v) => {
									if (disabled) return;
									if (v) {
										setValues([...values, workspace.id]);
									} else {
										setValues(
											values.filter(
												(_workspace) =>
													_workspace !== workspace.id
											)
										);
									}
								}}
							/>
							<TeamCard
								workspace={workspace}
								displayButton={false}
							/>
						</div>
					</Tooltip>
				);
			})}
		</div>
	);
};
