export const IconNames = [
	"academia",
	"admin-only",
	"ai-icon",
	"align-bottom",
	"align-middle",
	"align-top",
	"bookmark-empty",
	"bookmark-freezer",
	"bookmark-invisible",
	"bookmark-selected",
	"cancel",
	"cancel-subscription-illustration(dark)",
	"cancel-subscription-illustration(light)",
	"caret-down",
	"caret-left",
	"caret-right",
	"center-align",
	"checkbox-disabled",
	"checkmark",
	"checkmark-circle",
	"checkout",
	"circle-cancel",
	"circle-check",
	"circle-save",
	"collapse",
	"command-bulleted-list",
	"command-date",
	"command-heading1",
	"command-heading2",
	"command-numbered-list",
	"command-paragraph",
	"command-subtitle",
	"consumable",
	"credit-card",
	"data-storage",
	"dedicated",
	"diamond",
	"dna-placeholder-illustration",
	"down-trend",
	"drag-handle-resize",
	"dropdown-check",
	"expand-all",
	"envelope",
	"error",
	"error-circle",
	"exit",
	"export",
	"experiment",
	"fill",
	"filter-checked",
	"folder-file",
	"format-number-list",
	"genemod-doc",
	"gift",
	"gift-icon",
	"gift_original",
	"group",
	"handshake",
	"heart",
	"help",
	"highlighter",
	"import",
	"import-icon",
	"institution",
	"left-align",
	"list",
	"logout",
	"manage-team",
	"minus",
	"multi-workspace",
	"new-day",
	"plus",
	"plus-v2",
	"preference",
	"prioritization",
	"profile",
	"protocol-step-complete",
	"protocol-step-incomplete",
	"puzzle",
	"question",
	"rearrange",
	"red-dot",
	"remove-all",
	"right-align",
	"search",
	"shield",
	"sigma",
	"simple-cloud",
	"sort-new",
	"spinner",
	"stock",
	"stripe",
	"text-color",
	"trophie",
	"unpin",
	"up-trend",
	"workspace",
	"experiment-link",
	"chatgpt",
	"formal",
	"table-link",
	"rack-grid",
	"filter-checked-v2",
	"sort",
	"people-group",
	"people-group-filled",
	"swoosh-check",
] as const;

export const GeneralIcons = [
	"bulb",
	"settings",
	"settings-filled",
	"bookmark",
	"open-box",
	"closed-box",
	"trash",
	"trash-outlined",
	"eye",
	"eye-off",
	"hashtag",
	"category",
	"waffle",
	"tune-icon",
	"open-book",
	"meatballs",
	"kabob",
	"pencil",
	"edit",
	"copy-link",
	"cut",
	"send",
	"lock",
	"lock-o",
	"unlock",
	"unlock-o",
	"drag-handle",
	"hamburger",
	"add-all",
	"plus-circle",
	"plus-circle-fill",
	"home",
	"home-o",
	"private",
	"person-add",
	"person-add-o",
	"diversity-o",
	"group-people",
	"repository",
	"grid",
] as const;

export const BusinessIcons = [
	"folder",
	"folder-add",
	"folder-o",
	"folder-add-o",
	"file",
	"file-add",
	"file-o",
	"file-add-o",
	"archive",
	"archive-o",
	"article",
	"printer",
	"protocol-library",
	"paper-clip",
	"active-projects",
	"report",
	"formal-o",
	"building-o",
	"subscription",
	"analytics",
	"monitoring",
] as const;

export const TimeIcons = [
	"history",
	"filter-o",
	"filter",
	"wall-calendar",
	"calendar-add",
	"calendar-add-o",
	"clock",
] as const;

export const FileTypesIcons = [
	"excel",
	"word",
	"pdf",
	"sharepoin",
	"power-point",
	"drive-slides",
	"drive-docs",
	"xps",
	"txt",
	"drive",
	"drive-form",
	"drive-sheets",
	"sap",
	"video",
] as const;

export const DirectionalIcons = [
	"download",
	"shorten",
	"link-tab",
	"upload",
	"chevron-right",
	"chevron-up",
	"chevron-down",
	"expand",
	"double-arrow-right",
	"double-arrow-left",
	"chevron-left",
	"arrow-forward",
	"caret-up",
	"arrow-backward",
	"subdirectory-arrow-right",
	"arrow-insert",
	"arrow-outward",
	"caret-down",
	"sync",
	"keyboard-return",
] as const;

export const TextEditorIcons = [
	"table",
	"table-o",
	"image",
	"number-list",
	"bulleted-list",
	"undo",
	"redo",
	"format-indent-decrease",
	"function",
	"format-align-left",
	"format-align-center",
	"subscript",
	"superscript",
	"normal-text",
	"format-indent-increase",
	"format-underlined",
	"format-bold",
	"format-align-right",
	"format-strikethrough",
	"format-italic",
	"format-color-fill",
	"format-highlighter",
	"H1",
	"H2",
	"H3",
	"format-clear",
	"variable-o",
	"variable",
] as const;

export const ScienceIcons = [
	"mouse",
	"freezer",
	"lab-flask",
	"microscope",
	"lab-tube",
	"box",
	"cage",
	"male",
	"female",
	"dna",
	"facility",
] as const;

export const CommunicationIcons = [
	"bell",
	"comment",
	"comment-o",
	"add-comment",
	"casual-o",
	"exit-circle",
	"exit-circle-o",
	"success",
	"success-o",
	"info",
	"info-o",
	"question-mark",
	"question-mark-o",
	"exclamation",
	"exclamation-o",
	"warning-o",
] as const;

export const InternetTechnologyIcons = [
	"security",
	"globe",
	"link",
	"rewrite",
	"stars",
	"share",
	"remove-link",
	"computer",
	"share-o",
	"cloud-disconnected",
	"cloud",
	"sync-cloud-icon",
	"qr-code",
	"mention-at",
	"cached-o",
	"extension-o",
	"extension",
	"cloud-download-o",
	"cloud-download",
] as const;

export const GenemodIcons = [
	...IconNames,
	...BusinessIcons,
	...TimeIcons,
	...FileTypesIcons,
	...DirectionalIcons,
	...TextEditorIcons,
	...ScienceIcons,
	...CommunicationIcons,
	...InternetTechnologyIcons,
	...GeneralIcons,
] as const;

export type IconName = (typeof GenemodIcons)[number];
