import React, { useEffect, useRef, useState } from "react";
import { TabListTabText } from "@common/components/TabList/TabList";
import {
	ActivityLog,
	ButtonV2,
	EditableSidePanel,
	Notification,
	SelectV2,
	SidePanel,
	TabList,
	TextArea,
	Typography,
} from "@components";
import { truncArgs } from "@helpers/Formatters";
import { useItemTypeNameMappingsFn } from "@helpers/Hooks";
import { idKeysOfConst } from "@helpers/TypeHelpers";
import AnalyticsTabContent from "../AnalyticsTabContent/AnalyticsTabContent";
import ItemTypeSelect from "../ItemTypeSelect/ItemTypeSelect";
import "./FridgeSettings.scss";
import { WorkspaceSelector } from "@common/components/WorkspaceSelector";
import {
	Freezer,
	toSharedWriteDto,
	SharedInternalObj,
	FreezerLabelType,
	DEFAULT_ITEM_TYPE,
	FREEZER_TYPES,
} from "@common/types";
import {
	SharingObjectButtons,
	SharedWithDisplay,
	SharingLevelRestrictionWarning,
} from "@common/components/Sharing/SharingObjectButtons";
import { useSharingState } from "@common/helpers/Hooks/SharedObjectHooks";
import useCurrentFeatureRestriction from "@helpers/Hooks/useCurrentFeatureRestrictionHook";
import useCurrentOrganization from "@helpers/Hooks/useCurrentOrganizationHook";
import useCurrentWorkspaceUserHooks from "@helpers/Hooks/UseCurrentWorkspaceUserHook";
import { useOrganizationRouter } from "@root/AppRouter";
import { ACCOUNT_SETTINGS_PATHS, FREEZER_PATHS } from "@root/routes";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { useGetWorkspaceUsersQuery } from "@redux/team/TeamApi";
import RearrangeModal from "../RearrangeModal/RearrangeModal";
import { ShelfViewContent } from "@containers/Freezer/contents/ShelfView";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	useFreezerQuery,
	useFreezerUpdateMutation,
	useFreezerStorageSummaryQuery,
} from "@redux/inventory/Freezer";
import { useFreezerPages } from "@helpers/Hooks/UseFreezerNavbarHooks";
import { FREEZER_PAGES } from "@containers/Freezer";
import { useCurrentWorkspaceUuid } from "@helpers/Hooks/UseCurrentWorkspaceUuid";
import InputV3 from "@common/components/InputV3/InputV3";

export default function FridgeSettings(): JSX.Element {
	const {
		genericallyNamed: {
			isVisible,
			closePanel: _closePanel,
			data: { id },
		},
	} = useCommonPanelState("fridgeSettings");
	const { data: freezer } = useFreezerQuery(id || skipToken);

	const itemTypeNameMappingsFn = useItemTypeNameMappingsFn();
	const viewOnly = freezer?.is_archived;
	/** Team related info */
	const { organization } = useCurrentOrganization();
	const teamName = organization?.name;
	const { featureRestrictionData, fetchFeatureRestriction } =
		useCurrentFeatureRestriction();
	const memberCount = featureRestrictionData?.members.current;
	const restrictFreezerAnalytics =
		featureRestrictionData?.freezer_analytics?.is_limit_reached;

	const [showRearrangeRacksModal, setShowRearrangeRacksModal] =
		useState(false);
	const [showEditShelfModal, setShowEditShelfModal] = useState(false);

	const closePanel = () => {
		if (showEditFridge) {
			setEditFridge(false);
		}

		setName("");
		setDescription("");
		setFridgeType(freezer?.freezer_type || 0);

		setTempState({
			name: name,
			description: description,
			freezer_type: fridgeType || 0,
			default_item_type: freezer?.default_item_type || DEFAULT_ITEM_TYPE,
		});

		_closePanel();
	};

	const [showEditFridge, setEditFridge] = React.useState<boolean>(false);

	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [fridgeType, setFridgeType] = useState<number>(
		freezer?.freezer_type || 0
	);
	const [labelType, setLabelType] = useState<FreezerLabelType>(
		freezer?.label_type || 0
	);
	const [tempState, setTempState] = useState({
		name: freezer?.name || "",
		description: freezer?.description || "",
		freezer_type: freezer?.freezer_type || 0,
		default_item_type: freezer?.default_item_type || DEFAULT_ITEM_TYPE,
	});

	useEffect(() => {
		if (name === "" || name === undefined) {
			const temp = freezer;
			setName(temp?.name || "");
			setLabelType(temp?.label_type || 0);
			setDescription(temp?.description || "");

			setTempState({
				name: (freezer?.name || "").trim(),
				description: freezer?.description || "",
				freezer_type: freezer?.freezer_type || 0,
				default_item_type:
					freezer?.default_item_type || DEFAULT_ITEM_TYPE,
			});
		}
	}, [showEditFridge, freezer]);

	useEffect(() => {
		if (!memberCount) {
			// when freezerSetting is in edit mode, but there is no featureRestrictionData in redux
			fetchFeatureRestriction();
		}
	}, [tempState]);

	const detailContent = (
		<>
			<EditableSidePanel
				id={freezer?.id || null}
				title={freezer?.name || ""}
				edit={showEditFridge}
				showEdit={() => setEditFridge(true)}
				viewOnly={viewOnly}
				dataCy="fridge-settings"
				type="freezer"
				setShowEditShelfModal={setShowEditShelfModal}
			>
				<div className="fridge-form">
					<div className="sidePanel-infoView-layout">
						<Typography
							className="sidePanel-infoView-leftSide"
							variant="body"
							medium
						>
							Freezer type
						</Typography>
						<Typography variant="body" color="text-secondary-v2">
							{FREEZER_TYPES[freezer?.freezer_type || 0]}
						</Typography>
					</div>
					{teamName && (
						<div className="sidePanel-infoView-layout">
							<Typography
								className="sidePanel-infoView-leftSide"
								variant="body"
								medium
							>
								Shared with
							</Typography>
							<SharedWithDisplay sharedObject={freezer} />
						</div>
					)}
					<div className="sidePanel-infoView-layout">
						<Typography
							className="sidePanel-infoView-leftSide"
							variant="body"
							medium
						>
							Item type
						</Typography>
						<Typography variant="body" color="text-secondary-v2">
							{itemTypeNameMappingsFn(
								freezer?.default_item_type || DEFAULT_ITEM_TYPE
							)}
						</Typography>
					</div>
					<div className="sidePanel-infoView-layout">
						<Typography
							className="sidePanel-infoView-leftSide"
							variant="body"
							medium
						>
							Dimension
						</Typography>
						<Typography variant="body" color="text-secondary-v2">
							{freezer?.shelves} shelves
						</Typography>
					</div>
				</div>
			</EditableSidePanel>
		</>
	);

	const activityContent = (
		<ActivityLog
			activityFilter={{
				location_model: "Freezer",
				location_object_id: freezer?.id,
			}}
		/>
	);

	const { data: rawStorageSummaryData } = useFreezerStorageSummaryQuery(
		freezer?.id || -1,
		{ skip: !freezer }
	);

	const storageSummaryData = rawStorageSummaryData;

	const tabItems = [
		{
			key: 1,
			tabtitle: <TabListTabText tabTitle="Details" />,
			tabcontent: detailContent,
		},
		{
			key: 2,
			tabtitle: <TabListTabText tabTitle="Activity" />,
			tabcontent: activityContent,
		},
		{
			key: 3,
			tabtitle: (
				<TabListTabText
					tabTitle="Analytics"
					withUpgradeIcon={restrictFreezerAnalytics}
				/>
			),
			tabcontent: (
				<AnalyticsTabContent
					title="Inventory distribution"
					storageSummary={{
						dataSource: storageSummaryData,
						columns: [
							{
								title: "Shelf",
								dataIndex: "name",
								key: "name",
								ellipsis: true,
							},
							{
								title: "Racks",
								dataIndex: "racks",
								key: "racks",
								width: 72,
								align: "right",
							},
							{
								title: "Boxes",
								dataIndex: "boxes",
								key: "boxes",
								width: 72,
								align: "right",
							},
							{
								title: "Items",
								dataIndex: "items",
								key: "items",
								width: 82,
								align: "right",
							},
						],
						mockDataSource: storageSummaryData,
					}}
				/>
			),
		},
	];

	return (
		<>
			<SidePanel
				isVisible={isVisible}
				onClose={() => closePanel()}
				header={"Freezer settings"}
				className="fridge-settings-panel"
				dataCy="fridge-settings"
				showIcon={false}
			>
				<TabList
					inSettings
					tabListItems={tabItems}
					largeSize={true}
					hasBottomGradient={false}
				/>
				{/* Modal for rearranging racks on the shelf */}
				<RearrangeModal
					visible={showRearrangeRacksModal}
					width={472}
					onOk={() => setShowRearrangeRacksModal(false)}
				>
					<div className="rearrange-racks-view">
						<ShelfViewContent
							showEditShelfModal={showEditShelfModal}
							showRearrangeRacksModal={showRearrangeRacksModal}
							freezerId={id}
						/>
					</div>
				</RearrangeModal>
				{/* Modal for changing shelf's dimension */}
				<RearrangeModal
					title="Modify layout"
					visible={showEditShelfModal}
					width={472}
					onOk={() => setShowEditShelfModal(false)}
				>
					<div className="change-dimension-view">
						<ShelfViewContent
							showEditShelfModal={showEditShelfModal}
							showRearrangeRacksModal={showRearrangeRacksModal}
							freezerId={id}
							isChangingDimension
						/>
					</div>
				</RearrangeModal>
			</SidePanel>
			<EditFridgeSettings />
		</>
	);
}

function EditFridgeSettings() {
	const {
		isEditFridgeSettingsPanelVisible: isVisible,
		closeEditFridgeSettingsPanel,
		editFridgeSettingsPanelData: { id },
	} = useCommonModalState("editFridgeSettingsPanel");
	const { data: freezer } = useFreezerQuery(id || skipToken);
	const { isLastWorkSpaceUser } = useCurrentWorkspaceUserHooks();

	const viewOnly = freezer?.is_archived;
	/** Team related info */
	const { organization } = useCurrentOrganization();
	const { featureRestrictionData, fetchFeatureRestriction } =
		useCurrentFeatureRestriction();
	const memberCount = featureRestrictionData?.members.current;
	const ref = useRef<HTMLDivElement | null>(null);

	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const [updateFreezer] = useFreezerUpdateMutation();
	const isEnterprise = featureRestrictionData?.plan_name === "enterprise";
	const { data: paginatedWorkspaceUsersData } = useGetWorkspaceUsersQuery(
		{
			page: 1,
			page_size: 2,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);
	const hasTeamMembers =
		paginatedWorkspaceUsersData && paginatedWorkspaceUsersData.count > 1;

	const currentPage = useFreezerPages();
	const currentWorkspaceId = useCurrentWorkspaceUuid();

	const closePanel = () => {
		if (showEditFridge) {
			setEditFridge(false);
		}

		setName("");
		setDescription("");
		setFridgeType(freezer?.freezer_type || 0);

		setTempState({
			name: name,
			description: description,
			freezer_type: fridgeType || 0,
			default_item_type: freezer?.default_item_type || DEFAULT_ITEM_TYPE,
		});

		closeEditFridgeSettingsPanel();
	};

	const [showEditFridge, setEditFridge] = React.useState<boolean>(false);
	const handleEditFridge = (fridge: Freezer) => {
		const failure = (err?: string) => {
			console.log("Failed to edit the Freezer", err, fridge);
			Notification.warning({
				message: "Failed to edit the Freezer",
			});
		};
		updateFreezer(toSharedWriteDto<Freezer>(fridge))
			.unwrap()
			.then((resp) => {
				setEditFridge(false);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${resp.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});

				const newSharingOption = resp.sharing;
				if (
					(currentPage === FREEZER_PAGES.HOME ||
						currentPage === FREEZER_PAGES.CONTENTS) &&
					newSharingOption === "workspace" &&
					!fridge.access.workspaces.includes(currentWorkspaceId)
				) {
					closePanel();
					history.push(appendBaseUrl(FREEZER_PATHS.HOME));
				} else {
					closePanel();
				}
			})
			.catch(() => failure());
	};

	const handleCancel = () => {
		setEditFridge(false);
		setTempState({
			name: name,
			description: description,
			freezer_type: fridgeType,
			default_item_type: freezer?.default_item_type || DEFAULT_ITEM_TYPE,
		});
		closeEditFridgeSettingsPanel();
	};

	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [fridgeType, setFridgeType] = useState<number>(
		freezer?.freezer_type || 0
	);
	const [labelType, setLabelType] = useState<FreezerLabelType>(
		freezer?.label_type || 0
	);
	const [tempState, setTempState] = useState({
		name: freezer?.name || "",
		description: freezer?.description || "",
		freezer_type: freezer?.freezer_type || 0,
		default_item_type: freezer?.default_item_type || DEFAULT_ITEM_TYPE,
	});

	const history = useHistory();
	const { appendBaseUrl } = useOrganizationRouter();
	const planName = featureRestrictionData?.plan_name;

	const {
		selectedWorkspaces,
		setSelectedWorkspaces,
		selectedSharingOption,
		setSelectedSharingOption,
		sharedObjectFields,
	} = useSharingState(freezer as SharedInternalObj<Freezer>, showEditFridge);

	useEffect(() => {
		if (name === "" || name === undefined) {
			const temp = freezer;
			setName(temp?.name || "");
			setLabelType(temp?.label_type || 0);
			setDescription(temp?.description || "");

			setTempState({
				name: (freezer?.name || "").trim(),
				description: freezer?.description || "",
				freezer_type: freezer?.freezer_type || 0,
				default_item_type:
					freezer?.default_item_type || DEFAULT_ITEM_TYPE,
			});
		}
	}, [showEditFridge, freezer]);

	useEffect(() => {
		setSelectedWorkspaces(freezer?.access.workspaces || []);
	}, [freezer]);

	useEffect(() => {
		if (!memberCount) {
			// when freezerSetting is in edit mode, but there is no featureRestrictionData in redux
			fetchFeatureRestriction();
		}
	}, [tempState]);

	const validateForm = () => {
		return !!tempState.name?.trim().length;
	};

	const handleSubmit = () => {
		if (validateForm()) {
			handleEditFridge({
				...freezer,
				name: tempState.name?.trim(),
				description: tempState.description,
				freezer_type: tempState.freezer_type,
				default_item_type: tempState.default_item_type,
				...sharedObjectFields,
			} as Freezer);
		}
	};

	const isInviteRequired =
		!isEnterprise &&
		!hasTeamMembers &&
		selectedSharingOption === "personal";

	const restrictionWarnings: SharingLevelRestrictionWarning[] = [
		{
			warning: (
				<div>
					<Typography>
						To create a shared freezer, you must invite a colleague
						first.
					</Typography>
					<ButtonV2
						onClick={() =>
							history.push({
								pathname: appendBaseUrl(
									ACCOUNT_SETTINGS_PATHS.MANAGE_TEAM.route
								),
								state: {
									inviteVisible: true,
								},
							})
						}
						type="link"
					>
						Click here to invite others
					</ButtonV2>
				</div>
			),
			visible: planName === "enterprise" ? false : isInviteRequired,
		},
	];

	return (
		<SidePanel
			isVisible={isVisible}
			onClose={handleCancel}
			header={"Edit freezer"}
			className="fridge-settings-panel"
			dataCy="fridge-settings"
			showIcon={false}
			hideHeader
		>
			<EditableSidePanel
				edit={!viewOnly}
				showEdit={() => handleCancel()}
				onConfirm={handleSubmit}
				validateForm={validateForm}
				type="freezer"
				dataCy="fridge-settings"
			>
				<div className="edit-fridge-form">
					<InputV3
						value={tempState.name}
						onChange={(e) =>
							setTempState({
								...tempState,
								name: e.target.value,
							})
						}
						label="Freezer name*"
						dataCy="fridge-settings-editable-name"
					/>
					<TextArea
						label="Description"
						placeholder="Keep everyone on the same page"
						value={tempState.description}
						style={{ minHeight: 56 }}
						onChange={(e) =>
							setTempState({
								...tempState,
								description: e.target.value,
							})
						}
						dataCy="fridge-settings-editable-description"
					/>
					<div className="select-fields">
						<div className="fridge-type-edit-container">
							<Typography
								variant="body"
								medium
								className="edit-description-label"
							>
								Freezer type*
							</Typography>
							<SelectV2
								value={tempState.freezer_type}
								onChange={(val) =>
									setTempState({
										...tempState,
										freezer_type: parseInt(val as string),
									})
								}
								optionLabelProp="label"
								dataCy="fridge-settings-editable-type"
							>
								{idKeysOfConst(FREEZER_TYPES).map(
									(typeName) => {
										return (
											<SelectV2.Option
												key={typeName}
												value={typeName}
												label={FREEZER_TYPES[typeName]}
												data-cy={`fridge-settings-editable-type-${typeName}`}
											>
												<SelectV2.SelectedOpt
													isSelected={
														typeName ===
														tempState.freezer_type
													}
													label={
														FREEZER_TYPES[typeName]
													}
												/>
											</SelectV2.Option>
										);
									}
								)}
							</SelectV2>
						</div>
						<div className="fridge-type-edit-container">
							<Typography
								variant="body"
								medium
								className="edit-description-label"
							>
								Item type
							</Typography>
							<ItemTypeSelect
								value={tempState.default_item_type as number}
								defaultValue={
									tempState.default_item_type ||
									DEFAULT_ITEM_TYPE
								}
								onSelect={(value) =>
									setTempState({
										...tempState,
										default_item_type: value as any,
									})
								}
								dataCy="fridge-settings-editable-item-type"
							/>
						</div>
					</div>
					<div>
						<Typography
							variant="body"
							medium
							color="text-primary-v2"
							style={{ marginBottom: 8 }}
						>
							Share with
						</Typography>
						<SharingObjectButtons
							value={selectedSharingOption}
							objectType="freezer"
							size={"small"}
							useSmallButtons={true}
							onChange={(value) => {
								if (!selectedSharingOption) return;

								const sharedFreezerValues = [
									"workspace",
									"org",
								];
								const wasPersonalFridge =
									selectedSharingOption === "personal";
								const reachedSharedFreezerLimit =
									featureRestrictionData?.shared_freezer
										?.is_limit_reached;
								const toSharedFridge =
									sharedFreezerValues.includes(value);

								if (ref.current && value === "workspace") {
									setTimeout(() => {
										ref.current?.scrollIntoView({
											behavior: "smooth",
										});
									}, 100);
								}

								if (
									wasPersonalFridge &&
									toSharedFridge &&
									reachedSharedFreezerLimit
								) {
									openUpgradeModal({
										type: "FREEZER",
									});
									return;
								}

								setSelectedSharingOption(value);
							}}
							disablePersonalOption={freezer?.is_shared}
							disableWorkspacesOption={isLastWorkSpaceUser}
							personalTooltip={
								freezer?.is_shared
									? "Shared freezer cannot be changed to private"
									: undefined
							}
							hideHeader
							restrictionWarnings={restrictionWarnings}
						/>
					</div>
					{isEnterprise && selectedSharingOption === "workspace" && (
						<WorkspaceSelector
							values={selectedWorkspaces}
							setValues={setSelectedWorkspaces}
						/>
					)}
				</div>
				<div ref={ref}></div>
			</EditableSidePanel>
		</SidePanel>
	);
}
