import { anchormeValidator } from "@common/components/GenemodAnchorme/GenemodAnchorme";
import { Color, TypographyColorClassMap } from "@common/styles/Colors";
import { Demo, DemoSection, DemoWrapper, GenemodAnchorme } from "@components";
import { TooltipProps } from "antdv5";
import classNames from "classnames";
import React, { CSSProperties, useRef, useState } from "react";
import Tooltip from "../Tooltip";
import styles from "./index.module.scss";

interface Props {
	link: string;
	children: string;
	style?: CSSProperties;
}

const Hyperlink: React.FC<Props> = ({ link, children, style }) => {
	const pattern = /^((http|https):\/\/)/;

	if (!pattern.test(link)) {
		link = `//${link}`;
	}

	return (
		<a href={link} target="_blank" rel="noopener noreferrer" style={style}>
			{children}
		</a>
	);
};

/** TODO: Remember to remove old variants after cleanup */
export type FONT_VARIANT =
	| "regular"
	| "headline2"
	| "headline3"
	| "headline4"
	| "headline5"
	| "small"
	| "footnote"
	| "caption"
	| "largeTitle"
	| "title"
	| "headline"
	| "subheadline"
	| "body"
	| "label"
	| "caption"
	| "h1"
	| "h2"
	| "h3"
	| "h4"
	| "h5"
	| "h6"
	| "subtitle1"
	| "subtitle2"
	| "body1"
	| "body2"
	| "smallprint"
	| "normal"
	| "editorTitle";

export type TypographyProps = Omit<
	React.HTMLAttributes<HTMLDivElement>,
	"style"
> & {
	variant?: FONT_VARIANT;
	color?: Color | "none";
	bold?: boolean;
	semibold?: boolean;
	medium?: boolean;
	resize?: boolean;
	// prevent color from being set in style
	// style?: Omit<CSSProperties, "color"> & {
	// 	color?: never;
	// };
	style?: CSSProperties;
	ellipsis?: boolean;
	hideTooltip?: boolean;
	withLinks?: boolean;
	linkStyle?: CSSProperties;
	link?: string;
	tooltipPlacement?: TooltipProps["placement"];
	dataCy?: string;
};

function _Typography(
	{
		variant = "body",
		color = DEFAULT_COLORS[variant],
		resize = true,
		className,
		bold = false,
		semibold = false,
		medium = false,
		children,
		ellipsis,
		hideTooltip,
		withLinks = false,
		linkStyle,
		link,
		tooltipPlacement = "topLeft",
		dataCy,
		...props
	}: TypographyProps,
	ref: any
): JSX.Element {
	const [tooltipVis, _setTooltipVis] = useState(false);
	const handler = useRef<NodeJS.Timeout | null>(null);
	let emphasizeClass = styles.semibold;
	if (
		!semibold &&
		(variant === "largeTitle" ||
			variant === "title" ||
			variant === "headline" ||
			variant === "subheadline" ||
			variant === "label" ||
			variant === "caption" ||
			variant === "editorTitle")
	) {
		emphasizeClass = styles.bold;
	} else if (medium) {
		emphasizeClass = styles.medium;
	}
	const typographyRef = useRef<HTMLDivElement>(null);
	let content = children;

	const setTooltipVis = (vis: boolean) => {
		/** Show Tooltip only if content is ellipsis. */
		if (!vis) {
			_setTooltipVis(false);
			return;
		}
		const divRef = ref ?? typographyRef;
		if (!divRef?.current) {
			return;
		}
		if (
			divRef.current.clientWidth < divRef.current.scrollWidth ||
			divRef.current.clientHeight < divRef.current.scrollHeight
		) {
			_setTooltipVis(true);
		}
	};

	const anchorStyle = (withLinks && linkStyle && { ...linkStyle }) || {};
	const hasValidLink =
		typeof link === "string" && anchormeValidator.url(link);

	const isLink = typeof children === "string" && withLinks;

	const displayTextAsLink = hasValidLink && isLink;

	if (displayTextAsLink) {
		content = (
			<Hyperlink link={link} style={anchorStyle}>
				{children || link}
			</Hyperlink>
		);
	} else if (isLink) {
		content = (
			<GenemodAnchorme target="_blank" rel="noopener" style={anchorStyle}>
				{children}
			</GenemodAnchorme>
		);
	}

	if (ellipsis) {
		content = (
			<Tooltip
				title={
					<Typography ellipsis={false} color="text-inverse">
						{children?.toString()}
					</Typography>
				}
				placement={tooltipPlacement}
				getPopupContainer={() => document.body}
				visible={tooltipVis}
				hideTooltip={hideTooltip}
			>
				{content}
			</Tooltip>
		);
	}

	return (
		<div
			data-cy={dataCy}
			ref={ref ?? typographyRef}
			onMouseEnter={() => {
				handler.current = setTimeout(() => {
					setTooltipVis(true);
				}, 500);
			}}
			onMouseLeave={() => {
				if (handler.current) clearTimeout(handler.current);
				setTooltipVis(false);
			}}
			className={classNames(
				className,
				styles[variant],
				{ [emphasizeClass]: bold || semibold || medium },
				{ [styles.resize]: resize },
				{ [styles.ellipsis]: ellipsis },
				{
					[TypographyColorClassMap[color as Color]]: color !== "none",
				},
				{ [styles.withLinks]: withLinks }
			)}
			{...props}
		>
			{content}
		</div>
	);
}
const Typography = React.forwardRef(_Typography);
export default Typography;

export const DEFAULT_COLORS = {
	regular: "text-primary-v2",
	headline2: "text-primary-v2",
	headline3: "text-primary-v2",
	headline4: "text-primary-v2",
	headline5: "text-primary-v2",
	small: "text-primary-v2",
	footnote: "text-primary-v2",
	caption: "text-primary-v2",
	editorTitle: "text-primary-v2",
	largeTitle: "text-primary-v2",
	title: "text-primary-v2",
	headline: "text-primary-v2",
	subheadline: "text-secondary-v2",
	body: "text-secondary-v2",
	label: "text-secondary-v2",
	h1: "text-primary-v2",
	h2: "text-primary-v2",
	h3: "text-primary-v2",
	h4: "text-primary-v2",
	h5: "text-primary-v2",
	h6: "text-primary-v2",
	subtitle1: "text-primary-v2",
	subtitle2: "text-primary-v2",
	body1: "text-secondary-v2",
	body2: "text-secondary-v2",
	smallprint: "text-tertiary-v2",
} as Record<FONT_VARIANT, Color>;

export function TYPOGRAPHY_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo>
					<div
						style={{
							width: 50,
							overflow: "hidden",
						}}
					>
						<Typography ellipsis>
							Long Long Long Long Long Long Long
						</Typography>
					</div>
				</Demo>
			</DemoSection>
			<DemoSection>
				<Demo
					title="Standard Usage"
					description="Different font styles"
				>
					<Typography variant="editorTitle">
						This is editorTitle (Font-size: 30px, Line-height: 36px)
					</Typography>
					<br />
					<Typography variant="largeTitle">
						This is largeTitle (Font-size: 22px, Line-height: 38px)
					</Typography>
					<br />
					<Typography variant="title">
						This is title (Font-size: 20px, Line-height: 32px)
					</Typography>
					<br />
					<Typography variant="headline">
						This is headline (Font-size: 16px, Line-height: 26px)
					</Typography>
					<br />
					<Typography variant="subheadline">
						This is subheadline (Font-size: 14px, Line-height: 22px)
					</Typography>
					<br />
					<Typography variant="body">
						This is body (Font-size: 14px, Line-height: 24px)
					</Typography>
					<br />
					<Typography variant="label">
						This is label (Font-size: 13px, Line-height: 18px)
					</Typography>
					<br />

					<Typography variant="caption">
						This is caption (Font-size: 11px, Line-height: 16px)
					</Typography>
					<br />
				</Demo>
				<Demo
					title="Capitalization"
					description="Capitalization rules for the Genemod platform"
				>
					<Typography>
						Genemod uses sentence case in most instances. Generally,
						if a word is not capitalized in a sentence, it shouldn’t
						be capitalized on the platform. This applies to headers,
						buttons, labels and text blocks. The list shows some
						principles on applying sentence case.
					</Typography>
					<ul>
						<li>
							<Typography>
								The first words and proper nouns are
								capitalized. Proper nouns include any Genemod
								brand names.
							</Typography>
							<Typography
								variant="headline"
								color="text-primary"
								bold
							>
								e.g. &quot;I&quot; is capitalized when referring
								to Genemod&apos;s product &quot;Inventory&quot;;
								&quot;i&quot; is lowercase when referring to
								inventory as a generic term.
							</Typography>
						</li>
						<br />
						<li>
							<Typography>
								When words are joined with a slash,
								capitalization of the second word follows the
								same format as the first word.
							</Typography>
							<Typography
								variant="headline"
								color="text-primary"
								bold
							>
								e.g. &quot;Volumn/Mass&quot;
							</Typography>
						</li>
						<br />
						<li>
							<Typography>
								If a title or heading includes a colon,
								capitalize the first word after.
							</Typography>
							<Typography
								variant="headline"
								color="text-primary"
								bold
							>
								e.g. &quot;Important: This option cannot be
								undo&quot;. Notice &quot;I&quot; and
								&quot;T&quot; are both capitalized.
							</Typography>
						</li>
						<br />
						<li>
							<Typography>
								Avoid using upper case to draw user attention.
								When needed, use italic sparingly.
							</Typography>
						</li>
					</ul>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
