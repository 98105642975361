/* eslint-disable no-case-declarations */
import React, { useEffect } from "react";
import {
	BaseElement,
	Transforms,
	Editor,
	Node,
	NodeEntry,
	Range,
	Text,
} from "slate";
import { ReactEditor, RenderElementProps } from "slate-react";
import { RenderedEditor } from "../types";
import {
	overrideFunction,
	registerRenderElements,
	RegisteredRenderElementsEditor,
} from "./PluginHelpers";
import { Awareness } from "y-protocols/awareness";
import { UtilityEditor } from "./withUtilityPlugin";
import {
	Mention,
	MultiMentionElementInput,
} from "@common/components/Mention/Mention";
import {
	MentionNotificationOptions,
	MentionTypeData,
} from "../../Mention/Mention";
import { PluginOptions } from "@common/components/SlateTextArea/SlateTextAreaHooks";
import { flattenUnion } from "@helpers/TypeHelpers";
import { TableData } from "../TableV2/types";
import {
	ExperimentId,
	OrganizationUserId,
	GenemodDocumentUUID,
	ExperimentDocumentLink,
	ExperimentDocumentLinkId,
} from "@common/types";
import { over } from "cypress/types/lodash";
import { useExperimentLinkDeleteMutation } from "@redux/ProjectManagement/PmApiSlice";

export const MENTION_TYPE = "mention";
export const MENTION_INPUT_TYPE = "mention-input";
export const MENTION_TRIGGER = "@";

export type MentionContext = "EDITOR" | "TEXT_AREA";
export type MentionType = "person" | "experiment" | "table" | "all";

/**
 * Defines the minimum editor definition for the mention plugin to work.
 */
export type MinimalMentionEditor = RegisteredRenderElementsEditor &
	RenderedEditor &
	UtilityEditor;
export type MentionEditor = {
	isMentionEditor: true;
	/**
	 * Is a mention or mention input currently active?
	 */
	isInMention: () => boolean;
};

export const isMentionEditor = (
	editor: any
): editor is MinimalMentionEditor & MentionEditor => editor.isMentionEditor;

export type MentionElement = BaseElement & {
	type: typeof MENTION_TYPE;
	id: GenemodDocumentUUID | OrganizationUserId | ExperimentId;
	mention_type: MentionType;
	notificationId?: number;
	// Deprecated property but needed to change
	// the mentions from all to new version
	orgUser?: number;
	experiment_document_link?: ExperimentDocumentLinkId;
};

export const isMentionNode = (n: any): n is MentionElement =>
	n.type === MENTION_TYPE;

export type MentionElementPayload = {
	text?: string;
	id: GenemodDocumentUUID | OrganizationUserId | ExperimentId;
	type: MentionType;
	notificationId?: number;
	experiment_document_link?: ExperimentDocumentLinkId;
};

export const makeMentionElement = ({
	text = "",
	id,
	type,
	notificationId,
	experiment_document_link,
}: MentionElementPayload): MentionElement => ({
	type: MENTION_TYPE,
	children: [{ text }],
	id,
	mention_type: type,
	notificationId,
	experiment_document_link,
});

const MENTION_INSERT_TYPES = ["NO_TYPE", "list-item", "table-data-v2"] as const;

/**
 * Renders the given placeholder plugin when the document body is empty.
 */
export const useMentionsPlugin = (context: MentionContext) => {
	const [deleteExperimentLinkMention] = useExperimentLinkDeleteMutation();

	return <T extends MinimalMentionEditor>(
		editor: T,
		notificationOptions?: MentionNotificationOptions,
		options?: PluginOptions
	): T & MentionEditor => {
		const e = editor as T & MentionEditor;
		e.isMentionEditor = true;
		e.isInMention = () =>
			!![...editor.getNodesOfType("mention")].length ||
			!![...editor.getNodesOfType("mention-input")].length;

		return withMentionInputPlugin(
			context,
			withMentionPlugin(context, e, deleteExperimentLinkMention as any),
			notificationOptions,
			options
		);
	};
};

const withMentionPlugin = <T extends MinimalMentionEditor>(
	context: MentionContext,
	editor: T,
	deleteExperimentLinkMention: (
		experiment_document_link: ExperimentDocumentLinkId
	) => any
): T => {
	const override = overrideFunction(editor);

	/**
	 * Render the mention
	 */
	registerRenderElements(editor)(MENTION_TYPE)((props) => (
		<MentionElement {...props} editor={editor} context={context} />
	));

	const { normalizeNode } = editor;
	editor.normalizeNode = (entry) => {
		const [node, path] = entry;
		if (isMentionNode(node)) {
			// Correct old mentions that have orgUser instead of id property by adding mention_type and id
			if (node.orgUser) {
				const normalizedNode = {
					children: node.children,
					id: node.orgUser,
					type: node.type,
					mention_type: "person",
				};
				Transforms.setNodes(editor, normalizedNode, { at: path });
				return;
			}
		}
		const isMentionInputOrDay = (n: Node) =>
			flattenUnion(n).type === MENTION_INPUT_TYPE ||
			flattenUnion(n).type === "day";
		if (isMentionInputOrDay(node)) {
			// check if any children nodes are also mentions and remove them
			const children = [...Node.children(editor, path)];
			for (const [childNode, childPath] of children) {
				if (isMentionInputOrDay(childNode)) {
					Transforms.removeNodes(editor, { at: childPath });
					return;
				}
			}
		}
		normalizeNode(entry);
	};

	const { apply } = editor;
	editor.apply = (operation) => {
		if (
			operation.type === "remove_node" &&
			isMentionNode(operation.node) &&
			operation.node.experiment_document_link
		) {
			// Custom function to run when a Experiment Mention node is deleted
			deleteExperimentLinkMention(
				operation.node.experiment_document_link
			);
		}
		apply(operation);
	};

	override("isInline")((isInline) => (element) => {
		return isMentionNode(element) || isInline(element);
	});

	override("isVoid")((isVoid) => (element) => {
		return isMentionNode(element) || isVoid(element);
	});

	return editor;
};

type ElementProps = RenderElementProps & {
	editor: MinimalMentionEditor;
	context: MentionContext;
	notificationOptions?: MentionNotificationOptions;
	options?: PluginOptions;
};

const MentionElement = ({
	children,
	element,
	editor,
	context,
}: ElementProps) => {
	element = element as MentionElement;

	const activeMentions = [...editor.getNodesOfType(MENTION_TYPE)];
	const active = !!activeMentions.find((am) => am[0] === element);

	const getThisMention = () => {
		const thisMention = [
			...editor.getNodesOfType(MENTION_TYPE, { at: [] }),
		].find((am) => am[0] === element);
		return thisMention;
	};

	const onClick = () => {
		const thisMention = getThisMention();
		if (!thisMention) return;
		const path = thisMention[1];
		const [start, end] = Editor.edges(editor, path);

		// Transforms.move(editor, { edge: start });

		Transforms.setSelection(editor, { focus: start, anchor: start });
	};

	return (
		<>
			<Mention
				context={context}
				notificationId={element.notificationId}
				experimentDocumentLink={element.experiment_document_link}
				typeData={
					{
						id: element.id,
						type: element.mention_type as Exclude<
							MentionType,
							"all"
						>,
					} as MentionTypeData
				}
				active={active}
				onClick={onClick}
			/>
			{/* Children is empty but has to be passed through still */}
			{children}
		</>
	);
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MENTION INPUT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export type MentionInputElement = BaseElement & {
	type: typeof MENTION_INPUT_TYPE;
	clientID: number;
	mention_type: MentionType;
};

export const isMentionInputNode = (n: any): n is MentionInputElement =>
	n.type === MENTION_INPUT_TYPE;

export const MENTION_INPUT_OFFLINE_CLIENT_ID = -1;

export const makeMentionInputElement = ({
	text = "",
	clientID = MENTION_INPUT_OFFLINE_CLIENT_ID,
	mention_type,
}: {
	text?: string;
	clientID?: number;
	mention_type: MentionType;
}): MentionInputElement => ({
	type: MENTION_INPUT_TYPE,
	children: [{ text }],
	clientID,
	mention_type,
});

const withMentionInputPlugin = <T extends MinimalMentionEditor>(
	context: MentionContext,
	editor: T,
	notificationOptions?: MentionNotificationOptions,
	options?: PluginOptions
): T => {
	const {
		insertMentionInput,
		findActiveMentionInput,
		clearOrphanInputs,
		isSelectionInMentionInput,
		isMentionInputEmpty,
		isSelectionInValidMentionBlock,
		removeInput,
		isAtStartOfInput,
		isAtEndOfInput,
	} = mentionHelpers(editor);

	const override = overrideFunction(editor);

	/**
	 * Render the mention
	 */
	registerRenderElements(editor)(MENTION_INPUT_TYPE)((props) => (
		<MentionElementInput
			{...props}
			editor={editor}
			context={context}
			notificationOptions={notificationOptions}
			options={options}
		/>
	));

	/**
	 * When we focus on another part of the document then remove any inactive inputs
	 */
	override("onChange")((onChange) => (...args) => {
		clearOrphanInputs();
		return onChange(...args);
	});

	override("onKeyDown")((onKeyDown) => (event) => {
		const activeMention = findActiveMentionInput();
		if (!activeMention) return onKeyDown(event);

		switch (event.key) {
			// These keys are handled in the mention element
			case "ArrowDown":
			case "ArrowUp":
			case "Enter":
			case "Tab":
				event.preventDefault();
				break;
			case "Backspace":
			case "ArrowLeft":
				// When nothing is inputted, and user types ENTER/ESC/ SPACE/LEFT/RIGHT or click outside, we will display “@“ as normal text
				if (isAtStartOfInput()) {
					event.preventDefault();
				}
				if (isMentionInputEmpty()) {
					removeInput(true);
				}
				break;
			case "ArrowRight":
				// Like for arrow/tab/enter, we want to remove the input if it's empty, but do not prevent default unless empty
				if (isAtEndOfInput()) {
					event.preventDefault();
				}
				if (isMentionInputEmpty()) {
					removeInput();
				}
				break;
			case " ":
				if (isMentionInputEmpty()) {
					event.preventDefault();
					removeInput();
				}
				break;
			case "Escape":
				event.preventDefault();
				removeInput();
				break;
		}
	});

	override("insertText")((insertText) => (text) => {
		// Check if currently inside "table-data-v2" node
		const { selection } = editor;
		// Look for table-data-v2 node
		if (selection) {
			const [matches] = Editor.nodes(editor, {
				at: selection,
				match: (node) => {
					return (node as any).type === "table-data-v2";
				},
			});

			if (matches) {
				const node = matches[0] as TableData;
				const hasMention = cellContainsMention(node);
				const isEmpty = node.children.some((node) => {
					return Text.isText(node) && node.text.trim() === "";
				});
				if (!hasMention) {
					if (text === MENTION_TRIGGER && !isEmpty) {
						return;
					}
				} else {
					return;
				}
			}
		}

		if (
			!editor.selection ||
			text !== MENTION_TRIGGER ||
			isSelectionInMentionInput() ||
			!isSelectionInValidMentionBlock()
		) {
			return insertText(text);
		}

		// Make sure a mention input is created at the beginning of line or after a whitespace
		const previousChar = Editor.string(
			editor,
			Editor.range(
				editor,
				editor.selection,
				Editor.before(editor, editor.selection)
			)
		);

		const nextChar = Editor.string(
			editor,
			Editor.range(
				editor,
				editor.selection,
				Editor.after(editor, editor.selection)
			)
		);

		const beginningOfLine = previousChar === "";
		const endOfLine = nextChar === "";
		const precededByWhitespace = previousChar === " ";
		const followedByWhitespace = nextChar === " ";

		if (
			(beginningOfLine || precededByWhitespace) &&
			(endOfLine || followedByWhitespace)
		) {
			insertMentionInput("all");
			return;
		}

		return insertText(text);
	});

	override("isInline")((isInline) => (element) => {
		return isMentionInputNode(element) ? true : isInline(element);
	});

	return editor;
};

const MentionElementInput = ({
	attributes,
	children,
	editor,
	element,
	context,
	notificationOptions,
	options,
}: ElementProps) => {
	const currentText = Node.string(element);

	return (
		<MultiMentionElementInput
			currentText={currentText}
			type={
				options?.mention_type || (element as any).mention_type || "all"
			}
			editor={editor}
			notificationOptions={notificationOptions}
		>
			<span
				contentEditable={true}
				suppressContentEditableWarning={true}
				{...attributes}
			>
				{children}
			</span>
		</MultiMentionElementInput>
	);
};

export const mentionHelpers = (editor: MinimalMentionEditor) => {
	// awareness is not set during plugin setup yet
	const getAwareness = () =>
		(editor as any)?.awareness as Awareness | undefined;
	const getClientID = () =>
		getAwareness()?.clientID || MENTION_INPUT_OFFLINE_CLIENT_ID;
	const getClientIDs = () =>
		[
			...(getAwareness()?.states?.keys?.() || [
				MENTION_INPUT_OFFLINE_CLIENT_ID,
			]),
		] as number[];

	const matchOption = {
		match: (node: Node) =>
			(node as MentionInputElement).clientID === getClientID(),
	};

	/**
	 * Add mention to the editor at the current location
	 */
	const insertMentionInput = (mention_type: MentionType) => {
		import("@common/components/Editor/formats/Table").then((c) => {
			const { Table } = c;
			const inTable = Table.isInTable(editor);
			if (inTable) {
				const currentCell = document.querySelector(
					`.${Table.getCellAnchorClass()}`
				);
				if (currentCell) {
					const node = ReactEditor.toSlateNode(editor, currentCell);
					const path = ReactEditor.findPath(editor, node);
					Transforms.select(editor, path);
				}
			}
			Transforms.insertNodes(
				editor,
				makeMentionInputElement({
					clientID: getClientID(),
					mention_type,
				})
			);
		});
	};

	const findActiveMentionInput = (): NodeEntry<Node> | undefined => {
		try {
			return [
				...editor.getNodesOfType(MENTION_INPUT_TYPE, matchOption),
			]?.[0];
		} catch (e) {
			return undefined;
		}
	};

	/**
	 * Replace the mention input with a given node or string, defaults to replacing
	 * with the string returned from @getMentionInputTextWithTrigger
	 */
	const replaceMentionInput = (
		entry = findActiveMentionInput(),
		replacement: Node | string = getMentionInputTextWithTrigger(entry)
	) => {
		entry && editor.replaceNodes(replacement, { at: entry[1] });
	};

	/**
	 * Is our cursor currently inside of a mention input?
	 */
	const isSelectionInMentionInput = () => {
		return !!findActiveMentionInput();
	};

	/**
	 * Get whatever is currently in the mention input, with the @ prepended
	 */
	const getMentionInputTextWithTrigger = (input = findActiveMentionInput()) =>
		`${MENTION_TRIGGER}${!input ? "" : Node.string(input[0])}`;

	/**
	 * Is mention input empty
	 */
	const isMentionInputEmpty = (input = findActiveMentionInput()) =>
		getMentionInputTextWithTrigger(input) === MENTION_TRIGGER;

	/**
	 * Is our cursor in a place where we can insert a mention?
	 */
	const isSelectionInValidMentionBlock = () => {
		const activeNodeTypes = editor
			.getAllActiveNodes()
			.map((entry) => (entry as any)?.[0]?.type || "NO_TYPE");

		return !!activeNodeTypes.find((type) =>
			MENTION_INSERT_TYPES.includes(type)
		);
	};

	/**
	 * Document cleanup. Remove inactive inputs from other users and current user.
	 */
	const clearOrphanInputs = () => {
		// Every active client id

		// Find all the inputs without client ids
		[
			...editor.getNodesOfType(MENTION_INPUT_TYPE, {
				at: [],
				match: (n) =>
					!getClientIDs().includes(
						(n as MentionInputElement).clientID
					),
			}),
			// Remove them
		].forEach((entry) => replaceMentionInput(entry));

		if (!isSelectionInMentionInput()) {
			// Now if we are not currently editing an input, find all of ours
			[
				...editor.getNodesOfType(MENTION_INPUT_TYPE, {
					...matchOption,
					at: [],
				}),
				// and remove them
			].forEach((entry) => replaceMentionInput(entry));
		}
	};

	/**
	 * Add mention to the editor
	 */
	type InsertMentionPayload = {
		selectedId: string;
		notificationId?: number;
		experiment_document_link?: ExperimentDocumentLinkId;
	};
	const insertMention = ({
		selectedId,
		notificationId,
		experiment_document_link,
	}: InsertMentionPayload) => {
		const [type, id] = selectedId.split("-");
		const payload: MentionElementPayload = {
			id:
				type === "table"
					? (selectedId.slice(6) as GenemodDocumentUUID)
					: (Number(id) as OrganizationUserId | ExperimentId),
			type: type as "experiment" | "person" | "table",
			notificationId,
		};
		if (experiment_document_link) {
			payload["experiment_document_link"] = experiment_document_link;
		}
		replaceMentionInput(undefined, makeMentionElement(payload));
		Transforms.move(editor, { distance: 2 });

		try {
			// If we are at the end of the line then we have to do some extra stuff to move our cursor.
			if (editor.selection) {
				const selectionIsMention =
					[...editor.getNodesOfType(MENTION_TYPE)].length > 0;
				if (selectionIsMention) {
					// our cursor is still on the mention even after the Transform.move, meaning we inserted at the end of the line
					// add a space and move the cursor
					const after = Editor.after(editor, editor.selection);
					Transforms.insertNodes(
						editor,
						{ text: " " },
						{ at: after }
					);
					Transforms.move(editor, { distance: 2 });
				}
			}
		} catch (e) {
			console.warn(e);
		}
	};

	/**
	 * Remove the active mention input and replace it with whatever text is currently entered. Handle moving cursor afterwards.
	 */
	const removeInput = (cursorLeft = false) => {
		const currentText = getMentionInputTextWithTrigger() || "";
		replaceMentionInput();
		if (!cursorLeft) {
			Transforms.move(editor, { distance: currentText.length + 1 });
		}
	};

	/**
	 * Is the cursor at the start of the current input?
	 */
	const isAtStartOfInput = () => {
		const input = findActiveMentionInput();
		const { selection } = editor;
		if (!input || !selection) return false;
		const path = input[1];
		const [start] = Editor.edges(editor, path);
		return Range.includes(selection, start);
	};

	/**
	 * Is the cursor at the end of the current input?
	 */
	const isAtEndOfInput = () => {
		const input = findActiveMentionInput();
		const { selection } = editor;
		if (!input || !selection) return false;
		const path = input[1];
		const [, end] = Editor.edges(editor, path);
		return Range.includes(selection, end);
	};

	return {
		insertMentionInput,
		findActiveMentionInput,
		isSelectionInMentionInput,
		isMentionInputEmpty,
		isSelectionInValidMentionBlock,
		clearOrphanInputs,
		insertMention,
		removeInput,
		isAtStartOfInput,
		isAtEndOfInput,
	} as const;
};

export const cellContainsMention = (node: TableData): boolean => {
	// Flattens the node's children into a single array of nodes.
	const flattenedChildren = node.children.map((n: Node) => flattenUnion(n));

	// Checks if the cell contains a mention of an experiment or all experiments.
	const hasExperimentInCell = !!flattenedChildren.find(
		(n) =>
			n.type === "mention" &&
			(n.mention_type === "experiment" || n.mention_type === "all")
	);

	// Checks if the cell contains a mention of a person or all persons.
	const hasPersonInCell = !!flattenedChildren.find(
		(n) =>
			n.type === "mention" &&
			(n.mention_type === "person" || n.mention_type === "all")
	);

	// Checks if the cell contains a mention of a day.
	const hasDayInCell = !!flattenedChildren.find((n) => n.type === "day");

	// Returns true if any of the above checks are true.
	return hasExperimentInCell || hasPersonInCell || hasDayInCell;
};
