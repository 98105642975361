import {
	AIButton,
	DropDown,
	GenemodIcon,
	IconName,
	LayerSystemContainer,
	SelectV2,
	Select,
	Typography,
} from "@common/components";
import { useConfig } from "@common/config/GenemodConfig";
import {
	Color,
	ColorCssVar,
	DeprecatedDocumentColor,
	translateDeprecatedCssColorVarToColor,
} from "@common/styles/Colors";
import cn from "classnames";
import { RevisionType } from "@common/types/ChatGPT";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { selectOpenOverlayNotechain } from "@redux/ProjectManagement/PmDocumentSlices";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { Editor, Range, Editor as SlateEditor } from "slate";
import { ReactEditor } from "slate-react";
import { ColorPicker, ToolbarBtn } from "../../components";
import { ColorPickerIndicatorWrapper } from "../../components/ColorPicker";
import { ReviseButton } from "../../components/ReviseHighlightedTextHangingToolbar/ReviseHighlightedTextHangingToolbar";
import { useGenemodSlate } from "../../hooks/UseGenemodSlateHook";
import { Table } from "../Table";
import styles from "./HangingToolbar.module.scss";
import { HeaderVariant } from "../../plugins/headers/types";

const DEFAULT_RECT = {
	x: 0,
	y: 0,
	width: 1,
	height: 1,
	top: 0,
	left: 0,
};
export const PM_EDITOR_ID = "pm-editor-content";
type StringFn = (arg: string | null) => void;

type EditorButton = {
	icon: IconName;
	description: string;
	onClick: () => void;
	active?: boolean;
};

const HangingToolbar = () => {
	const { data: user } = useCurrentUserQuery();
	const toolbar = useRef<HTMLDivElement | null>(null);
	const editor = useGenemodSlate();
	const selectionIsExpanded =
		!editor.readOnly &&
		editor.selection &&
		Range.isExpanded(editor.selection);
	const { openEditorLinkModal } = useCommonModalState("editorLinkModal");
	const handler = useRef<NodeJS.Timeout | null>(null);
	const openOverlayNotechain = useSelector(selectOpenOverlayNotechain);
	const CHATGPT_FEATURE_ENABLED = useConfig("CHATGPT_FEATURE_ENABLED");

	const [_isVisible, setIsVisible] = useState(false);
	const isVisible = !openOverlayNotechain && _isVisible;

	const inTitle = Table.isInTitle(editor);

	useEffect(() => {
		if (selectionIsExpanded && !inTitle) {
			handler.current = setTimeout(() => {
				setIsVisible(true);
			}, 500);
		} else {
			if (handler.current) clearTimeout(handler.current);
			setIsVisible(false);
		}
	}, [selectionIsExpanded, inTitle]);

	useHangingBySelection(toolbar);

	const marks = SlateEditor.marks(editor) || {};
	const textColor: Color = marks["color"]
		? translateDeprecatedCssColorVarToColor(
				marks["color"] as DeprecatedDocumentColor
		  )
		: "text-primary";

	const handleTextColor = useMemo(
		() => (color: ColorCssVar) => {
			setTimeout(() => {
				editor.toggleMark("color", color);
			}, 150);
		},
		[editor]
	) as StringFn;

	const headerVariant = editor.getHeaderVariant();
	const TOOLBAR_FONT_SIZE: {
		value: HeaderVariant | "";
		label: string;
		icon: IconName;
	}[] = [
		{ value: "", label: "Normal text", icon: "normal-text" },
		{ value: "h1", label: "Heading 1", icon: "H1" },
		{ value: "h2", label: "Heading 2", icon: "H2" },
		{ value: "h3", label: "Heading 3", icon: "H3" },
	];
	const handleFontSizeClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			setTimeout(() => {
				ReactEditor.focus(editor);
			}, 150);
		},
		[editor, ReactEditor.focus]
	);

	const FontSizeButton = useMemo(() => {
		return (
			<SelectV2
				variant="borderless"
				value={headerVariant || ""}
				onChange={(val) =>
					val
						? editor.convertToHeader(val as HeaderVariant)
						: editor.removeHeaders()
				}
				wrapperClassname={styles.selectButton}
				className={cn(styles.fontSelect, styles.select)}
				isSmall
				optionLabelProp="label"
				suffixIcon={
					<GenemodIcon
						style={{
							transform: "rotate(90deg)",
						}}
						name="chevron-right"
						size="small"
					/>
				}
			>
				{TOOLBAR_FONT_SIZE.map((font, index) => {
					return (
						<Select.Option
							key={index}
							label={font.label}
							value={font.value}
						>
							<Select.SelectedOpt
								label={
									<div
										style={{
											display: "flex",
											gap: "11px",
											alignItems: "center",
										}}
									>
										<GenemodIcon
											size="large"
											fill="text-secondary"
											name={font.icon}
										/>
										<Typography
											variant="label"
											resize={false}
											onClick={handleFontSizeClick}
										>
											{font.label}
										</Typography>
									</div>
								}
							/>
						</Select.Option>
					);
				})}
			</SelectV2>
		);
	}, [headerVariant, handleFontSizeClick]);

	const TextColorButton = useMemo(() => {
		/** @returns {JSX.Element} A JSX element for displaying the current color selection */
		const getTextColorDisplay = () => {
			// Adding time as the key causes the component to pre-process and apply fill property
			return (
				<ColorPickerIndicatorWrapper color={textColor}>
					<GenemodIcon
						forceRerender
						name="format-color-fill"
						fill={textColor}
					/>
				</ColorPickerIndicatorWrapper>
			);
		};

		return (
			<div className={styles.textHighlightButtonContainer}>
				<ColorPicker
					type="color"
					onClick={handleTextColor}
					display={getTextColorDisplay()}
					selectedColor={textColor}
				/>
			</div>
		);
	}, [textColor, handleTextColor]);

	const highlightColor: Color | null = marks["highlight"]
		? translateDeprecatedCssColorVarToColor(
				marks["highlight"] as DeprecatedDocumentColor
		  )
		: null;
	const handleHighlight = useMemo(
		() => (color: Color) => {
			setTimeout(() => {
				ReactEditor.focus(editor);
				editor.toggleMark("highlight", color);
			}, 150);
		},
		[editor, ReactEditor.focus]
	) as StringFn;
	const TextHighlightButton = useMemo(() => {
		/** @returns {JSX.Element} A JSX element for displaying the current highlight selection */
		const getHighlightColorDisplay = () => {
			return (
				<ColorPickerIndicatorWrapper
					color={highlightColor || undefined}
				>
					<GenemodIcon
						forceRerender
						name="highlighter"
						fill={highlightColor || undefined}
						size="medium"
					/>
				</ColorPickerIndicatorWrapper>
			);
		};

		return (
			<div className={styles.textHighlightButtonContainer}>
				<ColorPicker
					type="highlight"
					onClick={handleHighlight}
					display={getHighlightColorDisplay()}
					selectedColor={highlightColor || ""}
					includeNull
				/>
			</div>
		);
	}, [highlightColor, handleHighlight]);

	const buttons = () => {
		const btns: {
			group: (EditorButton | JSX.Element)[];
		}[] = [
			{
				group: [
					{
						icon: "format-bold",
						description: "Bold",
						onClick: () => editor.toggleMark("bold", true),
						active: marks.bold,
					},
					{
						icon: "format-italic",
						description: "Italic",
						onClick: () => editor.toggleMark("italic", true),
						active: marks.italic,
					},
					{
						icon: "link",
						description: "Insert link",
						onClick: () => openEditorLinkModal(),
					},
				],
			},
			{
				group: [TextColorButton, TextHighlightButton],
			},
			{
				group: [
					{
						icon: "comment",
						description: "Comment",
						onClick: () => {
							user && editor.addNotechain(user.id);
						},
					},
				],
			},
		];
		if (!Table.isInTable(editor)) {
			btns.unshift({
				group: [FontSizeButton],
			});
		}
		if (CHATGPT_FEATURE_ENABLED) {
			btns.unshift({
				group: [
					<ReviseHighlightedTextButton
						key="ReviseHighlightedTextButton"
						onSubButtonClick={() => setIsVisible(false)}
					/>,
				],
			});
		}
		return btns;
	};

	if (!isVisible) {
		return <></>;
	}

	return (
		<LayerSystemContainer
			wrapperRef={toolbar}
			className={styles.toolbar}
			contentEditable={false}
			onMouseDown={(e) => {
				// Prevents the editor from losing focus
				e.stopPropagation();
				e.preventDefault();
			}}
			overrideLayer={1}
		>
			{buttons().map((group, index) => {
				return (
					<div key={index} className={styles.btnGroup}>
						{group.group.map((btn, i) => {
							if (!(btn as EditorButton).icon) {
								return btn;
							}
							return (
								<ToolbarBtn
									key={i}
									icon={(btn as EditorButton).icon}
									description={
										(btn as EditorButton).description
									}
									onClick={(btn as EditorButton).onClick}
									active={(btn as EditorButton).active}
								/>
							);
						})}
					</div>
				);
			})}
		</LayerSystemContainer>
	);
};

export default HangingToolbar;

type ReviseHighlightedTextButtonProps = {
	onSubButtonClick: () => void;
};

const ReviseHighlightedTextButton = ({
	onSubButtonClick,
}: ReviseHighlightedTextButtonProps) => {
	return (
		<DropDown
			autoFocus={false}
			dropdownRender={() => {
				return (
					<LayerSystemContainer
						className={styles.reviseTextDropdown}
						overrideLayer={"background"}
					>
						<div className={styles.header}>
							<Typography color="text-primary" bold>
								Revise highlighted text
							</Typography>
							<GenemodIcon name="ai-icon" />
						</div>
						<LayerSystemContainer
							className={styles.buttons}
							overrideLayer={1}
						>
							<ReviseButton
								revisionType={RevisionType.REWRITE}
								onClick={onSubButtonClick}
							/>
							<ReviseButton
								revisionType={RevisionType.CASUAL}
								onClick={onSubButtonClick}
							/>
							<ReviseButton
								revisionType={RevisionType.FORMAL}
								onClick={onSubButtonClick}
							/>
							<ReviseButton
								revisionType={RevisionType.SHORTEN}
								onClick={onSubButtonClick}
							/>
							<ReviseButton
								revisionType={RevisionType.EXPAND}
								onClick={onSubButtonClick}
							/>
						</LayerSystemContainer>
					</LayerSystemContainer>
				);
			}}
		>
			<div>
				<AIButton className={styles.aiButtonContainer} />
			</div>
		</DropDown>
	);
};

export const useHangingBySelection = (
	ref: React.MutableRefObject<HTMLDivElement | null>,
	position = "top" as "top" | "bottom"
) => {
	const editor = useGenemodSlate();

	useEffect(() => {
		try {
			const el = ref.current;
			const { selection } = editor;

			if (!el) {
				return;
			}

			if (
				!selection ||
				Range.isCollapsed(selection) ||
				SlateEditor.string(editor, selection) === ""
			) {
				el.removeAttribute("style");
				return;
			}

			const selectedNodes = [
				...Editor.nodes(editor, { at: selection, mode: "lowest" }),
			];
			const node = selectedNodes?.[0]?.[0];
			if (!node) return;

			const selectedNode = ReactEditor.toDOMNode(editor, node);

			const rect = selectedNode.getBoundingClientRect();
			const editorRect =
				el.closest("#" + PM_EDITOR_ID)?.getBoundingClientRect() ||
				DEFAULT_RECT;

			if (position === "top") {
				el.style.top = `${rect.top - editorRect.top - 60}px`;
			} else {
				el.style.top = `${rect.bottom - editorRect.top + 10}px`;
			}

			el.style.left = `${
				rect.left +
				window.pageXOffset -
				el.offsetWidth / 2 +
				rect.width / 2
			}px`;
			el.style.position = "absolute";
			el.style.zIndex = "1";
			let left = rect.left - editorRect.left;
			if (left + el.clientWidth > editorRect.width) {
				left = editorRect.width - el.clientWidth;
			}
			el.style.left = `${left}px`;
		} catch (e) {
			console.warn(e);
		}
	});
};
