import { axios } from "@API";
import { useConfig } from "@common/config/GenemodConfig";
import { useHistory } from "@helpers/Hooks/UseRouterDom";
import { useOrganizationRouter } from "@root/AppRouter";
import { OFFICE_PATHS } from "@root/routes";
import React, { useEffect, useMemo, useState } from "react";

export enum OFFICE_FILE_TYPE {
	DOCX = "docx",
	DOC = "doc",
	XLSX = "xlsx",
	XLS = "xls",
	PPTX = "pptx",
	PPT = "ppt",
	WOPI_TEST = "wopitest",
}

export type EnvInput = {
	name: string;
	value: string;
};

export type Attachment = {
	url?: string;
	fileType: string;
	attachment: number;
	projectId: number;
	projectIsArchived: boolean;
};

const DEFAULT_BASE_URL =
	"https://FFC-onenote.officeapps.live.com/hosting/WopiTestFrame.aspx";
const DEFAULT_METHOD = "post";

const OFFICE_BASE_URL: { [key: string]: string } = {
	[OFFICE_FILE_TYPE.DOCX]:
		"https://FFC-word-edit.officeapps.live.com/we/wordeditorframe.aspx",
	[OFFICE_FILE_TYPE.XLSX]:
		"https://FFC-excel.officeapps.live.com/x/_layouts/xlviewerinternal.aspx?edit=1&",
	[OFFICE_FILE_TYPE.PPTX]:
		"https://FFC-powerpoint.officeapps.live.com/p/PowerPointFrame.aspx",
	[OFFICE_FILE_TYPE.WOPI_TEST]: DEFAULT_BASE_URL,
};

type OfficeContextProps = {
	attachment?: Attachment;
	// queryParams: EnvInput[];
	formInputs: EnvInput[];
	// setQueryParams: React.Dispatch<React.SetStateAction<EnvInput[]>>;
	setFormInputs: React.Dispatch<React.SetStateAction<EnvInput[]>>;
	finalUrl: string;
	baseUrl: string;
	setBaseUrl: React.Dispatch<React.SetStateAction<string>>;
	wopiSrc?: string;
	setWopiSrc: React.Dispatch<React.SetStateAction<string | undefined>>;
	method: string;
	setMethod: React.Dispatch<React.SetStateAction<string>>;
	onAttachmentClick: (attachment: Attachment) => void;
	onLoadOfficeOnline: () => void;
	resetAllParameters: () => void;
};

const OfficeContext = React.createContext<OfficeContextProps>({
	attachment: undefined,
	baseUrl: "",
	finalUrl: "",
	method: "",
	wopiSrc: "",
	formInputs: [],
	// queryParams: [],
	setBaseUrl: () => undefined,
	setFormInputs: () => undefined,
	setMethod: () => undefined,
	setWopiSrc: () => undefined,
	// setQueryParams: () => undefined,
	onAttachmentClick: () => undefined,
	onLoadOfficeOnline: () => undefined,
	resetAllParameters: () => undefined,
});

const getToken = () => {
	const token = axios.defaults.headers.common["Authorization"] as string;
	return token.split(" ")[1];
};

const getDefaultAccessTokenTtl = () => ({
	name: "access_token_ttl",
	value: `${new Date().setDate(new Date().getDate() + 10)}`,
});

export const OfficeProvider = (props: React.PropsWithChildren<object>) => {
	// const [queryParams, setQueryParams] = useState<EnvInput[]>([]);
	const [formInputs, setFormInputs] = useState<EnvInput[]>([]);
	const [wopiSrc, setWopiSrc] = useState<string>();
	const [baseUrl, setBaseUrl] = useState(DEFAULT_BASE_URL);
	const [method, setMethod] = useState(DEFAULT_METHOD);
	const [attachment, setAttachment] = useState<Attachment>();
	const token = getToken();
	const backendUrl = useConfig("REACT_APP_DJANGO_URL");
	const history = useHistory();
	const { appendBaseUrl } = useOrganizationRouter();

	const getWopiSrc = (attachment: number) => {
		return `${backendUrl}/wopi/files/${attachment}`;
	};

	const finalUrl = useMemo(() => {
		let newFinalUrl = baseUrl;
		if (wopiSrc?.length && !newFinalUrl.toLowerCase().includes("wopisrc")) {
			if (newFinalUrl[newFinalUrl.length - 1] !== "&") {
				newFinalUrl = `${newFinalUrl}?`;
			}
			newFinalUrl = `${newFinalUrl}wopisrc=${wopiSrc}`;
		}
		return newFinalUrl;
	}, [baseUrl, wopiSrc]);

	useEffect(() => {
		const fetchData = async () => {
			if (attachment) {
				try {
					const attachmentSrc = getWopiSrc(attachment.attachment);
					setWopiSrc(attachmentSrc);
				} catch (err) {
					console.error(err);
				}
			} else {
				setWopiSrc(undefined);
			}
		};
		fetchData();
	}, [attachment, backendUrl]);

	useEffect(() => {
		setFormInputs((prev) => {
			const newAccessToken = { name: "access_token", value: token };
			const oldAccessTokenIndex = prev.findIndex(
				(p) => p.name === "access_token"
			);
			if (oldAccessTokenIndex >= 0) {
				prev[oldAccessTokenIndex] = newAccessToken;
			} else {
				prev.push(newAccessToken);
			}
			const newAccessTokenTtl = getDefaultAccessTokenTtl();
			const oldAccessTokenTtlIndex = prev.findIndex(
				(p) => p.name === "access_token_ttl"
			);
			if (oldAccessTokenTtlIndex >= 0) {
				prev[oldAccessTokenTtlIndex] = newAccessTokenTtl;
			} else {
				prev.push(newAccessTokenTtl);
			}
			return [...prev];
		});
	}, [token]);

	const onAttachmentClick = (newAttachment: Attachment) => {
		if (attachment === newAttachment) {
			setWopiSrc(undefined);
			setAttachment(undefined);
			setBaseUrl(DEFAULT_BASE_URL);
		} else {
			const newBaseUrl = OFFICE_BASE_URL[newAttachment.fileType];
			if (newBaseUrl) {
				setAttachment(newAttachment);
				setBaseUrl(newBaseUrl);
			}
		}
	};

	const resetAllParameters = () => {
		setBaseUrl(DEFAULT_BASE_URL);
		setMethod(DEFAULT_METHOD);
		setAttachment(undefined);
		setWopiSrc(undefined);
		const newAccessToken = { name: "access_token", value: token };
		const newAccessTokenTtl = getDefaultAccessTokenTtl();
		setFormInputs([newAccessToken, newAccessTokenTtl]);
		// setQueryParams([]);
	};

	const onLoadOfficeOnline = () => {
		history.push(appendBaseUrl(OFFICE_PATHS.EDITOR));
	};

	return (
		<OfficeContext.Provider
			value={{
				attachment,
				baseUrl,
				finalUrl,
				formInputs,
				method,
				// queryParams,
				wopiSrc,
				setWopiSrc,
				setBaseUrl,
				setFormInputs,
				setMethod,
				// setQueryParams,
				onAttachmentClick,
				onLoadOfficeOnline,
				resetAllParameters,
			}}
		>
			{props.children}
		</OfficeContext.Provider>
	);
};

export function useOffice() {
	return React.useContext(OfficeContext);
}
