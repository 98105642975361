import React from "react";
import { Typography, GenemodIcon } from "@components";
import styles from "./BreadCrumb.module.scss";
import cn from "classnames";

type BreadCrumbProps = {
	/** BreadCrumb label */
	label: string;
	/** when label is clicked, it works only if state === idle */
	onClick?: () => void;
	/** BreadCrumb state */
	state?: "idle" | "active" | "disable";
	/** If true, hide `chevron-right` icon */
	isLastCrumb: boolean;
	/** Give it a maxWidth for the ellipsis to take effect or else it will be 150px by default */
	maxWidth?: number | string;
};

/**
 * BreadCrumb component for Navbar
 */
export function BreadCrumb({
	label,
	onClick,
	state = "idle",
	isLastCrumb,
	maxWidth = 150,
}: BreadCrumbProps): JSX.Element {
	return (
		<div
			className={cn(styles.breadcrumb, {
				[styles.breadcrumb__active]: state === "active",
				[styles.breadcrumb__disable]: state === "disable",
				[styles.breadcrumb__last]: isLastCrumb,
			})}
			onClick={onClick}
		>
			<Typography
				variant="body2"
				color="none"
				className={styles.crumbLabel}
				style={{
					maxWidth,
				}}
			>
				{label}
			</Typography>
			<GenemodIcon
				size="small"
				name="chevron-right"
				className={styles.chevronRight}
			/>
		</div>
	);
}
