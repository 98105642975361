import API from "@API";
import { ExperimentDocumentReadComment } from "@common/components/Editor/plugins/Notes/types";
import {
	GenemodGoogleDocId,
	GoogleDocCreateDto,
	GoogleDocCreateOptions,
	GoogleDocDto,
} from "@common/components/GoogleDocsIntegration/GoogleDrivePickerData";
import {
	DocumentImage,
	DocumentImageDto,
	DocumentImageUUID,
	Experiment,
	ExperimentAttachment,
	ExperimentAttachmentPatchData,
	ExperimentDocumentLink,
	ExperimentDocumentLinkPayload,
	ExperimentDocumentTableSearch,
	ExperimentId,
	ExperimentMaterial,
	ExperimentMaterialSearchPayload,
	ExperimentReportUpdatePayload,
	ExperimentStatus,
	ExperimentTable,
	ExperimentTablePayload,
	FolderMaterial,
	FolderMaterialSearchPayload,
	GenemodDocumentUUID,
	OrganizationId,
	OrganizationUserId,
	PMDocument,
	PageNumberPaginationResponse,
	ProjectColorEnum,
	ProjectId,
	ProjectProps,
	ProjectPropsDto,
	Protocol,
	ProtocolId,
	ProtocolLabel,
	ProtocolVersion,
	ProtocolVersionDto,
	ProtocolVersionId,
	ProtocolVersionSearchFilters,
	ProtocolVersionStatusEnum,
	SearchQuery,
	SerializedSharedObject,
	UUID,
	documentImageFromDto,
	getExperimentMaterialPayload,
	getFolderMaterialPayload,
	projectPropsDtoToProjectProps,
	projectPropsToProjectPropsDto,
	protocolVersionFromDto,
	OrganizationUser,
	SimpleShareableUser,
} from "@common/types";
import { ExperimentDocumentAttachment } from "@common/types/ExperimentDocumentAttachment";
import {
	ExperimentReport,
	ExperimentReportId,
	ExperimentReportListItem,
} from "@common/types/ExperimentReport";
import {
	ExperimentFile,
	ExperimentFileActivity,
	ExperimentFileActivityCreatePayload,
	ExperimentFileActivitySearchPayload,
	ExperimentFileList,
	ExperimentFileReviewStatus,
	ExperimentFileSearchPayload,
	ExperimentFileStatus,
	Folder,
	FolderId,
	FolderSearchPayload,
} from "@common/types/Folder";
import {
	ProjectSearchFilters,
	ProjectSearchResults,
	toProjectSearchResults,
} from "@common/types/PmSearchTypes";
import { PaginatedSearchResults, SortByOptions } from "@common/types/Search";
import { AtLeast, AtLeastId } from "@helpers/TypeHelpers";
import {
	createSingletonQuery,
	genemodBaseQuery,
} from "@redux/helpers/RtkQuery";
import { createApi, skipToken } from "@reduxjs/toolkit/query/react";
import { AxiosRequestConfig } from "axios";
import moment from "moment";
import { userApi } from "../user/UserApi";
import { PMDocumentDTO, pmDocumentToDto } from "./PmSliceHelpers";

export type ExperimentFileChangeStatusPayload = {
	fileId: number;
	status: ExperimentFileStatus;
	reviewers?: OrganizationUserId[];
	comment: string;
};

export type FileAttachmentSortBy = SortByOptions<
	"name" | "updated_by" | "updated_at"
>;
export type FileAttachmentSearchPayload = {
	id: number;
	params?: {
		search?: string;
		sort_by?: FileAttachmentSortBy;
		page?: number;
	};
};

export type ExperimentSearchSortBy = SortByOptions<
	"name" | "due_date" | "created_at"
>;

export type ExperimentSearchPayload = {
	parent_project: number;
	parent_subproject?: number | "null";
	search?: string;
	sort_by?: ExperimentSearchSortBy;
	status?: ExperimentStatus[];
	page?: number;
};

type ProjectSearchPayload = {
	projectId: number;
	filters: ProjectSearchFilters;
};

export type ProtocolVersionDeletePayload = {
	name: string;
	version_number: number;
};

export type ProtocolSearchSortBy = SortByOptions<
	"-total_usage" | "-last_used_at" | keyof Protocol
>;
export type ProtocolListParams = {
	search: string;
	page: string | number;
	page_size: number;
	labels: Array<string>;
	published: "true" | "false";
	sort_by: ProtocolSearchSortBy;
	last_created_at_start: string;
	last_created_at_end: string;
	last_created_by: Array<string>;
	include_filter_options?: boolean;
};

export type ProtocolVersionPatchData = {
	id: number;
	data: Partial<ProtocolVersion>;
};

export type CreateProtocolPayload = {
	name: string;
	labels: string[];
};

export type ProtocolPatchData = {
	id: number;
	data: Partial<{
		name: string;
		labels: string[];
		bookmarked_by?: OrganizationUserId[];
	}>;
};

type ProtocolLabelPatchData = {
	id: number;
	data: {
		name: string;
	};
};

const TAG_TYPES = [
	"Projects",
	"Experiment",
	"ExperimentMaterials",
	"FolderMaterials",
	"ExperimentDocuments",
	"ProtocolVersions",
	"Protocols",
	"DocumentImages",
	"ProtocolLabels",
	"ExperimentProtocolDocuments",
	"ExperimentAttachments",
	"Folders",
	"ExperimentFile",
	"ExperimentDocumentReadComments",
	"ExperimentReports",
	"ExperimentFileActivities",
	"ExperimentDocumentTables",
] as const;

type JoinSessionPayload = {
	env: string;
	token: string;
};

/**
 * Data sent to the backend for comparison against an existing protocol
 */
export type DocumentPrepublishRequest = {
	uuid: string;
	name: string;
	content: string;
};

/**
 * Get back the original information sent to prepublish with additional information
 * on existing protocols related to the document.
 */
export type DocumentPublishStatus = DocumentPrepublishRequest &
	(
		| {
				protocol_exists: false;
				changes_found: false;
				latest_version: 0;
				num_versions: 0;
		  }
		| {
				protocol_exists: true;
				changes_found: boolean;
				latest_version: number;
				num_versions: number;
		  }
	);

/**
 * Request data for publishing a document.
 */
export type PublishDocumentRequest = {
	uuid: string;
	name: string;
	content: string;
	/**
	 * Set this to publish a specific version number. If the version already exists,
	 * the request will fail. If this value is not provided, the version will be determined
	 * automatically.
	 */
	version?: number;
	// In some cases, we need control over the published protocol's status
	status?: ProtocolVersionStatusEnum;
};

export type ProjectKey = { id: number; key: string };

const sortExperimentDocByDate = (dto: Experiment): Experiment => {
	// The only transform we are doing is currently sorting the documents by the date the were created (oldest -> newest)
	return {
		...dto,
		documents: dto.documents.sort((a, b) =>
			moment(a.created_at).diff(moment(b.created_at))
		),
	};
};

export const projectManagementApi = createApi({
	reducerPath: "projectManagementApi",
	baseQuery: genemodBaseQuery({ baseUrl: API.pm.baseRoute }),
	tagTypes: TAG_TYPES,
	endpoints: (builder) => {
		const getProjectPatchMutation = (optimistic: boolean) =>
			builder.mutation<
				ProjectProps,
				AtLeastId<
					ProjectProps & {
						user_ids: OrganizationUserId[];
						color: ProjectColorEnum;
					}
				>
			>({
				query: (body) => ({
					url: `projects/${body.id}/`,
					method: "PATCH",
					body: projectPropsToProjectPropsDto(body),
				}),
				invalidatesTags: ["Projects", "Experiment", "ExperimentFile"],
				extraOptions: { maxRetries: 0 },
				transformResponse: (response: ProjectPropsDto) =>
					projectPropsDtoToProjectProps(response),
				onQueryStarted: optimistic
					? async (
							{ id, ...patch },
							{ dispatch, queryFulfilled }
					  ) => {
							const patchResult = dispatch(
								projectManagementApi.util.updateQueryData(
									"project",
									id,
									(draft) => {
										Object.assign(draft, patch);
									}
								)
							);
							try {
								await queryFulfilled;
							} catch {
								patchResult.undo();
							}
							dispatch(
								userApi.util.invalidateTags(["Preferences"])
							);
					  }
					: async (_, { dispatch, queryFulfilled }) => {
							await queryFulfilled;
							dispatch(
								userApi.util.invalidateTags(["Preferences"])
							);
					  },
			});

		const project = {
			projects: builder.query<
				ProjectProps[],
				{ is_archived?: boolean } | void
			>({
				query: (params) => ({
					url: `projects/`,
					params,
				}),
				providesTags: ["Projects"],
				transformResponse: (response: ProjectPropsDto[]) =>
					response.map(projectPropsDtoToProjectProps),
			}),
			projectKeys: builder.query<ProjectKey[], void>({
				query: (params) => ({
					url: `projects/project-keys/`,
					params,
				}),
				providesTags: ["Projects"],
			}),
			project: builder.query<ProjectProps, number>({
				query: (projectId) => `projects/${projectId}/`,
				providesTags: ["Projects"],
				extraOptions: {
					maxRetries: 0,
				},
				keepUnusedDataFor: 0,
				transformResponse: (response: ProjectPropsDto) =>
					projectPropsDtoToProjectProps(response),
			}),
			activeExperimentsCount: builder.query<number, void>({
				query: () => `projects/active-experiments-count/`,
				providesTags: ["Experiment"],
			}),
			projectCreate: builder.mutation<
				ProjectProps,
				{
					name: string;
					key: string;
					user_ids: number[];
					color: ProjectColorEnum;
					access: SerializedSharedObject["access"];
				}
			>({
				query: (body) => ({
					url: `projects/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["Projects"],
				extraOptions: { maxRetries: 0 },
				transformResponse: (response: ProjectPropsDto) =>
					projectPropsDtoToProjectProps(response),
			}),
			projectPatch: getProjectPatchMutation(false),
			projectPatchOptimistic: getProjectPatchMutation(true),
			projectDelete: builder.mutation<void, number>({
				query: (projectId) => ({
					url: `projects/${projectId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Projects"],
			}),
			projectManagerInvite: builder.mutation<
				ProjectProps,
				{ projectId: number; user_ids: number[] }
			>({
				query: ({ projectId, user_ids }) => ({
					url: `projects/${projectId}/users/`,
					method: "POST",
					body: { user_ids },
				}),
				invalidatesTags: ["Projects"],
				transformResponse: (response: ProjectPropsDto) =>
					projectPropsDtoToProjectProps(response),
			}),
			projectManagerUninvite: builder.mutation<
				void,
				{ projectId: number; user_id: number }
			>({
				query: ({ projectId, user_id }) => ({
					url: `projects/${projectId}/users/${user_id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Projects"],
			}),
			getProjectShareableUsers: builder.query<
				SimpleShareableUser[],
				ProjectId
			>({
				query: (projectId) => ({
					url: `projects/${projectId}/shareable-users/`,
					method: "GET",
				}),
				providesTags: ["Projects"],
			}),
			projectArchivedFiles: builder.query<ExperimentFile[], ProjectId>({
				query: (projectId) => ({
					url: `projects/${projectId}/archived-files/`,
					method: "GET",
				}),
				providesTags: ["ExperimentFile"],
			}),
			projectArchivedFolders: builder.query<Folder[], ProjectId>({
				query: (projectId) => ({
					url: `projects/${projectId}/archived-folders/`,
					method: "GET",
				}),
				providesTags: ["Folders"],
			}),
		} as const;
		const experiment = {
			experiment: builder.query<Experiment, number>({
				query: (experimendId) => `experiments/${experimendId}/`,
				providesTags: ["Experiment"],
				extraOptions: {
					maxRetries: 0,
				},
				transformResponse: sortExperimentDocByDate,
				keepUnusedDataFor: 0,
			}),
			experimentPatch: builder.mutation<
				Experiment,
				AtLeastId<Experiment>
			>({
				query: (body) => ({
					url: `experiments/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["Experiment"],
			}),
			experimentCreate: builder.mutation<
				Experiment,
				{
					parent_project: number;
					parent_subproject?: number;
					name: string;
					user_ids: number[];
				}
			>({
				query: (body) => ({
					url: `experiments/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["Experiment"],
			}),
			experimentDelete: builder.mutation<void, number>({
				query: (experimentId) => ({
					url: `experiments/${experimentId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Experiment"],
			}),
			// Invite users to a subproject
			experimentUserCreate: builder.mutation<
				void,
				{ experimentId: number; user_ids: number[] }
			>({
				query: (body) => ({
					url: `experiments/${body.experimentId}/users/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["Experiment"],
				extraOptions: { maxRetries: 0 },
			}),
			experimentUserDelete: builder.mutation<
				void,
				{ experimentId: number; userId: number }
			>({
				query: ({ experimentId, userId }) => ({
					url: `experiments/${experimentId}/users/${userId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Experiment"],
				extraOptions: { maxRetries: 0 },
			}),
			experimentDocumentSync: builder.mutation<
				Experiment,
				number | ExperimentId
			>({
				query: (experimentId) => ({
					url: `experiments/${experimentId}/sync-documents/`,
					method: "POST",
				}),
			}),
			pmDocumentSync: builder.mutation<PMDocumentDTO, UUID>({
				query: (pmDocumentUuid) => ({
					url: `experiment-documents/${pmDocumentUuid}/sync-document/`,
					method: "POST",
				}),
			}),
			pmDocumentAttachment: builder.mutation<
				ExperimentDocumentAttachment,
				{
					documentUUID: string;
					data: FormData;
				}
			>({
				query: ({ documentUUID, data }) => ({
					url: `experiment-documents/${documentUUID}/attachments/`,
					method: "POST",
					body: data,
				}),
				extraOptions: {
					maxRetries: 0,
				},
			}),
			getPmDocumentAttachment: builder.query<
				ExperimentDocumentAttachment,
				number
			>({
				query: (attachmentId) => ({
					url: `experiment-document-attachment/${attachmentId}/`,
				}),
				extraOptions: {
					maxRetries: 0,
				},
			}),

			suggestedExperiments: builder.query<Experiment[], void>({
				query: () => `experiments/list-suggested-experiments/`,
				providesTags: ["Experiment"],
				keepUnusedDataFor: 0,
			}),
			updateViewExperiment: builder.query<void, ExperimentId>({
				query: (experimentId) =>
					`experiments/${experimentId}/update-view-experiment/`,
				extraOptions: {
					maxRetries: 0,
				},
				keepUnusedDataFor: 0,
			}),
		} as const;

		// ~~~~~~~~~~~~~~~~ PM Search ~~~~~~~~~~~~~~~~

		/**
		 * "attachments" was changed to "files" on the front end. Updating it here.
		 */
		const filterTypeToQueryType = (
			filters: ProjectSearchFilters
		): ProjectSearchFilters => {
			if (filters.filterType === "files") {
				return {
					...filters,
					filterType: "attachments",
				} as unknown as ProjectSearchFilters;
			}
			return filters;
		};

		const pmSearch = {
			projectSearch: builder.query<
				ProjectSearchResults,
				ProjectSearchPayload
			>({
				query: ({ projectId, filters }) => ({
					url: `projects/${projectId}/search/`,
					params: filterTypeToQueryType(filters),
				}),
				transformResponse: toProjectSearchResults,
			}),
			experimentSearch: builder.query<
				PaginatedSearchResults<Experiment>,
				ExperimentSearchPayload
			>({
				query: (params) => ({
					url: `experiments/`,
					params,
				}),
				providesTags: ["Experiment"],
			}),
		} as const;
		// ~~~~~~~~~~~~~~~~ /PM Search ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Experiment Attachments ~~~~~~~~~~~~~~~~

		const experimentAttachments = {
			experimentAttachments: builder.query<
				ExperimentAttachment[],
				number
			>({
				query: (experimentId) =>
					`experiments/${experimentId}/attachment-upload/`,
				providesTags: ["ExperimentAttachments"],
			}),
			experimentAttachmentAdd: builder.mutation<
				ExperimentAttachment,
				{
					experimentId: number;
					data: FormData;
					config?: AxiosRequestConfig;
				}
			>({
				query: ({ experimentId, data, config }) => ({
					url: `experiments/${experimentId}/attachment-upload/`,
					method: "POST",
					body: data,
					config,
				}),
				invalidatesTags: ["ExperimentAttachments"],
			}),
			experimentAttachmentsDelete: builder.mutation<
				void,
				{ experimentId: number; attachmentIds: number[] }
			>({
				query: ({ experimentId, attachmentIds }) => ({
					url: `experiments/${experimentId}/attachment-upload/bulk/`,
					method: "DELETE",
					body: attachmentIds,
				}),
				invalidatesTags: ["ExperimentAttachments"],
			}),
			experimentAttachmentPatch: builder.mutation<
				ExperimentAttachment,
				{
					experimentAttachmentId: number;
					attachmentPatchData: ExperimentAttachmentPatchData;
				}
			>({
				query: ({ experimentAttachmentId, attachmentPatchData }) => ({
					url: `experiment-attachments/${experimentAttachmentId}/`,
					method: "PATCH",
					body: attachmentPatchData,
				}),
				invalidatesTags: ["ExperimentAttachments"],
			}),
		};
		// ~~~~~~~~~~~~~~~~ / Experiment Attachments ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Experiment Tables ~~~~~~~~~~~~~~~~
		const experimentTables = {
			experimentDocumentTables: builder.query<
				ExperimentTable[],
				ExperimentDocumentTableSearch
			>({
				query: (params) => ({
					url: "experiment-document-table/",
					params,
				}),
				providesTags: ["ExperimentDocumentTables"],
			}),
			experimentDocumentTable: builder.query<
				ExperimentTable,
				GenemodDocumentUUID
			>({
				query: (table_document_id) => ({
					url: `experiment-document-table/${table_document_id}/`,
				}),
				providesTags: ["ExperimentDocumentTables"],
			}),
			experimentTableAdd: builder.mutation<
				ExperimentTable,
				ExperimentTablePayload
			>({
				query: (data) => ({
					url: "experiment-document-table/",
					method: "POST",
					body: data,
				}),
				invalidatesTags: ["ExperimentDocumentTables"],
			}),
			experimentTablePatch: builder.mutation<
				ExperimentTable,
				ExperimentTablePayload
			>({
				query: (data) => ({
					url: `experiment-document-table/${data.table_id}-${data.document}/`,
					method: "PATCH",
					body: data,
				}),
			}),
			experimentTableDelete: builder.mutation<ExperimentTable, string>({
				query: (table_document_id) => ({
					url: `experiment-document-table/${table_document_id}/`,
					method: "DELETE",
				}),
			}),
		};

		// ~~~~~~~~~~~~~~~~ / Experiment Tables ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Experiment Tables ~~~~~~~~~~~~~~~~
		const experimentLinks = {
			experimentLinkAdd: builder.mutation<
				ExperimentDocumentLink,
				ExperimentDocumentLinkPayload
			>({
				query: (data) => ({
					url: "experiment-document-link/",
					method: "POST",
					body: data,
				}),
			}),
			experimentLinkDelete: builder.mutation<
				ExperimentDocumentLink,
				string
			>({
				query: (experiment_link_id) => ({
					url: `experiment-document-link/${experiment_link_id}/`,
					method: "DELETE",
				}),
			}),
		};

		// ~~~~~~~~~~~~~~~~ / Experiment Tables ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Experiment documents ~~~~~~~~~~~~~~~~
		type PmDocWithUuid = AtLeast<PMDocument, "uuid">;
		const experimentDocumentUrl = (uuid: GenemodDocumentUUID) =>
			`experiment-documents/${uuid}/`;
		const experimentDocument = {
			documentGet: builder.query<PMDocumentDTO, GenemodDocumentUUID>({
				query: (uuid) => experimentDocumentUrl(uuid),
				keepUnusedDataFor: 0,
				extraOptions: {
					maxRetries: 0,
				},
				providesTags: ["ExperimentProtocolDocuments"],
			}),
			extraDocumentCreate: builder.mutation<
				PMDocumentDTO,
				{ experimentId: number; document: AtLeast<PMDocument, "name"> }
			>({
				query: ({ experimentId, document }) => ({
					url: `experiments/${experimentId}/extra-documents/`,
					method: "POST",
					body: document,
				}),
				invalidatesTags: ["Experiment"],
			}),
			protocolDocumentCreate: builder.mutation<
				PMDocumentDTO,
				{ experimentId: number }
			>({
				query: ({ experimentId }) => ({
					url: `experiments/${experimentId}/protocol-documents/`,
					method: "POST",
				}),
				invalidatesTags: ["ExperimentProtocolDocuments", "Experiment"],
			}),
			documentPatch: builder.mutation<PMDocumentDTO, PmDocWithUuid>({
				query: (document) => ({
					url: experimentDocumentUrl(document.uuid),
					method: "PATCH",
					body: pmDocumentToDto(document),
				}),
				invalidatesTags: ["ExperimentProtocolDocuments"],
			}),
			documentDelete: builder.mutation<void, PmDocWithUuid>({
				query: (document) => ({
					url: experimentDocumentUrl(document.uuid),
					method: "DELETE",
				}),
				invalidatesTags: ["ExperimentProtocolDocuments", "Experiment"],
			}),
			joinSession: builder.query<JoinSessionPayload, PMDocument["uuid"]>({
				query: (documentId) =>
					`experiment-documents/${documentId}/join-session/`,
			}),
			documentImage: builder.query<DocumentImage, DocumentImageUUID>({
				query: (uuid) => `experiment-uploads/${uuid}/`,
				providesTags: ["DocumentImages"],
				transformResponse: (response: DocumentImageDto) =>
					documentImageFromDto(response),
			}),
			documentPrepublish: builder.mutation<
				DocumentPublishStatus[],
				DocumentPrepublishRequest[]
			>({
				query: (body) => ({
					url: `experiment-documents/pre-publish/`,
					method: "POST",
					body,
				}),
			}),
			documentPublish: builder.mutation<
				ProtocolVersion[],
				PublishDocumentRequest[]
			>({
				query: (body) => ({
					url: "experiment-documents/bulk-publish/",
					body,
					method: "POST",
				}),
				extraOptions: {
					maxRetries: 0,
				},
			}),
		} as const;
		// ~~~~~~~~~~~~~~~~ /Experiment documents ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Experiment material ~~~~~~~~~~~~~~~~
		const experimentMaterial = {
			experimentMaterials: builder.query<
				PageNumberPaginationResponse<ExperimentMaterial>,
				ExperimentMaterialSearchPayload
			>({
				query: (params) => ({
					url: `/experiment-file-materials/`,
					params,
				}),
				providesTags: ["ExperimentMaterials"],
			}),
			experimentMaterialCreate: builder.mutation<
				ExperimentMaterial,
				AtLeast<ExperimentMaterial, "name" | "experiment_file">
			>({
				query: (material) => ({
					url: `/experiment-file-materials/`,
					body: getExperimentMaterialPayload(material),
					method: "POST",
				}),
				invalidatesTags: ["ExperimentMaterials"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			experimentMaterialPatch: builder.mutation<
				ExperimentMaterial,
				AtLeastId<ExperimentMaterial>
			>({
				query: (material) => ({
					url: `/experiment-file-materials/${material.id}/`,
					method: "PATCH",
					body: getExperimentMaterialPayload(material),
				}),
				invalidatesTags: ["ExperimentMaterials"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			experimentMaterialDelete: builder.mutation<void, number>({
				query: (materialId) => ({
					url: `/experiment-file-materials/${materialId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["ExperimentMaterials"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			exportExperimentMaterials: builder.query<
				string,
				ExperimentMaterialSearchPayload
			>({
				query: (params) => ({
					url: "experiment-file-materials/export/",
					params,
					responseHandler: (response: any) => response.text(),
				}),
			}),
		} as const;
		// ~~~~~~~~~~~~~~~~ /Experiment material ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Folder material ~~~~~~~~~~~~~~~~
		const folderMaterial = {
			folderMaterials: builder.query<
				PageNumberPaginationResponse<FolderMaterial>,
				FolderMaterialSearchPayload
			>({
				query: (params) => ({
					url: `/folder-materials/`,
					params,
				}),
				providesTags: ["FolderMaterials"],
			}),
			folderMaterialCreate: builder.mutation<
				FolderMaterial,
				AtLeast<FolderMaterial, "name" | "folder">
			>({
				query: (material) => ({
					url: `/folder-materials/`,
					body: getFolderMaterialPayload(material),
					method: "POST",
				}),
				invalidatesTags: ["FolderMaterials"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			folderMaterialPatch: builder.mutation<
				FolderMaterial,
				AtLeastId<FolderMaterial>
			>({
				query: (material) => ({
					url: `/folder-materials/${material.id}/`,
					method: "PATCH",
					body: getFolderMaterialPayload(material),
				}),
				invalidatesTags: ["FolderMaterials"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			folderMaterialDelete: builder.mutation<void, number>({
				query: (materialId) => ({
					url: `/folder-materials/${materialId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["FolderMaterials"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			exportFolderAndChildrenMaterials: builder.query<string, number>({
				query: (folderId) => ({
					url: `folder-materials/export-folder/${folderId}/`,
					responseHandler: (response: any) => response.text(),
				}),
				extraOptions: {
					maxRetries: 0,
				},
			}),
			exportFolderMaterials: builder.query<
				string,
				FolderMaterialSearchPayload
			>({
				query: (params) => ({
					url: "folder-materials/export/",
					params,
					responseHandler: (response: any) => response.text(),
				}),
				extraOptions: {
					maxRetries: 0,
				},
			}),
			listFolderMaterials: builder.query<
				(ExperimentMaterial & FolderMaterial)[],
				number
			>({
				query: (folderId) => ({
					url: `/folder-materials/list-materials/${folderId}/`,
				}),
				extraOptions: {
					maxRetries: 0,
				},
			}),
		} as const;
		// ~~~~~~~~~~~~~~~~ /Experiment material ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Google Docs ~~~~~~~~~~~~~~~~
		const googleDocsUrl = "google-docs";
		const getGoogleDocsUrl = (payload: GoogleDocCreateOptions) => {
			if (payload.parentType === "Subproject") {
				return `subprojects/${payload.subprojectId}/${googleDocsUrl}/`;
			} else if (payload.parentType === "Experiment") {
				return `experiments/${payload.experimentId}/${googleDocsUrl}/`;
			} else if (payload.parentType === "Project") {
				return `projects/${payload.projectId}/${googleDocsUrl}/`;
			} else {
				return `items/${payload.itemId}/${googleDocsUrl}/`;
			}
		};
		const googleDocs = {
			googleDocCreate: builder.mutation<
				GoogleDocDto,
				{ dto: GoogleDocCreateDto } & GoogleDocCreateOptions
			>({
				query: (payload) => ({
					url: getGoogleDocsUrl(payload),
					method: "POST",
					body: payload.dto,
				}),
				invalidatesTags: ["ExperimentDocuments"],
			}),
			googleDocDelete: builder.mutation<void, GenemodGoogleDocId>({
				query: (docId) => ({
					url: `${googleDocsUrl}/${docId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["ExperimentDocuments"],
			}),
			experimentGoogleDocs: builder.query<
				GoogleDocDto[],
				{ experimentId: number }
			>({
				query: ({ experimentId }) =>
					`experiments/${experimentId}/google-docs/`,

				providesTags: () => [
					{
						type: "ExperimentDocuments",
						id: "LIST",
					},
				],
			}),
		};

		// ~~~~~~~~~~~~~~~~ /Google Docs ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Protocol ~~~~~~~~~~~~~~~~
		const pmProtocol = {
			protocol: builder.query<Protocol, ProtocolId>({
				query: (protocol) => `protocols/${protocol}/`,
				providesTags: ["Protocols"],
				extraOptions: { maxRetries: 0 },
			}),
			protocolPatch: builder.mutation<Protocol, ProtocolPatchData>({
				query: ({ id, data }) => ({
					url: `protocols/${id}/`,
					method: "PATCH",
					body: data,
				}),
				async onQueryStarted(
					{ id, data },
					{ dispatch, queryFulfilled }
				) {
					dispatch(
						projectManagementApi.util.updateQueryData(
							"protocol",
							id as ProtocolId,
							(draft) => {
								if (!data.labels) return;

								const state = draft as Protocol;
								const labels = state.labels;
								if (data.labels.length > labels.length) {
									labels.push({
										id: -1,
										name: data.labels[
											data.labels.length - 1
										],
									});
								} else {
									labels.filter((i) =>
										data.labels?.includes(i.name)
									);
								}
							}
						)
					);
				},
				invalidatesTags: [
					"Protocols",
					"ProtocolVersions",
					"ProtocolLabels",
				],
				extraOptions: { maxRetries: 0 },
			}),
			/**
			 * List protocols within the user's organization.
			 * Supports search, filtering, and ordering.
			 */
			protocols: builder.query<
				PaginatedSearchResults<Protocol>,
				Partial<ProtocolListParams> | void
			>({
				query: (params) => ({
					url: `protocols/`,
					method: "GET",
					params: { ...params, include_filter_options: false },
				}),
				providesTags: ["Protocols"],
			}),
			createProtocolVersion: builder.mutation<
				ProtocolVersion,
				ProtocolId
			>({
				query: (protocolId) => ({
					url: `protocols/${protocolId}/create-new-version/`,
					method: "POST",
				}),
				transformResponse: (dto: ProtocolVersionDto) =>
					protocolVersionFromDto(dto),
				invalidatesTags: ["Protocols", "ProtocolVersions"],
			}),
			protocolVersions: builder.query<
				ProtocolVersion[],
				ProtocolVersionSearchFilters
			>({
				query: (filters) => ({
					url: `protocol-versions/`,
					params: filters,
				}),
				transformResponse: (dtos: ProtocolVersionDto[]) =>
					dtos.map(protocolVersionFromDto),
				providesTags: ["ProtocolVersions"],
				keepUnusedDataFor: 0,
			}),
			protocolVersion: builder.query<ProtocolVersion, ProtocolVersionId>({
				query: (protocolVersion) => ({
					url: `protocol-versions/${protocolVersion}/`,
					method: "GET",
				}),
				transformResponse: (dto: ProtocolVersionDto) =>
					protocolVersionFromDto(dto),
				providesTags: ["ProtocolVersions"],
			}),
			protocolVersionBulkDelete: builder.mutation<
				void,
				ProtocolVersionId[]
			>({
				query: (ids) => ({
					url: `/protocol-versions/bulk/`,
					method: "DELETE",
					body: ids,
				}),
				invalidatesTags: ["ProtocolVersions", "Protocols"],
			}),
			protocolDelete: builder.mutation<void, ProtocolId>({
				query: (protocolId) => ({
					url: `/protocols/${protocolId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Protocols", "ProtocolVersions"],
			}),
			protocolVersionDelete: builder.mutation<void, ProtocolVersionId>({
				query: (protocolVersionId) => ({
					url: `/protocol-versions/${protocolVersionId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["ProtocolVersions", "Protocols"],
			}),
			createNewProtocolVersion: builder.mutation<
				ProtocolVersion,
				ProtocolVersionId
			>({
				query: (protocolVersionId) => ({
					url: `/protocol-versions/${protocolVersionId}/create/`,
					method: "POST",
				}),
				transformResponse: (dto: ProtocolVersionDto) =>
					protocolVersionFromDto(dto),
				invalidatesTags: ["Protocols", "ProtocolVersions"],
			}),
			useProtocolVersion: builder.mutation<
				ProtocolVersion,
				ProtocolVersionId
			>({
				query: (protocolVersionId: number) => ({
					url: `/protocol-versions/${protocolVersionId}/use/`,
					method: "POST",
				}),
				transformResponse: (dto: ProtocolVersionDto) =>
					protocolVersionFromDto(dto),
				invalidatesTags: ["Protocols", "ProtocolVersions"],
			}),
			protocolVersionPatch: builder.mutation<
				ProtocolVersion,
				ProtocolVersionPatchData
			>({
				query: ({ id, data }) => ({
					url: `/protocol-versions/${id}/`,
					method: "PATCH",
					body: data,
				}),
				transformResponse: (dto: ProtocolVersionDto) =>
					protocolVersionFromDto(dto),
				invalidatesTags: ["Protocols", "ProtocolVersions"],
			}),
			protocolLabels: builder.query<ProtocolLabel[], void>({
				query: () => ({
					url: `/protocol-labels/`,
					method: "GET",
				}),
				providesTags: ["ProtocolLabels"],
			}),
			createProtocolLabel: builder.mutation<ProtocolLabel, string>({
				query: (name) => ({
					url: `/protocol-labels/`,
					method: "POST",
					body: {
						name,
					},
				}),
				invalidatesTags: ["ProtocolLabels"],
			}),
			patchProtocolLabel: builder.mutation<void, ProtocolLabelPatchData>({
				query: ({ id, data }) => ({
					url: `/protocol-labels/${id}/`,
					method: "PATCH",
					body: data,
				}),
				invalidatesTags: ["ProtocolLabels", "Protocols"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			deleteProtocolLabel: builder.mutation<void, number>({
				query: (id) => ({
					url: `/protocol-labels/${id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["ProtocolLabels", "Protocols"],
			}),
			createProtocol: builder.mutation<Protocol, CreateProtocolPayload>({
				query: ({ name, labels }) => ({
					url: `/protocols/`,
					method: "POST",
					body: {
						name,
						labels,
					},
				}),
				invalidatesTags: [
					"ProtocolLabels",
					"Protocols",
					// Need to refetch "unpublished drafts" section
					"ProtocolVersions",
				],
			}),
		};
		// ~~~~~~~~~~~~~~~~ Protocol ~~~~~~~~~~~~~~~~

		//~~~~~~~~~~~~~~~~ folder and Experiment Files ~~~~~~~~~~~~~~~~
		const folder = {
			searchProjectFolders: builder.query<
				PageNumberPaginationResponse<Folder>,
				FolderSearchPayload | void
			>({
				query: (params) => ({
					url: "folders/",
					method: "GET",
					params,
				}),
			}),
			getProjectFolders: builder.query<
				Folder[],
				{ projectId: ProjectId; archived?: boolean }
			>({
				query: ({ projectId, ...params }) => ({
					url: `projects/${projectId}/folders/`,
					params,
					method: "GET",
				}),
				providesTags: ["Folders"],
			}),
			getProjectFolder: builder.query<Folder, FolderId>({
				query: (folderId) => ({
					url: `folders/${folderId}/`,
					method: "GET",
				}),
				providesTags: ["Folders"],
			}),
			createProjectFolder: builder.mutation<
				Folder,
				{
					projectId: ProjectId;
					body: { name: string; parent_folder?: number };
				}
			>({
				query: ({ projectId, body }) => ({
					url: `projects/${projectId}/folders/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["Folders"],
			}),
			patchProjectFolder: builder.mutation<Folder, Partial<Folder>>({
				query: (body) => ({
					url: `folders/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["Folders"],
			}),
			deleteProjectFolder: builder.mutation<void, FolderId>({
				query: (folderId) => ({
					url: `folders/${folderId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Folders", "ExperimentFile"],
			}),
			listExperimentFiles: builder.query<
				ExperimentFileList[],
				{
					projectId: number;
					archived?: boolean;
				}
			>({
				query: ({ projectId, ...params }) => ({
					url: `experiment-files-lite/?parent_project=${projectId}`,
					params,
					method: "GET",
				}),
				providesTags: ["ExperimentFile"],
			}),
			searchExperimentFiles: builder.query<
				PageNumberPaginationResponse<ExperimentFile>,
				ExperimentFileSearchPayload | void
			>({
				query: (params) => ({
					url: "experiment-files/",
					method: "GET",
					params,
				}),
				providesTags: ["ExperimentFile"],
			}),
			getExperimentFile: builder.query<ExperimentFile, ExperimentId>({
				query: (experimentId) => ({
					url: `experiment-files/${experimentId}/`,
					method: "GET",
				}),
				extraOptions: { maxRetries: 0 },
				providesTags: ["ExperimentFile"],
			}),
			createExperimentFile: builder.mutation<
				ExperimentFile,
				{ name: string; parent_project: number; parent_folder?: number }
			>({
				query: (body) => ({
					url: "experiment-files/",
					method: "POST",
					body,
				}),
				invalidatesTags: ["ExperimentFile"],
			}),
			patchExperimentFile: builder.mutation<
				ExperimentFile,
				Partial<ExperimentFile>
			>({
				query: (body) => ({
					url: `experiment-files/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["ExperimentFile"],
			}),
			deleteExperimentFile: builder.mutation<void, ExperimentId>({
				query: (experimentId) => ({
					url: `experiment-files/${experimentId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["ExperimentFile"],
			}),
			updateViewExperimentFile: builder.query<void, ExperimentId>({
				query: (experimentId) =>
					`experiment-files/${experimentId}/update-view-experiment-file/`,
				extraOptions: {
					maxRetries: 0,
				},
				keepUnusedDataFor: 0,
			}),
			getRecentlyOpenedExperimentFiles: builder.query<
				ExperimentFile[],
				void
			>({
				query: () => "experiment-files/recently-opened/",
				providesTags: ["ExperimentFile"],
			}),
			getExperimentFileActivities: builder.query<
				ExperimentFileActivity[],
				ExperimentFileActivitySearchPayload
			>({
				query: (params) => ({
					url: `experiment-file-activities/`,
					method: "GET",
					params,
				}),
				providesTags: ["ExperimentFileActivities"],
			}),
			createExperimentActivity: builder.mutation<
				ExperimentFileActivity,
				ExperimentFileActivityCreatePayload
			>({
				query: (body) => ({
					url: `experiment-file-activities/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["ExperimentFileActivities"],
			}),
			addFolderCollaborator: builder.mutation<
				void,
				{
					folderId: FolderId;
					collaborator_ids: OrganizationUserId[];
				}
			>({
				query: ({ folderId, ...body }) => ({
					url: `folders/${folderId}/add-collaborator/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["Folders"],
			}),
			archiveFolder: builder.mutation<void, FolderId>({
				query: (folderId) => ({
					url: `folders/${folderId}/archive-folder/`,
					method: "POST",
				}),
				invalidatesTags: ["Folders", "ExperimentFile"],
			}),
			unarchiveFolder: builder.mutation<void, FolderId>({
				query: (folderId) => ({
					url: `folders/${folderId}/unarchive-folder/`,
					method: "POST",
				}),
				invalidatesTags: ["ExperimentFile", "Folders"],
			}),
			changeFileStatus: builder.mutation<
				void,
				ExperimentFileChangeStatusPayload
			>({
				query: ({ fileId, ...body }) => ({
					url: `experiment-files/${fileId}/change-status/`,
					body,
					method: "POST",
				}),
				invalidatesTags: ["ExperimentFile", "ExperimentFileActivities"],
			}),
			changeFileReviewStatus: builder.mutation<
				void,
				{
					fileId: number;
					review_status: ExperimentFileReviewStatus;
					comment: string;
				}
			>({
				query: ({ fileId, ...body }) => ({
					url: `experiment-files/${fileId}/change-review-status/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["ExperimentFile", "ExperimentFileActivities"],
			}),
			withdrawFile: builder.mutation<
				string,
				{
					fileId: number;
					status: ExperimentFileStatus;
					reviewers?: OrganizationUserId[];
					comment: string;
				}
			>({
				query: ({ fileId, ...body }) => ({
					url: `experiment-files/${fileId}/withdraw/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["ExperimentFile", "ExperimentFileActivities"],
			}),
		};

		const experimentComments = {
			getExperimentDocumentReadComments: builder.query<
				ExperimentDocumentReadComment[],
				GenemodDocumentUUID
			>({
				query: (document) => ({
					url: `experiment-document-read-comments/`,
					method: "GET",
					params: { document },
				}),
				providesTags: ["ExperimentDocumentReadComments"],
			}),
			createExperimentDocumentReadComments: builder.mutation<
				ExperimentDocumentReadComment,
				Omit<ExperimentDocumentReadComment, "id" | "user">
			>({
				query: (body) => ({
					url: `experiment-document-read-comments/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["ExperimentDocumentReadComments"],
			}),
		};

		const reports = {
			experimentReports: builder.query<
				ExperimentReportListItem[],
				SearchQuery
			>({
				query: (params) => ({
					url: `experiment-report/`,
					method: "GET",
					params,
				}),
				providesTags: ["ExperimentReports"],
			}),
			experimentReport: builder.query<
				ExperimentReport,
				ExperimentReportId
			>({
				query: (id) => ({
					url: `experiment-report/${id}/`,
					method: "GET",
				}),
			}),
			createExperimentReport: builder.mutation<
				ExperimentReport,
				FormData
				// ExperimentReportCreatePayload
			>({
				query: (body) => ({
					url: `experiment-report/`,
					method: "POST",
					body,
					formData: true,
				}),
				invalidatesTags: ["ExperimentReports"],
			}),
			patchExperimentReport: builder.mutation<
				ExperimentReport,
				ExperimentReportUpdatePayload
			>({
				query: (body) => ({
					url: `experiment-report/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["ExperimentReports"],
				async onQueryStarted(
					{ id, name },
					{ dispatch, queryFulfilled }
				) {
					const patchResult = dispatch(
						projectManagementApi.util.updateQueryData(
							"experimentReports",
							{},
							(draft) => {
								const reportIndex = draft.findIndex(
									(report) => report.id === id
								);
								if (reportIndex !== -1) {
									draft[reportIndex].name = name;
								}
							}
						)
					);
					queryFulfilled.catch(patchResult.undo);
				},
			}),
			deleteExperimentReport: builder.mutation<void, ExperimentReportId>({
				query: (id) => ({
					url: `experiment-report/${id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["ExperimentReports"],
				async onQueryStarted(id, { dispatch }) {
					dispatch(
						projectManagementApi.util.updateQueryData(
							"experimentReports",
							{},
							(draft) => {
								const reportIndex = draft.findIndex(
									(report) => report.id === id
								);
								if (reportIndex !== -1) {
									draft.splice(reportIndex, 1);
								}
							}
						)
					);
				},
			}),
		};

		const dashboard = {
			projectsOverview: builder.query<
				{
					num_active_projects: number;
					num_active_protocols: number;
				},
				OrganizationId
			>({
				query: () => ({
					url: `projects-overview/`,
					method: "GET",
				}),
				providesTags: ["Projects", "Experiment"],
			}),
		};

		return {
			...project,
			...experiment,
			...experimentMaterial,
			...folderMaterial,
			...pmSearch,
			...experimentDocument,
			...googleDocs,
			...googleDocs,
			...googleDocs,
			...pmProtocol,
			...experimentAttachments,
			...experimentLinks,
			...experimentTables,
			...folder,
			...experimentComments,
			...googleDocs,
			...reports,
			...dashboard,
		};
	},
});

export const useProjectSearchSingletonQuery = createSingletonQuery<
	ProjectSearchPayload,
	ProjectSearchResults
>(projectManagementApi.endpoints.projectSearch);

export const {
	useProjectsQuery,
	useProjectKeysQuery,
	useLazyProjectQuery,
	useProjectQuery,
	useActiveExperimentsCountQuery,
	useProjectCreateMutation,
	useProjectPatchMutation,
	useProjectPatchOptimisticMutation,
	useProjectDeleteMutation,
	useProjectManagerInviteMutation,
	useProjectManagerUninviteMutation,
	useExperimentSearchQuery,
	useExperimentQuery,
	useLazyExperimentQuery,
	useExperimentPatchMutation,
	useExperimentCreateMutation,
	useExperimentDeleteMutation,
	useExperimentMaterialsQuery,
	useExperimentUserCreateMutation,
	useExperimentUserDeleteMutation,
	useSuggestedExperimentsQuery,
	useExperimentAttachmentAddMutation,
	useExperimentAttachmentPatchMutation,
	useExperimentAttachmentsDeleteMutation,
	useExperimentLinkAddMutation,
	useExperimentLinkDeleteMutation,
	useExperimentDocumentTablesQuery,
	useExperimentDocumentTableQuery,
	useExperimentTableAddMutation,
	useExperimentTablePatchMutation,
	useExperimentTableDeleteMutation,
	useExperimentMaterialCreateMutation,
	useExperimentMaterialPatchMutation,
	useExperimentMaterialDeleteMutation,
	useFolderMaterialCreateMutation,
	useFolderMaterialsQuery,
	useFolderMaterialDeleteMutation,
	useFolderMaterialPatchMutation,
	useLazyExportFolderMaterialsQuery,
	useLazyExportFolderAndChildrenMaterialsQuery,
	useLazyListFolderMaterialsQuery,
	useUpdateViewExperimentQuery,
	useDocumentGetQuery,
	useLazyDocumentGetQuery,
	useLazyGetPmDocumentAttachmentQuery,
	useExtraDocumentCreateMutation,
	useProtocolDocumentCreateMutation,
	useDocumentPatchMutation,
	useDocumentDeleteMutation,
	useGoogleDocCreateMutation,
	useGoogleDocDeleteMutation,
	useExperimentGoogleDocsQuery,
	useProtocolVersionQuery,
	useLazyProtocolVersionQuery,
	useProtocolVersionsQuery,
	useLazyProtocolVersionsQuery,
	useProtocolDeleteMutation,
	useProtocolVersionBulkDeleteMutation,
	useProtocolQuery,
	useLazyProtocolQuery,
	useProtocolsQuery,
	useLazyProtocolsQuery,
	useProtocolVersionDeleteMutation,
	useProtocolPatchMutation,
	useJoinSessionQuery,
	useLazyJoinSessionQuery,
	useDocumentImageQuery,
	useLazyDocumentImageQuery,
	useCreateNewProtocolVersionMutation,
	useProtocolVersionPatchMutation,
	useUseProtocolVersionMutation,
	useProtocolLabelsQuery,
	useCreateProtocolLabelMutation,
	usePatchProtocolLabelMutation,
	useDeleteProtocolLabelMutation,
	useCreateProtocolMutation,
	useDocumentPublishMutation,
	useDocumentPrepublishMutation,
	useExperimentDocumentSyncMutation,
	usePmDocumentSyncMutation,
	usePmDocumentAttachmentMutation,
	useGetPmDocumentAttachmentQuery,
	useLazyExportExperimentMaterialsQuery,
	useGetProjectFoldersQuery,
	useGetProjectFolderQuery,
	useLazyGetProjectFolderQuery,
	useCreateProjectFolderMutation,
	usePatchProjectFolderMutation,
	useDeleteProjectFolderMutation,
	useGetExperimentFileQuery,
	useCreateExperimentFileMutation,
	usePatchExperimentFileMutation,
	useDeleteExperimentFileMutation,
	useSearchProjectFoldersQuery,
	useLazyGetExperimentFileQuery,
	useSearchExperimentFilesQuery,
	useLazySearchExperimentFilesQuery,
	useListExperimentFilesQuery,
	useGetExperimentDocumentReadCommentsQuery,
	useCreateExperimentDocumentReadCommentsMutation,
	useUpdateViewExperimentFileQuery,
	useGetRecentlyOpenedExperimentFilesQuery,
	useGetExperimentFileActivitiesQuery,
	useCreateExperimentActivityMutation,
	useCreateProtocolVersionMutation,
	useExperimentReportsQuery,
	useExperimentReportQuery,
	useCreateExperimentReportMutation,
	usePatchExperimentReportMutation,
	useDeleteExperimentReportMutation,
	useProjectsOverviewQuery,
	useLazyUpdateViewExperimentFileQuery,
	useGetProjectShareableUsersQuery,
	useAddFolderCollaboratorMutation,
	useListFolderMaterialsQuery,
	useProjectArchivedFilesQuery,
	useProjectArchivedFoldersQuery,
	useArchiveFolderMutation,
	useUnarchiveFolderMutation,
	useChangeFileStatusMutation,
	useChangeFileReviewStatusMutation,
	useWithdrawFileMutation,
} = projectManagementApi;

export const useExperimentAttachmentsQuery = (experimentId?: number) => {
	return projectManagementApi.useExperimentAttachmentsQuery(
		experimentId && experimentId > 0 ? experimentId : skipToken
	);
};
