import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./CellInfo.module.scss";
import { Menu } from "antd";
import { useBarcodeModal } from "@common/barcode/BarcodeModal";
import {
	Bookmark,
	ClickToEdit,
	GenemodIcon,
	UpgradeButton,
	Typography,
	TabList,
	ActivityLog,
	Spin,
	LayerSystemContainer,
	ButtonV2,
} from "@components";
import {
	AttachmentCard,
	ItemViewAndEditPanel,
} from "../../../components/ItemViewAndEditPanel/ItemViewAndEditPanel";
import { prepareItemPrint } from "@redux/freezer/BoxViewSlice";
import { useAppDispatch } from "@redux/store";
import { FREEZER_NAME_CHAR_LIMITS } from "../../../data";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import {
	getIsSingleSelection,
	useBoxTableActions,
} from "@redux/freezer/BoxTableSlice";
import {
	useBookmarkToggleClick,
	useHandleAddOrEditItem,
	useBoxView,
} from "../../BoxTableHooks";
import { useOpenOrderFormForItem } from "../../../BoxView/BoxView";
import { useOpenDeleteSelectedBoxTableCellsModal } from "../SelectedCellActionModals/SelectedCellActionModals";
import DropdownV2, {
	DropdownMenuDivider,
	DropdownMenuItem,
} from "@common/components/DropDownV2/dropdownV2";
import { SelectParam } from "antd/lib/menu";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { nanoid } from "nanoid";
import { useSelector } from "react-redux";
import { useItemTypeQuery } from "@redux/inventory/Item";

export const useItemForCellInfo = () => {
	const { item: itemData, items, itemIdInUrl } = useBoxView();
	const item = isNaN(itemIdInUrl as number)
		? null
		: itemData || items.find((item) => item.id === itemIdInUrl);
	return item;
};

const ItemInfoPanel = (): JSX.Element => {
	const { viewOnly, isItemFetching } = useBoxView();
	const item = useItemForCellInfo();
	const { data: itemType } = useItemTypeQuery(item?.item_type || skipToken);
	const handleAddOrEditItem = useHandleAddOrEditItem();
	const handleBookmarkToggleClick = useBookmarkToggleClick();
	const [menuVisible, setMenuVisible] = useState(false);

	const TABS = [];
	if (item) {
		TABS.push(
			...[
				{
					key: "details",
					tabtitle: "Details",
					tabcontent: (
						<div>
							<ItemViewAndEditPanel
								viewOnly={viewOnly}
								item={item}
								nameIsEditable
								onEdit={handleAddOrEditItem}
							/>
						</div>
					),
				},
				{
					key: "activity",
					tabtitle: "Activity",
					tabcontent: (
						<div className={styles.activityContainer}>
							<ItemActivity />
						</div>
					),
				},
				{
					key: "barcode",
					tabtitle: "Barcode",
					tabcontent: (
						<div className={styles.activityContainer}>
							<ItemBarcode />
						</div>
					),
				},
				{
					key: "attachments",
					tabtitle: "Files",
					tabcontent: (
						<div className={styles.activityContainer}>
							<AttachmentCard item={item} viewOnly={viewOnly} />
						</div>
					),
				},
			]
		);
	}

	return (
		<LayerSystemContainer className={styles.itemInfo}>
			<Spin
				spinning={isItemFetching && !item?.name}
				wrapperClassName={styles.spin}
			>
				{item && itemType && (
					<>
						<div className={styles.header}>
							<ClickToEdit
								color="text-primary-v2"
								value={item.name}
								className={styles.itemName}
								onComplete={(value) => {
									handleAddOrEditItem(
										{
											...item,
											name: value,
										},
										() => {
											return;
										}
									);
								}}
								validators={[
									{
										validator: (val) => val.length > 0,
										error: "Please specify the name",
									},
								]}
								readOnly={viewOnly}
								maxLength={FREEZER_NAME_CHAR_LIMITS.ITEM}
								component="input"
								dataCy="cell-info-name"
							/>
							<Bookmark
								isBookmarked={item.is_bookmarked}
								onChange={() => handleBookmarkToggleClick(item)}
								size="large"
								containerStyle={{ marginLeft: "auto" }}
							/>
							<DropdownV2
								onVisibleChange={(visible) =>
									setMenuVisible(visible)
								}
								getPopupContainer={() => document.body}
								overlay={
									<DropDownMenu
										onSelect={() => setMenuVisible(false)}
									/>
								}
								open={menuVisible}
							>
								<GenemodIcon
									name="meatballs"
									size="large"
									dataCy="cell-dowpdown-menu"
									hoverEffect
								/>
							</DropdownV2>
						</div>
						<TabList
							tabListItems={TABS}
							largeSize={true}
							defaultActiveKey="details"
							className={styles.tabs}
							hasTopGradient={false}
							hasBottomGradient={false}
						/>
					</>
				)}
			</Spin>
		</LayerSystemContainer>
	);
};

const DropDownMenu = ({ onSelect: onSelectExt }: { onSelect: () => void }) => {
	const dispatch = useAppDispatch();
	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const { item, viewOnly } = useBoxView();
	const isSingleSelection = useSelector(getIsSingleSelection);
	const openOrderForm = useOpenOrderFormForItem({ fromBoxView: true });
	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const { setIsImporting, setMovingItems } = useBoxTableActions();
	const deleteSelected = useOpenDeleteSelectedBoxTableCellsModal();

	const { is_limit_reached: orderManagementRestricted } =
		useFeatureRestrictionHook("order_management");

	if (!item) return <></>;
	const { id } = item;

	const onSelect = (param: SelectParam) => {
		if (param.key === "import") setIsImporting(true);
		if (param.key === "move") setMovingItems(true);
		if (param.key === "order") {
			if (orderManagementRestricted) {
				openUpgradeModal({ type: "UNLOCK_ORDERS" });
				return;
			}

			openOrderForm();
		}
		if (param.key === "print") dispatch(prepareItemPrint(id));
		if (param.key === "share") openShareLinkModal({});
		if (param.key === "delete") deleteSelected();
		onSelectExt();
	};

	const Item = Menu.Item;
	return (
		<Menu onSelect={onSelect} selectedKeys={[]}>
			{!viewOnly && (
				<DropdownMenuItem key="import" label="Import items" />
			)}
			{!viewOnly && <DropdownMenuItem key="move" label="Move selected" />}
			{isSingleSelection && (
				<Item key="order">
					Request order
					{orderManagementRestricted && (
						<UpgradeButton type="tag" style={{ marginLeft: 24 }} />
					)}
				</Item>
			)}
			{isSingleSelection && (
				<DropdownMenuItem key="print" label="Print" />
			)}
			{isSingleSelection && (
				<DropdownMenuItem
					key="share"
					label="Share"
					dataCy="item-menu-share"
				/>
			)}
			{!viewOnly && <DropdownMenuDivider />}
			{!viewOnly && (
				<DropdownMenuItem
					key="delete"
					label="Delete selected"
					color="dust-red"
				/>
			)}
		</Menu>
	);
};

function ItemActivity() {
	const [refreshTrigger, setRefreshTrigger] = useState(nanoid());
	const ref = useRef<HTMLDivElement | null>(null);
	const { item } = useBoxView();

	useEffect(() => {
		setRefreshTrigger(nanoid());
		if (!ref.current) return;
		// keep the height of the card the same when refreshing
		ref.current.style.height = window.getComputedStyle(ref.current).height;
	}, [item]);

	if (!item) return <></>;

	return (
		<ActivityLog
			key={refreshTrigger}
			activityFilter={{
				location_model: "Item",
				location_object_id: item.id,
			}}
			onDoneLoading={() => {
				if (!ref.current) return;
				ref.current.style.height = "auto";
			}}
		/>
	);
}

function ItemBarcode() {
	const barcodeContext = useBarcodeModal();
	const { item } = useBoxView();

	if (!item) return <></>;
	const onClick = () => {
		if (!item) return;
		barcodeContext.openBarcodeModal([item]);
	};
	return (
		<div>
			<Typography
				variant="headline"
				style={{ marginBottom: 8 }}
				color="text-primary-v2"
				semibold
			>
				Barcodes
			</Typography>
			<Typography
				variant="subheadline"
				color="text-tertiary-v2"
				style={{ marginBottom: 16 }}
			>
				Generate or link barcodes directly in Genemod.
			</Typography>
			<ButtonV2 type="primary" onClick={onClick}>
				Print barcode
			</ButtonV2>
		</div>
	);
}

export function InfoSectionDivider({
	className,
}: {
	className?: string;
}): JSX.Element {
	return <div className={cn("divider", className)} />;
}

export default ItemInfoPanel;
