import {
	Button,
	ButtonV2,
	GenemodIcon,
	Modal,
	Notification,
} from "@common/components";
import React, {
	useState,
	createContext,
	useContext,
	SetStateAction,
	Dispatch,
	useEffect,
} from "react";
import { REPOSITORY_SECTIONS } from "@containers/Freezer/CreateNewItem/data";
import CreateNewItem from "@containers/Freezer/CreateNewItem/CreateNewItem";
import styles from "./styles.module.scss";
import {
	useBulkItemsCreateMutation,
	useItemCreateMutation,
} from "@redux/inventory/Item";
import { FurnitureCategoryId, Item } from "@common/types";
import { truncArgs } from "@helpers/Formatters";
import { useLocation } from "react-router-dom";
import { useParams } from "@helpers/URLParams";
import ConsumableCreatePanel from "@containers/Freezer/FurnitureCategoryView/ConsumableCreatePanel/ConsumableCreatePanel";

type ErrorsContextProps = {
	hasErrors: boolean;
	setHasErrors: Dispatch<SetStateAction<boolean>>;
};

export const ErrorsContext = createContext({} as ErrorsContextProps);

function AddBtn({
	onClick,
	disabled,
}: {
	onClick?: () => void;
	disabled?: boolean;
}) {
	const { hasErrors } = useContext(ErrorsContext);
	return (
		<div
			style={{
				display: "block",
				position: "absolute",
				right: "32px",
				bottom: "32px",
			}}
		>
			<Button
				onClick={onClick}
				disabled={disabled || hasErrors}
				dataCy="add-new-item-btn"
			>
				Add
			</Button>
		</div>
	);
}

function NewItemModal({
	isOpen,
	setIsOpen,
	isConsumable = false,
}: {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isConsumable?: boolean;
}) {
	const [bulkCreateItems] = useBulkItemsCreateMutation();
	const [createNewItem] = useItemCreateMutation();
	const [hasErrors, setHasErrors] = useState(false);
	const { pathname, search } = useLocation();

	const handleCreateNewItem = (
		item: Item,
		callback?: (newBox: boolean, item: Item | Error) => void,
		copies?: number
	) => {
		setIsOpen(false);
		if (copies && copies > 1) {
			const payload = Array.from({ length: copies }, () => ({ ...item }));
			bulkCreateItems({ items: payload })
				.then((res) => {
					if ("data" in res && Array.isArray(res.data.items)) {
						// Iterate through the items
						for (const item of res.data.items) {
							callback && callback(true, item);
						}
					}
					Notification.success({
						message: (
							<span>
								{copies}
								{" Items been created."}
							</span>
						),
					});
				})
				.catch(() => {
					Notification.error({
						message: "Something happened, try later or contact us",
					});
				});
		} else {
			createNewItem({ item })
				.unwrap()
				.then((data) => {
					callback && callback(true, data);
					Notification.success({
						message: (
							<span>
								<b>{truncArgs`${data.name}`(68)}</b>
								{" has been created."}
							</span>
						),
					});
				})
				.catch(() => {
					Notification.error({
						message: "Something happened, try later or contact us",
					});
				});
		}
	};

	return (
		<Modal
			visible={isOpen}
			onCancel={() => setIsOpen(false)}
			title={isConsumable ? "Add new consumable" : "Add new item"}
			hideCancelButton
			hideOkButton
			destroyOnClose
			bodyStyle={{
				maxHeight: "calc(100vh - 343px)",
				overflow: "auto",
			}}
			wrapClassName={styles.newItemModal}
			dataCy="new-item"
		>
			<ErrorsContext.Provider value={{ hasErrors, setHasErrors }}>
				{isConsumable ? (
					<form className="information-form" autoComplete="off">
						<div className="form-body">
							<ConsumableCreatePanel
								extraPadding
								setConsumableId={() => {}}
								setIsCreate={setIsOpen}
								isRepositoryPage
							>
								<AddBtn />
							</ConsumableCreatePanel>
						</div>
					</form>
				) : (
					<CreateNewItem
						title="Create new item"
						focusedCell={null}
						onCancel={() => setIsOpen(false)}
						handleSubmit={handleCreateNewItem}
						withoutHeader
						formSections={REPOSITORY_SECTIONS}
						layerColor={1}
						redirectTo={pathname + search + "&modalOpen=true"}
						isRepositoryPage
					>
						<AddBtn />
					</CreateNewItem>
				)}
			</ErrorsContext.Provider>
		</Modal>
	);
}

function NewItemBtn({ isConsumable = false }: { isConsumable?: boolean }) {
	const [isOpen, setIsOpen] = useState(false);
	const { getParam } = useParams();

	useEffect(() => {
		if (getParam("modalOpen")) {
			setIsOpen(true);
		}
	}, [getParam("modalOpen")]);
	return (
		<>
			<ButtonV2
				type="primary"
				icon={"plus"}
				onClick={() => setIsOpen(true)}
				dataCy="new-item-btn"
			>
				{isConsumable ? "New consumable" : "New item"}
			</ButtonV2>
			<NewItemModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				isConsumable={isConsumable}
			/>
		</>
	);
}

export default NewItemBtn;
