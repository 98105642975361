import { ModalProps } from "@common/components/Modal/Modal";
import SimpleFilter from "@common/components/SimpleFilter";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { ColorCssVarMap } from "@common/styles/Colors";
import {
	CURRENCY_TYPES,
	ExperimentMaterial,
	ExperimentMaterialOriginEnum,
	ExperimentMaterialOriginEnumToLabel,
	FolderMaterial,
	GroupedSearchResultItem,
	formatCustomId,
} from "@common/types";
import {
	Button,
	GenemodAnchorme,
	GenemodIcon,
	Input,
	InputV2,
	Modal,
	Notification,
	ResponsiveTable,
	Select,
	SelectV2,
	Tooltip,
	Typography,
} from "@components";
import column_styles from "@components/Table/ItemColumnWidth.module.scss";
import { FREEZER_PATHS } from "@containers/Freezer";
import { truncArgs } from "@helpers/Formatters";
import { useDebouncedCallback } from "@helpers/Hooks";
import { nameKeysOfConst } from "@helpers/TypeHelpers";
import {
	useExperimentMaterialCreateMutation,
	useExperimentMaterialDeleteMutation,
	useExperimentMaterialPatchMutation,
	useFolderMaterialCreateMutation,
	useFolderMaterialDeleteMutation,
	useFolderMaterialPatchMutation,
} from "@redux/ProjectManagement/PmApiSlice";
import {
	useConsumableItemSearchQuery,
	useFreezerItemGroupedByNameSearchQuery,
} from "@redux/freezer/FreezerApiSlice";
import { useOrganizationRouter } from "@root/AppRouter";
import { AutoComplete } from "antd";
import { ColumnProps, SorterResult } from "antd/lib/table";
import cn from "classnames";
import isUrl from "is-url";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
	ConsumableLinkModal,
	FreezerLinkModal,
	LinkModalStateContext,
	LinkModalStateContextProvider,
} from "../MaterialsLinkModals";
import styles from "./index.module.scss";
import InputV3 from "@common/components/InputV3/InputV3";

type ExperimentFolderMaterial = ExperimentMaterial | FolderMaterial;

type MaterialsTableColumns =
	| "name"
	| "origin"
	| "link"
	| "source"
	| "reference"
	| "catalog"
	| "lot"
	| "packaging"
	| "price";

type CustomMaterialTableColumn = {
	key: MaterialsTableColumns;
	props: ColumnProps<ExperimentFolderMaterial>;
};

type MaterialTableProps = {
	materials: ExperimentFolderMaterial[];
	newMaterials: ExperimentFolderMaterial[];
	setNewMaterials: (materials: ExperimentFolderMaterial[]) => void;
	isLoading: boolean;
	scrollHeight?: number;
	scrollWidth?: number;
	isArchived: boolean;
	columns?: CustomMaterialTableColumn[];
	onOriginFilterChange?: (origins: ExperimentMaterialOriginEnum[]) => void;
	hideOriginFilter?: boolean;
	isFolderMaterial?: boolean;
};

export default function MaterialsTable({
	materials,
	newMaterials,
	setNewMaterials,
	isLoading,
	scrollHeight,
	scrollWidth,
	isArchived,
	columns,
	onOriginFilterChange,
	hideOriginFilter,
	isFolderMaterial,
}: MaterialTableProps): JSX.Element {
	const [editingMaterial, setEditingMaterial] = useState<
		undefined | ExperimentFolderMaterial
	>(undefined);
	const [isEditingLink, setIsEditingLink] = useState(false);
	const materialsList = useMemo(() => {
		/** Merge materials list with the new added materials list */
		let list = [...newMaterials, ...materials];
		/** If there is a material that is being edited and the user uses the search input, the editing material row will be fixed to the top of the list*/
		if (editingMaterial) {
			if (
				materials.some((material) => material.id === editingMaterial.id)
			) {
				list = list.map((material) => {
					if (material.id === editingMaterial?.id) {
						return editingMaterial;
					}
					return material;
				});
			} else {
				// Editing material is not in the list. Append it
				list = [editingMaterial, ...list];
			}
		}
		return list.map((material) => ({
			...material,
			key: material.id,
		}));
	}, [materials, newMaterials, editingMaterial]);
	const [deleteMaterial, setDeleteMaterial] =
		useState<ExperimentFolderMaterial | null>(null);
	const [updateExperimentMaterial] = useExperimentMaterialPatchMutation();
	const [updateFolderMaterial] = useFolderMaterialPatchMutation();
	const [
		deleteExperimentMaterialMutation,
		{ isLoading: isDeletingExperimentMaterial },
	] = useExperimentMaterialDeleteMutation();
	const [
		deleteFolderMaterialMutation,
		{ isLoading: isDeletingFolderMaterial },
	] = useFolderMaterialDeleteMutation();
	const [addExperimentMaterial] = useExperimentMaterialCreateMutation();
	const [addFolderMaterial] = useFolderMaterialCreateMutation();
	const { appendBaseUrl } = useOrganizationRouter();

	const [originFilter, setOriginFilter] = useState<
		ExperimentMaterialOriginEnum[]
	>([]);

	const handleOriginFilterOnChange = (value: number) => {
		const newOrigin = value as ExperimentMaterialOriginEnum;
		let origins = [...originFilter];
		if (origins.includes(newOrigin)) {
			origins = originFilter.filter((s) => s !== newOrigin);
		} else {
			origins = [...origins, newOrigin];
		}
		setOriginFilter(origins);
		onOriginFilterChange?.(origins);
	};

	const [linkError, setLinkError] = useState(false);

	const handleEditCell = (updatedMaterial: ExperimentFolderMaterial) => {
		if (editingMaterial?.id === updatedMaterial.id) {
			setEditingMaterial(updatedMaterial);
			return;
		}
		setNewMaterials(
			newMaterials.map((material) => {
				if (material.id === updatedMaterial.id) {
					return updatedMaterial;
				}
				return material;
			})
		);
	};

	const handleCancelEditCell = (id: number) => {
		if (id === editingMaterial?.id) {
			setEditingMaterial(undefined);
			setIsEditingLink(false);
			return;
		}
		setNewMaterials(newMaterials.filter((material) => material.id !== id));
	};

	const renderLinkCell = (material: ExperimentFolderMaterial) => {
		if (material.origin === ExperimentMaterialOriginEnum.WEB_LINK) {
			if (material.link) {
				return (
					<div className={styles.flex} style={{ gap: 12 }}>
						<GenemodIcon
							name="globe"
							fill="link-primary"
							size="large"
						/>
						<Typography
							style={{ cursor: "pointer" }}
							withLinks
							ellipsis
						>
							{material.link}
						</Typography>
					</div>
				);
			}
		} else if (
			material.origin === ExperimentMaterialOriginEnum.FREEZER &&
			material.freezer_item
		) {
			const item = material.freezer_item;
			const pathname = FREEZER_PATHS.BOXES.replace(
				":freezer_id",
				(item?.location?.freezer || -1).toString()
			).replace(
				":box_id",
				(item?.location?.box_location?.box || -1).toString()
			);
			const newParams = new URLSearchParams({
				search_ids: item.id + "",
			});

			const path = {
				pathname: appendBaseUrl(pathname),
				search: `?${newParams.toString()}`,
			};

			return (
				<div className={styles.linkItem}>
					<GenemodIcon
						name="freezer"
						fill="link-primary"
						size="large"
					/>
					<Link
						to={path}
						style={{
							color: ColorCssVarMap["link-primary"],
						}}
						target="_blank"
					>
						{`[${formatCustomId(
							item.organization_prefix,
							item.custom_id
						)}] ${item.name}`}
					</Link>
				</div>
			);
		} else if (
			material.origin === ExperimentMaterialOriginEnum.CONSUMABLES &&
			material.consumable_item
		) {
			const item = material.consumable_item;
			const pathname = FREEZER_PATHS.FURNITURE_CATEGORIES.replace(
				":space_id",
				(item?.parent_space?.id || -1).toString()
			)
				.replace(
					":furniture_id",
					(item?.parent_furniture?.id || -1).toString()
				)
				.replace(
					":furniture_category_id",
					(item?.parent_furniture_category?.id || -1).toString()
				);
			const newParams = new URLSearchParams({
				consumable_id: item.id + "",
			});

			const path = {
				pathname: appendBaseUrl(pathname),
				search: `?${newParams.toString()}`,
			};

			return (
				<div className={styles.linkItem}>
					<GenemodIcon
						name="consumable"
						fill="link-primary"
						size="large"
					/>
					<Link
						to={path}
						style={{
							color: ColorCssVarMap["link-primary"],
						}}
						target="_blank"
					>
						{`[${item.custom_id}] ${item.name}`}
					</Link>
				</div>
			);
		}
		if (!editingMaterial && !isArchived) {
			return (
				<AddLinkButton
					material={material}
					onEditMaterial={setEditingMaterial}
					className={styles.addLinkButton}
					onClick={() => {
						setIsEditingLink(true);
						setEditingMaterial(material);
					}}
				/>
			);
		}
		return " ";
	};

	const getTableColumns = (
		linkError: boolean
	): ColumnProps<ExperimentFolderMaterial>[] => {
		const renderColumn =
			(dataIndex: string) =>
			(text: any, record: ExperimentFolderMaterial) => {
				const catchLinkIndexes = ["reference", "source", "packaging"];
				const isWithLinks = catchLinkIndexes.includes(dataIndex);
				if (record.origin !== ExperimentMaterialOriginEnum.WEB_LINK) {
					const src =
						record.origin === ExperimentMaterialOriginEnum.FREEZER
							? record.freezer_item
							: record.consumable_item;
					if (isWithLinks) {
						return (
							<GenemodAnchorme target="_blank" rel="noopener">
								{(src as any)?.[dataIndex] || ""}
							</GenemodAnchorme>
						);
					} else {
						return (src as any)?.[dataIndex] || "";
					}
				}
				if (isWithLinks) {
					return (
						<GenemodAnchorme target="_blank" rel="noopener">
							{text || ""}
						</GenemodAnchorme>
					);
				} else {
					return text;
				}
			};
		const cols: ColumnProps<ExperimentFolderMaterial>[] = [
			{
				title: "Name",
				key: "name",
				dataIndex: "name",
				ellipsis: true,
				className: column_styles["M_COLUMN_WIDTH"],
			},
			{
				title: "Quantity",
				key: "quantity",
				dataIndex: "quantity",
				render: renderColumn("quantity"),
			},
			{
				title: "Pricing",
				key: "price",
				dataIndex: "price",
				className: column_styles["S_COLUMN_WIDTH"],
				width: 140,
				render: (price, record) => {
					if (
						record.origin !== ExperimentMaterialOriginEnum.WEB_LINK
					) {
						const src =
							record.origin ===
							ExperimentMaterialOriginEnum.FREEZER
								? record.freezer_item
								: record.consumable_item;
						price = (src as any)?.["price"] || "";
					}
					return price
						? `${(CURRENCY_TYPES as any)[record.currency]} ${
								record.price
						  }`
						: "-";
				},
			},
			{
				title: (
					<>
						{hideOriginFilter ? (
							"Link type"
						) : (
							<SimpleFilter
								label="Link type"
								selectedValues={originFilter}
								options={ExperimentMaterialOriginEnumToLabel}
								onChange={handleOriginFilterOnChange}
								onClear={() => {
									setOriginFilter([]);
									onOriginFilterChange?.([]);
								}}
							/>
						)}
					</>
				),
				key: "origin",
				dataIndex: "origin",
				className: column_styles["M_COLUMN_WIDTH"],
				render: (_, row) =>
					`${ExperimentMaterialOriginEnumToLabel[row.origin].label} ${
						row.found ? `(${row.found} found)` : ""
					}`,
			},
			{
				title: "Link",
				key: "link",
				dataIndex: "link",
				className: column_styles["L_COLUMN_WIDTH"],
				render: (_, record) => renderLinkCell(record),
			},
			{
				title: "Source",
				key: "source",
				dataIndex: "source",
				className: column_styles["S_COLUMN_WIDTH"],
				render: renderColumn("source"),
			},
			{
				title: "Reference",
				key: "reference",
				dataIndex: "reference",
				className: column_styles["S_COLUMN_WIDTH"],
				render: renderColumn("reference"),
			},
			{
				title: "Catalog #",
				key: "catalog",
				dataIndex: "catalog",
				className: column_styles["S_COLUMN_WIDTH"],
				render: renderColumn("catalog"),
			},
			{
				title: "Lot #",
				key: "lot",
				dataIndex: "lot",
				className: column_styles["S_COLUMN_WIDTH"],
				render: renderColumn("lot"),
			},
			{
				title: "Packaging",
				key: "packaging",
				dataIndex: "packaging",
				className: column_styles["S_COLUMN_WIDTH"],
				render: renderColumn("packaging"),
			},
			{
				title: "",
				key: "options",
				width: 95,
				fixed: "right",
				render: (_, row) => {
					if (isArchived) return <></>;
					const isEdit = row.id < 0 || editingMaterial?.id === row.id;
					return (
						<div
							className={cn(styles.actionCell, {
								[styles.actionCell__edit]: isEdit,
								[styles.actionCell__disabled]:
									!isEdit && editingMaterial,
							})}
						>
							{isEdit ? (
								<>
									<Tooltip title="Save">
										<GenemodIcon
											name="circle-save"
											onClick={() =>
												editingMaterial?.id === row.id
													? onEdit()
													: row?.name && !linkError
													? onCreate(row)
													: null
											}
											size="large"
											fill={
												!row?.name || linkError
													? "button-disabled"
													: "accent-subtle"
											}
											disabled={!row?.name || linkError}
										/>
									</Tooltip>
									<Tooltip title="Cancel">
										<GenemodIcon
											name="circle-cancel"
											onClick={() => {
												handleCancelEditCell(row.id);
												setLinkError(false);
											}}
											size="large"
											fill="accent-subtle"
											stroke="accent-subtle"
										/>
									</Tooltip>
								</>
							) : (
								<div
									className={styles.flex}
									style={{ gap: 16 }}
								>
									<Tooltip title="Edit">
										<div
											className={
												styles.editAndDeleteButton
											}
										>
											<GenemodIcon
												name="edit"
												onClick={() => {
													if (!editingMaterial) {
														setEditingMaterial(row);
													}
												}}
												size="default"
												fill={
													editingMaterial &&
													row.id !==
														editingMaterial.id
														? "button-disabled"
														: undefined
												}
												className={
													editingMaterial &&
													row.id !==
														editingMaterial.id
														? styles.iconDisabled
														: ""
												}
											/>
										</div>
									</Tooltip>
									<Tooltip title="Delete">
										<div
											className={
												styles.editAndDeleteButton
											}
										>
											<GenemodIcon
												name="trash"
												size="default"
												onClick={() => {
													if (!editingMaterial) {
														setDeleteMaterial(row);
													}
												}}
												fill={
													editingMaterial &&
													row.id !==
														editingMaterial.id
														? "button-disabled"
														: undefined
												}
											/>
										</div>
									</Tooltip>
								</div>
							)}
						</div>
					);
				},
			},
		];

		if (columns) {
			/** If "columns" props is present it will iterate over the columns array finding the columns passed in the prop */
			const customColumns = columns.map((col) => {
				let column = cols.find((x) => x.key === col.key);
				/** The default column props and "columns" props are being merged. */
				column = {
					...column,
					...col.props,
				};
				return {
					...column,
					/** The onCell prop is needed to handle the editing state of the table row  */
					onCell: (record: ExperimentFolderMaterial) => ({
						dataIndex: column?.dataIndex || 0,
						editing:
							record.id < 0 || editingMaterial?.id === record.id,
						material:
							editingMaterial?.id === record.id
								? editingMaterial
								: record,
						onEditMaterial: handleEditCell,
						linkError,
						setLinkError,
					}),
				};
			});
			/** We will get the options columns of the columns list and add it as default, independent of the columns passed in the "column" prop */
			const options = cols.find((x) => x.key === "options");
			if (options) {
				/** Pushing the options column to the passed columns. We add the onCell prop to the option columns to handle the editing state of the row. */
				customColumns.push({
					...options,
					onCell: (record) => ({
						dataIndex: options.dataIndex || 0,
						editing:
							record.id < 0 || editingMaterial?.id === record.id,
						material:
							editingMaterial?.id === record.id
								? editingMaterial
								: record,
						onEditMaterial: handleEditCell,
						linkError,
						setLinkError,
					}),
				});
			}

			/** Finally we return the columns */
			return customColumns;
		}

		return cols.map((col) => ({
			...col,
			onCell: (record) => ({
				dataIndex: col.dataIndex,
				editing: record.id < 0 || editingMaterial?.id === record.id,
				autoFocusField:
					record.id > 0 && isEditingLink ? "link" : "name",
				material:
					editingMaterial?.id === record.id
						? editingMaterial
						: record,
				onEditMaterial: handleEditCell,
				linkError,
				setLinkError,
			}),
		}));
	};

	const onCreate = (newMaterial: ExperimentFolderMaterial) => {
		const addMaterial = isFolderMaterial
			? addFolderMaterial
			: addExperimentMaterial;
		addMaterial(newMaterial as any)
			.unwrap()
			.then(() => {
				setNewMaterials(
					newMaterials.filter(
						(material) => material.id !== newMaterial.id
					)
				);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${newMaterial.name}`(68)}</b>
							{" has been created."}
						</span>
					),
				});
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to add a new material.",
				});
			});
	};

	const onEdit = () => {
		if (!editingMaterial) return;
		const updateMaterial = isFolderMaterial
			? updateFolderMaterial
			: updateExperimentMaterial;
		updateMaterial(editingMaterial)
			.unwrap()
			.then(() => {
				setEditingMaterial(undefined);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${editingMaterial.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to update material.",
				});
			});
	};

	const onDelete = async () => {
		if (!deleteMaterial) return;
		const deleteMaterialMutation = isFolderMaterial
			? deleteFolderMaterialMutation
			: deleteExperimentMaterialMutation;
		deleteMaterialMutation(deleteMaterial.id)
			.unwrap()
			.then(() => {
				setDeleteMaterial(null);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${deleteMaterial.name}`(68)}</b>
							{" has been deleted."}
						</span>
					),
				});
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to delete material.",
				});
			});
	};

	const onSortChange = (
		_: any,
		__: any,
		sorter: SorterResult<ExperimentFolderMaterial>
	) => {
		// let sort_by: MaterialsFilters["sort_by"] = undefined;
		// if (sorter.order) {
		// 	sort_by = `${sorter.order === "ascend" ? "" : "-"}${
		// 		sorter.columnKey
		// 	}` as MaterialsFilters["sort_by"];
		// }
		// onSortByChange(sort_by);
	};

	return (
		<LinkModalStateContextProvider>
			<ResponsiveTable<ExperimentFolderMaterial>
				components={{ body: { cell: EditableCell } }}
				columns={getTableColumns(linkError)}
				dataSource={materialsList}
				shadedHeader
				// controlledSortKey={sortBy}
				onChange={onSortChange}
				loading={isLoading}
				className={styles.materialsTable}
				scroll={{ y: scrollHeight, x: scrollWidth }}
				useLoadingSkeleton
			/>
			<DeleteModal
				visible={!!deleteMaterial}
				onCancel={() => setDeleteMaterial(null)}
				onOk={onDelete}
				okButtonProps={{
					loading:
						isDeletingFolderMaterial ||
						isDeletingExperimentMaterial,
				}}
				material={deleteMaterial}
			/>
			<ConsumableLinkModal />
			<FreezerLinkModal />
		</LinkModalStateContextProvider>
	);
}

function DeleteModal(
	props: Omit<ModalProps, "children"> & {
		material: ExperimentFolderMaterial | null;
	}
) {
	const { material, ...modalProps } = props;
	const inventoryWarning =
		material?.origin === ExperimentMaterialOriginEnum.WEB_LINK ? (
			""
		) : (
			<>
				This <b>will not</b> delete your record in the Inventory.
			</>
		);
	return (
		<Modal
			title="Delete material?"
			hideCancelButton
			okText="Delete"
			{...modalProps}
		>
			You are about to delete &quot;{`${material?.name}`}&quot;.{" "}
			{inventoryWarning} <b>This action cannot be undone.</b>
		</Modal>
	);
}

type EditableCellProps = React.HTMLAttributes<HTMLElement> & {
	/** autofocus field when it is rendered (default = "name") */
	autoFocusField?: "name" | "link";
	editing: boolean;
	material: ExperimentFolderMaterial;
	onEditMaterial: (material: ExperimentFolderMaterial) => void;
	dataIndex: MaterialsTableColumns;
	children: React.ReactNode;
	linkError: boolean;
	setLinkError: (error: boolean) => void;
};

function EditableCell({
	autoFocusField = "name",
	dataIndex,
	editing,
	children,
	material: originalMaterial,
	onEditMaterial,
	linkError,
	setLinkError,
	...restProps
}: EditableCellProps) {
	if (!dataIndex) return <td {...restProps}>{children}</td>;
	let inputNode;
	const [material, setMaterial] = useState<ExperimentFolderMaterial>({
		...originalMaterial,
	});

	useEffect(() => {
		setMaterial(originalMaterial);
	}, [originalMaterial]);

	useEffect(() => {
		if (
			editing &&
			material.origin !== ExperimentMaterialOriginEnum.WEB_LINK
		) {
			const dataSrc =
				material.origin === ExperimentMaterialOriginEnum.FREEZER
					? material.freezer_item
					: material.consumable_item;
			// Assuming that everything is disabled correctly...
			const _material = {
				...material,
				source: dataSrc?.source || "",
				reference: dataSrc?.reference || "",
				catalog: dataSrc?.catalog || "",
				lot: dataSrc?.lot || "",
				packaging: dataSrc?.packaging || "",
				price: dataSrc?.price || "",
				currency: dataSrc?.currency || 0,
			} as ExperimentFolderMaterial;
			setMaterial(_material);
			onEditMaterial(_material);
		}
	}, [material.freezer_item, material.consumable_item]);

	const error = useMemo(() => {
		if (linkError) {
			return "Enter a valid URL.";
		} else {
			return null;
		}
	}, [linkError]);

	const validateAndFixUrl = (input: string) =>
		((url) => (isUrl(url) ? url : null))(
			input.startsWith("www.") ? `http://${input}` : input
		);

	const handleInputChange = (e: { target: { value: string } }) => {
		const url = validateAndFixUrl(e.target.value);
		if (url) {
			setLinkError(false);
		} else {
			setLinkError(true);
		}
		onEditMaterial({
			...originalMaterial,
			link: e.target.value,
		});
	};

	const disabledInfoCell =
		material.origin !== ExperimentMaterialOriginEnum.WEB_LINK &&
		dataIndex.toString() !== "quantity";

	switch (dataIndex) {
		case "name":
			inputNode = (
				<MaterialNameCell
					autoFocus={autoFocusField === "name"}
					value={material?.name || ""}
					onChange={(name, origin) =>
						onEditMaterial({
							...originalMaterial,
							name,
							origin,
						})
					}
					material={material}
				/>
			);
			break;
		case "origin":
			inputNode = (
				<OriginEditCell
					value={material?.origin || 0}
					onChange={(origin) =>
						onEditMaterial({
							...material,
							origin,
						})
					}
				/>
			);
			break;
		case "link":
			// Note: This stuff only shows up in edit mode
			if (material.origin !== ExperimentMaterialOriginEnum.WEB_LINK) {
				if (
					(material.origin === ExperimentMaterialOriginEnum.FREEZER &&
						!material.freezer_item) ||
					(material.origin ===
						ExperimentMaterialOriginEnum.CONSUMABLES &&
						!material.consumable_item)
				) {
					inputNode = (
						<AddLinkButton
							material={material}
							onEditMaterial={onEditMaterial}
						/>
					);
				} else {
					inputNode = (
						<div className={styles.editLinkItemContainer}>
							{children}
							<Tooltip title="Remove link">
								<GenemodIcon
									size="large"
									name="remove-link"
									onClick={() => {
										onEditMaterial({
											...material,
											freezer_item: null,
											consumable_item: null,
										});
									}}
								/>
							</Tooltip>
						</div>
					);
				}
			} else {
				// Web link origin
				inputNode = (
					<InputV2
						prefix={<GenemodIcon name="globe" decorative />}
						autoFocus={autoFocusField === "link"}
						value={material?.link || ""}
						onChange={handleInputChange}
						wrapperProps={{ style: { marginBottom: 0 } }}
						placeholder="Insert URL"
						error={error}
					/>
				);
			}
			break;
		case "price":
			inputNode = (
				<CurrencyEditCell
					currency={material.currency || 0}
					price={material?.price || material.price || ""}
					setCurrency={(currency) =>
						onEditMaterial({
							...material,
							currency,
						})
					}
					setPrice={(price) => {
						onEditMaterial({
							...material,
							price,
						});
					}}
				/>
			);
			break;
		default:
			inputNode = (
				<InputV2
					value={material?.[dataIndex] || ""}
					onChange={(e) =>
						onEditMaterial({
							...material,
							[dataIndex]: e.target.value,
						})
					}
					wrapperProps={{
						style: {
							marginBottom: 0,
							cursor: disabledInfoCell
								? "not-allowed"
								: "inherit",
						},
					}}
					disabled={disabledInfoCell}
				/>
			);
	}
	return <td {...restProps}>{editing ? inputNode : children}</td>;
}

type AddLinkButtonProps = {
	material: ExperimentFolderMaterial;
	onEditMaterial: (material: ExperimentFolderMaterial) => void;
	/** extra function after button clicked */
	onClick?: () => void;
	/** className on LinkButton */
	className?: string;
};
function AddLinkButton({
	material,
	onEditMaterial,
	onClick,
	className,
}: AddLinkButtonProps) {
	const { setLinkModalState } = useContext(LinkModalStateContext);
	return (
		<Button
			type="ghost"
			className={className}
			style={{
				width: "max-content",
				paddingLeft: 4,
				paddingRight: 8,
			}}
			onClick={() => {
				onClick?.();
				switch (material.origin) {
					case ExperimentMaterialOriginEnum.CONSUMABLES:
						setLinkModalState({
							type: "consumables",
							materialItem: material,
							materialCallback: onEditMaterial,
							defaultSearch: material.name,
						});
						break;
					case ExperimentMaterialOriginEnum.FREEZER:
						setLinkModalState({
							type: "freezer",
							materialItem: material,
							materialCallback: onEditMaterial,
							defaultSearch: material.name,
						});
						break;
					default:
						return;
				}
			}}
			shape="squared"
			icon="plus"
			size="small"
		>
			{material.origin === ExperimentMaterialOriginEnum.WEB_LINK &&
				"Add URL"}
			{material.origin === ExperimentMaterialOriginEnum.CONSUMABLES &&
				"Link consumable item"}
			{material.origin === ExperimentMaterialOriginEnum.FREEZER &&
				"Link freezer item"}
		</Button>
	);
}

type MaterialNameCellProps = {
	autoFocus: boolean;
	value: string;
	onChange: (name: string, origin: ExperimentMaterialOriginEnum) => void;
	material: ExperimentFolderMaterial;
};
function MaterialNameCell({
	autoFocus,
	value,
	onChange,
	material,
}: MaterialNameCellProps) {
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState(value);

	useDebouncedCallback(() => setSearch(value), 200, [value]);

	const { data: freezerSearchResult } =
		useFreezerItemGroupedByNameSearchQuery(
			{
				search,
				is_shared: true,
				is_archived: false,
			},
			{
				skip: !search,
			}
		);
	const { data: consumableSearchResults } = useConsumableItemSearchQuery(
		{
			search,
		},
		{
			skip: !search,
		}
	);
	const freezerResults = useMemo(() => {
		if (!freezerSearchResult) {
			return [];
		}
		return freezerSearchResult.results.map((item) => ({
			name: item.name,
			count: (item as GroupedSearchResultItem).count,
			value: `FREEZER ${item.name}`,
		}));
	}, [freezerSearchResult]);
	const consumableResults = useMemo(() => {
		if (!consumableSearchResults?.count) {
			return [];
		}
		return consumableSearchResults.results.map((item) => ({
			name: item.name,
			count: item.count,
			value: `CONSUMABLE ${item.name}`,
		}));
	}, [consumableSearchResults]);

	const renderOptionContent = (name: string, count: number) => (
		<div
			className={styles.searchOption}
			style={{
				justifyContent: "space-between",
			}}
		>
			<Typography variant="label" ellipsis hideTooltip>
				{name}
			</Typography>
			<Typography variant="caption" color="text-tertiary">
				{`${count} match${count > 1 ? "es" : ""}`}
			</Typography>
		</div>
	);

	return (
		<AutoComplete
			autoFocus={autoFocus}
			className={styles.materialNameAutoComplete}
			dropdownClassName={cn(styles.materialNameAutoCompleteDropdown, {
				[styles.nameSearchEmptyMsg__Freezer]:
					freezerResults.length === 0,
				[styles.nameSearchEmptyMsg__Consumable]:
					consumableResults.length === 0,
			})}
			value={value}
			onChange={(v) => {
				const name = String(v).slice(0, 200);
				onChange(name, material.origin);
				if (!open && !!name) {
					setOpen(true);
				} else if (open && !name) {
					setOpen(false);
				}
			}}
			onSelect={(v, option: any) => {
				let origin = ExperimentMaterialOriginEnum.WEB_LINK;
				const optionValue = typeof v === "string" ? v : "";
				const name = option?.props?.label || "";
				if (optionValue.includes("FREEZER")) {
					origin = ExperimentMaterialOriginEnum.FREEZER;
				} else if (optionValue.includes("CONSUMABLE")) {
					origin = ExperimentMaterialOriginEnum.CONSUMABLES;
				}
				onChange(name, origin);
			}}
			optionLabelProp="value"
			dropdownMatchSelectWidth={false}
			open={open}
			onDropdownVisibleChange={(visible) => setOpen(!!value && visible)}
		>
			<AutoComplete.Option
				key="custom-option"
				label={value || ""}
				value={value || ""}
			>
				<div
					className={styles.flex}
					style={{
						gap: 8,
					}}
				>
					<GenemodIcon name="plus" stroke="text-secondary" />
					<Typography>{`Add “${value}” as external material`}</Typography>
				</div>
			</AutoComplete.Option>
			<AutoComplete.OptGroup
				key="freezer-optgroup"
				label="Freezer results"
			>
				{freezerResults.map((result, index) => (
					<AutoComplete.Option
						key={result.value + index}
						label={result.name}
						value={result.value}
					>
						{renderOptionContent(result.name, result.count)}
					</AutoComplete.Option>
				))}
			</AutoComplete.OptGroup>
			<AutoComplete.OptGroup
				key="consumable-optgroup"
				label="Consumables results"
			>
				{consumableResults.map((result, index) => (
					<AutoComplete.Option
						key={result.value + index}
						label={result.name}
						value={result.value}
					>
						{renderOptionContent(result.name, result.count)}
					</AutoComplete.Option>
				))}
			</AutoComplete.OptGroup>
		</AutoComplete>
	);
}

type OriginEditCellProps = {
	value: ExperimentMaterialOriginEnum;
	onChange: (v: ExperimentMaterialOriginEnum) => void;
};
function OriginEditCell({ value, onChange }: OriginEditCellProps) {
	return (
		<SelectV2
			value={value}
			onChange={(v) => onChange(v as ExperimentMaterialOriginEnum)}
			className={styles.tableSelect}
			getPopupContainer={(trigger) => document.body}
			optionLabelProp="label"
		>
			{Object.keys(ExperimentMaterialOriginEnumToLabel).map((key) => {
				const originKey = +key as ExperimentMaterialOriginEnum;
				const displayLabel =
					ExperimentMaterialOriginEnumToLabel[originKey].label;
				return (
					<Select.Option
						value={originKey}
						key={displayLabel}
						label={displayLabel}
					>
						<Select.SelectedOpt
							isSelected={value === originKey}
							label={displayLabel}
						/>
					</Select.Option>
				);
			})}
		</SelectV2>
	);
}

type CurrencyEditCellProps = {
	currency: ExperimentFolderMaterial["currency"];
	price: string;
	// onChange: (material: ExperimentFolderMaterial) => void;
	setCurrency: (currency: ExperimentFolderMaterial["currency"]) => void;
	setPrice: (price: string) => void;
	setIsEditingCurrency?: (isEditing: boolean) => void;
};
export function CurrencyEditCell({
	currency,
	price,
	setCurrency,
	setPrice,
	setIsEditingCurrency,
}: CurrencyEditCellProps) {
	return (
		<InputV2
			wrapperProps={{
				style: {
					marginBottom: 0,
				},
			}}
			prefixProps={{ style: { padding: 0 } }}
			placeholder={"0.00"}
			type="number"
			onChange={(ev) => setPrice(ev.target.value)}
			value={price}
			prefix={
				<SelectV2
					onClick={(e) => {
						setIsEditingCurrency?.(true);
						e.stopPropagation();
					}}
					className={cn(styles.currencySelector)}
					value={currency}
					onChange={(idx) =>
						setCurrency(idx as ExperimentFolderMaterial["currency"])
					}
					isInput
					scrollSafeMode={true}
					optionLabelProp="label"
					getPopupContainer={() => document.body}
				>
					{nameKeysOfConst(CURRENCY_TYPES).map((key) => (
						<Select.Option
							key={CURRENCY_TYPES[key]}
							value={CURRENCY_TYPES[key]}
							label={CURRENCY_TYPES[CURRENCY_TYPES[key]]}
						>
							<Select.SelectedOpt
								isSelected={currency === CURRENCY_TYPES[key]}
								label={`${
									CURRENCY_TYPES[CURRENCY_TYPES[key]]
								} ${key}`}
							/>
						</Select.Option>
					))}
				</SelectV2>
			}
			maxLength={15 /* One digit less than "MAX_SAFE_INTEGER" */}
		/>
	);
}
