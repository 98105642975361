import { DropDownWithItems } from "@common/components/DropDown/dropdown";
import { IconName } from "@common/components/GenemodIcon/GenemodIconRegistry";
import { useTheme } from "@common/components/Theme/context/ThemeContext";
import {
	Color,
	ColorCssVarMap,
	HexColorToRgbaText,
} from "@common/styles/Colors";
import { SharingOptions, SimpleSpace } from "@common/types";
import {
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
	LayerSystemContainer,
	Typography,
} from "@components";
import useCurrentWorkspaceUserHooks from "@helpers/Hooks/UseCurrentWorkspaceUserHook";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import classNames from "classnames";
import React, { MouseEventHandler, useState } from "react";
import styles from "./SpaceCard.module.scss";
import SPACE_BG_1 from "./background/space_bg_1.jpg";
import SPACE_BG_2 from "./background/space_bg_2.jpg";
import SPACE_BG_3 from "./background/space_bg_3.jpg";
import SPACE_BG_4 from "./background/space_bg_4.jpg";

export const COLOR_LIST = [
	"furniture-bench",
	"pastel-violet",
	"blue-contrast",
	"pastel-blue",
];
export const IMG_URL_LIST = [
	`url(${SPACE_BG_1})`,
	`url(${SPACE_BG_2})`,
	`url(${SPACE_BG_3})`,
	`url(${SPACE_BG_4})`,
];

const contentColorList: Color[] = [
	"furniture-bench",
	"pastel-violet",
	"blue-contrast",
	"pastel-blue",
	"border-subtle",
	"brown",
	"pastel-ginger",
	"text-tertiary",
];

type SpaceCardProps = {
	space: SimpleSpace;
	isPaidPlan?: boolean;
	isPreview?: boolean;
	isArchive?: boolean;
	isLocked?: boolean;
	onDelete?: (() => void) | ((space: SimpleSpace) => void);
	onArchive?: (() => void) | ((space: SimpleSpace) => void);
	onRestore?: (() => void) | ((space: SimpleSpace) => void);
	onClick?: MouseEventHandler<HTMLDivElement>;
};
export default function SpaceCard({
	space,
	isPaidPlan = true,
	isPreview = false,
	isArchive = false,
	isLocked = false,
	onDelete,
	onArchive,
	onRestore,
	onClick,
}: SpaceCardProps): JSX.Element {
	const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);
	const { isCurrentWorkspaceUserAdmin } = useCurrentWorkspaceUserHooks();
	const [themeMode] = useTheme();
	const { openSpaceSettings } = useCommonPanelState("spaceSettings");

	/** Renders the dropdown menu of the SimpleSpace card depending on its state */
	const menuOptions = (() => {
		if (space.is_archived) {
			if (!isCurrentWorkspaceUserAdmin) return [];

			return [
				{
					label: "Restore",
					action: () => onRestore?.(space),
				},
				{
					label: "Delete",
					action: () => onDelete?.(space),
				},
			] as const;
		}

		const opts = [];
		if (!isLocked) {
			opts.push({
				label: "Settings",
				action: () => openSpaceSettings({ id: space.id }),
			});
		}

		if (isCurrentWorkspaceUserAdmin) {
			opts.push(
				{
					label: "Archive",
					action: () => onArchive?.(space),
				},
				{
					label: "Delete",
					action: () => onDelete?.(space),
				}
			);
		}

		return opts;
	})();

	const renderIcon = () => {
		const icons: { [key in SharingOptions]: IconName } = {
			org: "building-o",
			personal: "profile",
			workspace: "group",
		};
		return isLocked ? "lock" : icons[space.sharing];
	};

	/** Renders the image or the pure color as the background depending on the given background code */
	const renderBackground = () => {
		const bg = space.background;
		if (bg < 4) {
			return {
				backgroundColor: ColorCssVarMap[COLOR_LIST[bg] as Color],
				opacity: 0.3,
			};
		} else {
			return {
				backgroundImage: IMG_URL_LIST[bg - 4],
				backgroundColor: "transparent",
				opacity: 0.7,
			};
		}
	};

	const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
		if (!isLocked && onClick) {
			onClick(e);
		}
	};

	return (
		<LayerSystemContainer
			onClick={handleClick}
			className={classNames(styles.container, {
				[styles.containerPreview]: isPreview,
				[styles.container__archived]: isArchive,
				[styles.container__focused]: isDropdownVisible && !isLocked,
				[styles.container__locked]: isLocked,
			})}
		>
			{menuOptions.length > 0 && !isPreview && (
				<div className={styles.option}>
					<DropDownWithItems
						type="kabob"
						visible={isDropdownVisible}
						onVisibleChange={setDropdownVisible}
						_afterClickHandler={() => setDropdownVisible(false)}
						menu={menuOptions}
					/>
				</div>
			)}
			<div className={styles.darkEffect} style={renderBackground()} />
			<div
				style={{
					backgroundColor: `${HexColorToRgbaText({
						color: contentColorList[space.background],
						themeMode,
						opacity: 0.2,
					})}`,
				}}
				className={styles.content}
			>
				<div className={styles.iconContainer}>
					<GenemodIcon name={renderIcon()} size="XLarge" />
				</div>
				<Typography ellipsis className={styles.spaceName}>
					{space.name}
				</Typography>
			</div>
		</LayerSystemContainer>
	);
}

export function SPACECARD_DEMO(): JSX.Element {
	const cardPlaceholders = [
		{
			id: 1,
			name: "Bench area",
			description: "",
			created_at: "",
			created_by: "",
			updated_at: "",
			updated_by: "",
			background: 4,
			is_archived: false,
			width: 9,
			height: 9,
			org: 1,
		},
		{
			id: 2,
			name: "3rd Floor West Hallway",
			description: "",
			created_at: "",
			created_by: "",
			updated_at: "",
			updated_by: "",
			background: 2,
			is_archived: true,
			width: 9,
			height: 8,
			org: 1,
		},
		{
			id: 3,
			name: "Office #1552",
			description: "",
			created_at: "",
			created_by: "",
			updated_at: "",
			updated_by: "",
			background: 5,
			is_archived: false,
			width: 9,
			height: 9,
			org: 1,
		},
	] as unknown as SimpleSpace[];
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Space cards"
					description="The first card is an active SpaceCard, the second one is in archive, and the third one is preview only"
				>
					<div
						style={{
							display: "grid",
							columnGap: 32,
							rowGap: 32,
							gridTemplateColumns: "1fr 1fr 1fr",
						}}
					>
						{cardPlaceholders.map((card) => {
							return (
								<SpaceCard
									key={card.id}
									space={card}
									isPreview={card.id === 3}
								/>
							);
						})}
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
