import { ColorCssVarMap } from "@common/styles/Colors";
import {
	Button,
	ButtonV2,
	DragAndDropGuidance,
	GenemodIcon,
	LayerSystemContainer,
	Modal,
	Select,
	Spin,
	Typography,
} from "@components";
import { useItemTypeNameMappingsFn } from "@helpers/Hooks";
import classNames from "classnames";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDropzone } from "react-dropzone";
import ItemTypeSelect from "../components/ItemTypeSelect/ItemTypeSelect";
import { getCellId, getColumnLabel, getRowLabel, parseCellId } from "../data";
import { useBoxView, useTableSelection } from "./BoxTableHooks";
import styles from "./Import.module.scss";

import { Column, Row } from "@common/components/InteractiveGrid/GridTypes";
import {
	Box,
	CURRENCY_DEFAULT_ID,
	CURRENCY_TYPES,
	CellCols,
	CellId,
	DEFAULT_ITEM_TYPE,
	Item,
	UUID,
} from "@common/types";
import { useLazyBoxQuery } from "@redux/inventory/Box";
import {
	useBulkItemsCreateMutation,
	useBulkItemsDeleteMutation,
	useItemTypesQuery,
} from "@redux/inventory/Item";
import { WorkSheet } from "xlsx";

/** Results from bulk import */
type ImportResultsType = {
	created?: Item[];
	failed?: Item[];
	existing?: Item[];
	failedRaw?: Item[];
};

// ========================= CONSTANTS and ERRORs ============================
const NAME_REQUIRED_ERROR = 'Please match a column to identifier "Name"';
const DUPLICATE_ERROR = "Duplicate identifier";
const EMPTY_NAME_ERROR = "Some rows are empty. All items must have a name";
// For drag and drop
const DND_ITEM = "ITEM";
const DO_NOT_UPLOAD = "DO_NOT_UPLOAD";
const SAVE_AS_NOTES = "SAVE_AS_NOTES";

const DEFAULT_ITEM = {
	id: -1,
	item_type: DEFAULT_ITEM_TYPE,
	concentration: "",
	volume: "",
	notes: "",
	tags: "",
	updated_at: "",
	type_data: {},
	custom_id: "",
	source: "",
	catalog: "",
	lot: "",
	packaging: "",
	price: "",
	reference: "",
	currency: CURRENCY_DEFAULT_ID,
};

const NO_FILE_POPUP_ERROR = {
	visible: true,
	title: "No file added",
	content: "Please add a Excel or CSV file before continuing",
	short: "Please add a file",
};
const NAME_REQUIRED_POPUP_ERROR = {
	visible: true,
	title: 'Identifier "Name" is unmatched',
	content: NAME_REQUIRED_ERROR,
};
const MAP_ALL_COLUMNS_POPUP_ERROR = {
	visible: true,
	title: "Unmatched columns",
	content:
		'Please match all the columns before continuing. To ignore a column, choose "Do not upload."',
};
const EMPTY_NAME_POPUP_ERROR = {
	visible: true,
	title: "Name is required",
	content:
		"One of the rows is missing a name. Check your Excel/CSV file for missing entries in the name column or use another column for name.",
};
// ======================================================================
type ErrorDataType = {
	visible?: boolean;
	title: string;
	content: string | JSX.Element;
	short?: string | JSX.Element;
};

type FileDataRowType = Record<string, string>;

type FileDataType = {
	filename: string;
	rows: FileDataRowType[];
};

type ColumnMapType = Record<string, string | UUID>;

type FieldMapType = Record<string | UUID, string>;

type BoxImportTypeProps = {
	/** Sets the importing screen */
	setImporting: (val: boolean) => void;
};

const SUPPORTED_DATE_FORMATS = [
	"MM-DD-YYYY",
	"DD-MM-YYYY",
	"YYYY-MM-DD",
] as const;
type DateFormat = (typeof SUPPORTED_DATE_FORMATS)[number];

/** Parent component for box item import. */
export default function BoxImport({ setImporting }: BoxImportTypeProps) {
	const { box, items } = useBoxView();
	const [createBulkItems] = useBulkItemsCreateMutation();
	const [deleteBulkItems] = useBulkItemsDeleteMutation();

	const [step, setStep] = useState(0);
	const { data: itemTypes } = useItemTypesQuery();
	const [itemType, setItemType] = useState<number>(
		itemTypes?.[0].id || DEFAULT_ITEM_TYPE
	);
	const itemTypeObj = itemTypes?.find((it) => it.id === itemType);
	const [fileData, setFileData] = useState<FileDataType | null>(null);
	const [error, setError] = useState<ErrorDataType | null>(null);
	// Used in parsing spreadsheet data for date fields
	const [dateFormat, setDateFormat] = useState<DateFormat>("MM-DD-YYYY");

	// Part 2
	const [columnMap, setColumnMap] = useState<ColumnMapType>({});
	const [initErrors, setInitErrors] = useState({});

	// lazy refetch of box
	const [fetchBox] = useLazyBoxQuery();

	// Reset column map if file data changes
	useEffect(() => {
		// Only map when user chooses file
		if (fileData && fileData.rows.length && step === 0) {
			// Map values if possible
			const itemFields = getItemTypeFields();
			// Visually appealing item columns: "Volume/Mass"
			const prettyItemColumns = Object.keys(itemFields).map(
				(x: any) => itemFields[x]
			);
			// Columns in the excel file
			const fileColumns = Object.keys(fileData.rows[0]);
			const tempErrors = {} as any;
			const tempMap = fileColumns.reduce((acc: any, col: string) => {
				const markAsError = () => {
					tempErrors[col] = {
						error: true,
					};
				};
				if (prettyItemColumns.includes(col)) {
					const fieldname = Object.keys(itemFields).filter(
						(x) => itemFields[x] === col
					)[0];
					const values = fileData.rows.map((r: any) => r[col]);
					if (
						fieldname !== "name" &&
						fieldname !== "added_at" &&
						fieldname !== "expiration_date"
					) {
						acc[col] = fieldname;
					} else if (
						fieldname === "name" &&
						allRowsPopulated(values)
					) {
						acc[col] = fieldname;
					} else if (
						(fieldname === "added_at" ||
							fieldname === "expiration_date") &&
						checkDateFormat(values, dateFormat)
					) {
						acc[col] = fieldname;
					} else {
						markAsError();
					}
				} else {
					markAsError();
				}
				return acc;
			}, {});
			setInitErrors(tempErrors);
			setColumnMap(tempMap);
		}
	}, [fileData, itemType, dateFormat]);

	// In case user goes backwards, clear error
	useEffect(() => {
		setError(null);
	}, [step]);

	const getItemTypeFields = () => {
		// Get data fields for corresponding item type
		const itemTypeFields = {} as any;
		if (itemTypeObj && itemTypeObj.schema.length) {
			const itemTypeName = itemTypeObj.name.toUpperCase();
			itemTypeObj.schema.forEach((field) => {
				itemTypeFields[field.uuid] = `${itemTypeName}.${field.label}`;
			});
		}
		const datafields = {
			name: "Item Name",
			concentration: "Concentration",
			volume: "Volume/Mass",
			added_at: "Date Added",
			expiration_date: "Expiration Date",
			...itemTypeFields,
			notes: "Notes",
			source: "Source",
			reference: "Reference",
			catalog: "Catalog #",
			lot: "Lot",
			packaging: "Packaging",
			price: "Price",
			location: "Location",
		};
		return datafields as any;
	};

	// Part 3
	const [newItems, setNewItems] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [results, setResults] = useState<ImportResultsType>({
		created: [],
		failed: [],
		existing: [],
		failedRaw: [],
	});
	const uploadItems = async () => {
		if (!box) return;
		setUploading(true);
		let itemData = newItems.map((item: any) => {
			// Convert dates to backend datetime format
			if (!item.added_at) {
				item.added_at = moment().format("YYYY-MM-DDThh:mm");
			} else {
				item.added_at = moment(item.added_at, dateFormat).format(
					"YYYY-MM-DDThh:mm"
				);
			}

			if (!item.expiration_date) {
				item.expiration_date = null;
			} else {
				item.expiration_date = moment(
					item.expiration_date,
					dateFormat
				).format("YYYY-MM-DD");
			}
			if (item.price) {
				// to check currency
				const currency = Object.entries(CURRENCY_TYPES).find(
					([_, value]) =>
						value === item.price[0] ||
						value === item.price.slice(0, 3)
				);
				if (currency) {
					item.currency = +currency[0];
				}
				// parsing only number and . from item.price field
				item.price = item.price.replace(/[^0-9.]/g, "");
			}

			return item;
		});

		if (itemTypeObj?.name !== "Default") {
			const typeFieldUuids =
				itemTypeObj?.schema?.map((field) => field.uuid) || [];

			itemData = itemData.map((item: any) => {
				item.item_type = itemType;
				item.type_data = typeFieldUuids.reduce(
					(acc: any, uuid: string) => {
						// Item type data will be on first level of item object
						acc[uuid] = item[uuid];
						return acc;
					},
					{}
				);

				return item;
			});
		}

		// Create a bulk API request
		try {
			const res = await createBulkItems({ items: itemData }).unwrap();
			const created = res.items;
			const existing = [...items];
			const results: Record<string, any> = {
				created,
				existing,
			};
			if (res.failed) {
				results["failed"] = [...res.failed];
				results["failedRaw"] = [...res.failed];
			}
			setResults(results);

			// We manually refetch the box data again after importing box items to get the latest information of the box contributors
			fetchBox(box.id);
		} catch (error) {
			setError({
				visible: true,
				title: "Bulk Item Creation Failed",
				content: "There was an error creating bulk items",
			});
		} finally {
			setTimeout(() => {
				setUploading(false);
				setImporting(false);
			}, 3000);
		}
	};

	// Evaluates if user can continue to next step. If not, sets popup error
	const onNextStep = () => {
		if (error) {
			setError({ ...error, visible: true });
			return;
		}

		// Perform checks
		if (step === 0) {
			if (fileData) {
				setStep(step + 1);
			} else {
				setError(NO_FILE_POPUP_ERROR);
			}
		} else if (step === 1) {
			if (
				!Object.keys(columnMap)
					.map((k) => columnMap[k])
					.includes("name")
			) {
				// Name column not mapped
				setError(NAME_REQUIRED_POPUP_ERROR);
			} else if (
				// Make sure all name rows are filled
				!allRowsPopulated(
					fileData?.rows.map(
						(x: FileDataRowType) =>
							x[
								Object.keys(columnMap).filter(
									(k: any) => columnMap[k] === "name"
								)[0]
							]
					) || []
				)
			) {
				// Name column not filled
				setError(EMPTY_NAME_POPUP_ERROR);
			} else if (
				Object.keys(columnMap).length !==
					Object.keys(fileData?.rows[0] || {}).length ||
				Object.keys(columnMap).reduce(
					(acc: any, key) => {
						// Already found a duplicate
						if (acc.containsDuplicates) {
							return acc;
						}

						// Checking if duplicate
						const fieldname = columnMap[key];
						const isSaveAsNotes = fieldname === SAVE_AS_NOTES;
						const isDoNotUpload = fieldname === DO_NOT_UPLOAD;
						if (!isSaveAsNotes && !isDoNotUpload) {
							if (acc.list.includes(fieldname)) {
								acc.containsDuplicates = true;
							} else {
								acc.list.push(fieldname);
							}
						}
						return acc;
					},
					{
						containsDuplicates: false,
						list: [],
					}
				).containsDuplicates
			) {
				// Check that all columns are mapped/no duplicates
				setError(MAP_ALL_COLUMNS_POPUP_ERROR);
			} else {
				setInitErrors({});
				setStep(step + 1);
			}
		} else if (step === 2) {
			uploadItems();
		}
	};

	// Reverting import
	const [revertModal, setRevertModal] = useState(false);
	const [isReverting, setReverting] = useState(false);

	// Actually reverts the upload
	const revertUpload = () => {
		if (!box) return;
		setReverting(true);
		// Delete all created items
		const toDelete = results?.created?.map((item) => item.id) || [];
		deleteBulkItems({ ids: toDelete })
			.unwrap()
			.then(() => {
				setReverting(false);
				setRevertModal(false);
				// Return to table
				setImporting(false);
			});
	};

	// To previous step
	const handleBack = () => {
		setStep(Math.max(0, step - 1));
	};
	const newCreatedRows = () => {
		if (!box) return [];
		// Available cells
		const existingCells = items.map((item) => getCellId(item));
		const emptyCells = Array(box.columns * box.rows)
			.fill(0)
			.map((_, i) => {
				const col = i % box.columns;
				const row = Math.floor(i / box.columns);
				return (String.fromCharCode(65 + col) + (row + 1)) as CellId;
			})
			.filter((x) => !existingCells.includes(x));

		// Assign imported items to a cell
		let fillIndex = 0;

		if (
			!emptyCells.length ||
			emptyCells.length < (fileData?.rows.length || 0)
		)
			return [];

		const rows =
			fileData?.rows.map((row: FileDataRowType) => {
				// Convert from excel/csv name into class fields
				const temp = {
					...DEFAULT_ITEM,
					...Object.keys(row).reduce((a: any, k) => {
						a[columnMap[k]] = row[k];
						return a;
					}, {}),
				} as Item;
				const notesJSON: any = {};
				Object.keys(row).forEach((k) => {
					if (columnMap[k] === "SAVE_AS_NOTES") {
						notesJSON[k] = row[k];
					}
				});
				const jsonString = JSON.stringify(notesJSON, null, 2);
				temp.notes = temp.notes + jsonString;

				// Assign an empty row and column
				const location = emptyCells[fillIndex++];
				temp.id = -1;
				temp.location = {
					id: -1,
					box_location: {
						id: -1,
						box: box.id,
						column: (location.charCodeAt(0) - 65) as Column,
						row: (parseInt(location.substring(1)) - 1) as Row,
					},
					item_group: null,
					freezer: box.location.freezer,
				};
				return temp as Item;
			}) || [];
		return rows;
	};

	const itemCount = items.length;

	const STEPS = !box
		? []
		: [
				{
					header: "Import items to Genemod",
					buttonText: "Continue to match",
					component: (
						<ImportStep1
							onFileDrop={setFileData}
							box={box}
							itemCount={itemCount}
							error={error}
							setError={setError}
							fileData={fileData}
							itemType={itemType}
							setItemType={setItemType}
							getItemTypeFields={getItemTypeFields}
							dateFormat={dateFormat}
							setDateFormat={setDateFormat}
						/>
					),
					backButtonText: "Cancel",
					onBack: () => setExitModal(true),
				},
				{
					header: "Match identifiers",
					buttonText: "Continue to organize",
					component: (
						<ImportStep2
							fileData={fileData}
							fieldMap={getItemTypeFields()}
							columnMap={columnMap}
							setColumnMap={setColumnMap}
							error={error}
							initErrors={initErrors}
							setError={setError}
							setInitErrors={setInitErrors}
							itemType={itemType}
							dateFormat={dateFormat}
						/>
					),
					backButtonText: "Back to import file",
					onBack: () => handleBack(),
				},
				{
					header: "Organize new items",
					buttonText: "Finish",
					component:
						step === 2 ? (
							<div
								className="p-12"
								style={{
									height: "calc(100vh - 144px)",
								}}
							>
								<ImportStep3
									newItems={newCreatedRows()}
									isNewItem={(x: Item) => x.id === -1}
									box={box}
									items={items}
									setNewItems={setNewItems}
								/>
							</div>
						) : null,
					backButtonText: "Back to match identifier",
					onBack: () => handleBack(),
				},
		  ];

	const [showExitModal, setExitModal] = useState(false);
	const exitImport = () => {
		setImporting(false);
	};

	const currentStep = STEPS[step];
	return (
		<div className={classNames(styles.boximport, styles.flex)}>
			<div className={classNames(styles.boximportheader)}>
				<Typography
					variant="label"
					className={classNames(styles.boximportexit)}
					color="text-secondary"
					onClick={currentStep.onBack}
				>
					<GenemodIcon
						name="chevron-left"
						style={{
							marginRight: "4px",
						}}
						stroke="text-primary"
					/>
					{currentStep.backButtonText}
				</Typography>
				<Typography className={styles.headerTitle} variant="title" bold>
					{currentStep.header}
				</Typography>
				<div className={classNames(styles.flex)}>
					<Typography style={{ marginRight: "24px" }}>
						Step {step + 1} of 3
					</Typography>
					<Button size="small" onClick={onNextStep}>
						{currentStep.buttonText}
					</Button>
				</div>
			</div>
			<LayerSystemContainer
				className={classNames(styles.boximportcontent, styles.flex, {
					[styles.organize]: step + 1 === 3,
					[styles.matchidentifiers]: step + 1 === 2,
				})}
			>
				{currentStep.component}
			</LayerSystemContainer>
			{error && (
				<Modal
					visible={error?.visible || false}
					closable
					okText="OK"
					hideCancelButton
					onCancel={() => setError({ ...error, visible: false })}
					onOk={() => setError({ ...error, visible: false })}
					title={error.title}
				>
					{error.content}
				</Modal>
			)}
			{uploading && (
				<Typography
					className={classNames(
						styles.uploadScreen,
						styles.flex,
						styles.flexcol
					)}
				>
					<Spin size="large"></Spin>
					<div style={{ marginTop: "8px" }}>Uploading...</div>
				</Typography>
			)}
			<Modal
				visible={revertModal}
				closable
				onCancel={() => setRevertModal(false)}
				onOk={revertUpload}
				title="Revert uploaded items?"
				okText="Revert and exit"
				okButtonProps={{
					loading: isReverting,
				}}
				destroyOnClose
			>
				<Typography>The uploaded items will be removed.</Typography>
			</Modal>
			<Modal
				closable={false}
				destroyOnClose
				visible={showExitModal}
				onCancel={() => setExitModal(false)}
				title="Cancel item import and exit to box?"
				cancelText="Continue importing"
				okText="Exit to box"
				cancelButtonProps={{
					onClick: () => setExitModal(false),
				}}
				okButtonProps={{
					onClick: exitImport,
				}}
				switchButtonsPos
			>
				<Typography>
					The upload is not completed. By exiting this page, you will
					lose the importing progress.
				</Typography>
			</Modal>
		</div>
	);
}

type Import1TypeProps = {
	/** Called when user drop or uploads a file. Provides the file name and first sheet in the workbook or csv */
	onFileDrop: (data: any) => void;
	/** Box object that the user is importing into */
	box: Box;
	/** Error data */
	error: any | null;
	/** Function for updating the error data */
	setError: (data: ErrorDataType | null) => void;
	/** Data from the file */
	fileData: FileDataType | null;
	/** Item type */
	itemType: number;
	/** Sets the Item type */
	setItemType: (data: number) => void;
	/** Returns a map of item type fields to display name */
	getItemTypeFields: () => void;
	dateFormat: DateFormat;
	setDateFormat: React.Dispatch<React.SetStateAction<DateFormat>>;
	itemCount: number;
};
/** Step 1 of bulk item import process */
function ImportStep1({
	onFileDrop,
	box,
	error,
	setError,
	fileData,
	itemType,
	setItemType,
	getItemTypeFields,
	dateFormat,
	setDateFormat,
	itemCount,
}: Import1TypeProps) {
	// XlSX is a large library, force code splitting by doing an import this way
	// https://reactjs.org/docs/code-splitting.html
	const xlsImport = import("xlsx");
	const itemTypeNameMappingsFn = useItemTypeNameMappingsFn();
	// File drop stuff
	const onDrop = (acceptedFiles: any) => {
		const file = acceptedFiles[0];
		const filename = file.name;

		resetPicker();
		const reader = new FileReader();
		reader.onload = (e: any) => {
			if (!reader.result) return;
			const data = e.target.result;
			xlsImport.then((XLSX) => {
				const workbook = XLSX.read(data, { type: "binary", raw: true });

				// Grab first worksheet only
				const key = Object.keys(workbook.Sheets)[0];
				let sheet = workbook.Sheets[key];
				sheet = XLSX.utils.sheet_to_json(sheet, {
					defval: "",
					raw: false,
				});

				const normalizedSheet = normalizeSheet(sheet);

				// Add the logic to filter out __EMPTY fields if necessary
				if (normalizedSheet.length > 0) {
					const firstRow = normalizedSheet[0];

					// Identify all fields that start with "__EMPTY" and are empty in the first row
					const emptyFields = Object.keys(firstRow).filter(
						(key) => key.startsWith("__EMPTY") && !firstRow[key]
					);

					// Filter out those fields from all rows
					normalizedSheet.forEach((row: { [x: string]: any }) => {
						emptyFields.forEach((field) => {
							delete row[field];
						});
					});
				}

				onFileDrop({
					filename,
					rows: normalizedSheet,
				});

				const boxCapacity = Math.max(box.rows * box.columns, 0);

				// Check if box has space for all items
				if (itemCount + normalizedSheet.length > boxCapacity) {
					setError({
						visible: true,
						title: "Too many items to import",
						content: (
							<div>
								<Typography>
									The file contains too many items for this
									box:
								</Typography>
								<Typography>
									Available space:{" "}
									{boxCapacity - box.item_count}
								</Typography>
								<Typography>
									Items in this file: {normalizedSheet.length}
								</Typography>
							</div>
						),
						short: (
							<>
								<Typography>
									Items exceeds box capacity.
								</Typography>
								<Typography>
									Please upload a new file
								</Typography>
							</>
						),
					});
				} else if (normalizedSheet.length === 0) {
					// Why did they upload an empty sheet :\
					setError({
						visible: true,
						title: "No items found in the file",
						content: (
							<Typography>
								The file is empty. If you are not sure how to
								prepare a file, download the provided template
								to start.
							</Typography>
						),
						short: "This file has 0 items. Please upload a new file",
					});
				}
			});
		};
		if (filename.endsWith(".csv")) {
			reader.readAsText(file);
		} else {
			reader.readAsBinaryString(file);
		}
	};

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		accept: { text: [".csv", ".xlsx"] },
		noClick: true,
		noKeyboard: true,
		multiple: false,
	});

	const resetPicker = () => {
		onFileDrop(null);
		setError(null);
	};

	// Generates and downloads a csv with item type field names as headers
	const generateCSV = () => {
		xlsImport.then((XLSX) => {
			const fields = getItemTypeFields() as any;
			const datafields = Object.keys(fields).reduce((acc: any, key) => {
				acc[fields[key]] = "";
				return acc;
			}, {});
			// Generate worksheets from datafields
			const worksheet = XLSX.utils.json_to_sheet([datafields]);
			const csv = XLSX.utils.sheet_to_csv(worksheet);
			saveCSV(
				"Genemod_" + itemTypeNameMappingsFn(itemType) + "_template",
				csv
			);
		});
	};

	return (
		<div className={styles.importstep1}>
			<Typography variant="subheadline" style={{ marginBottom: 12 }} bold>
				1. Choose an item type to upload
			</Typography>
			<ItemTypeSelect
				value={itemType}
				onSelect={(x) => setItemType(x)}
				style={{ width: 260, marginBottom: 32 }}
			/>
			<Typography variant="subheadline" style={{ marginBottom: 8 }} bold>
				2. Format your data based on the template
			</Typography>
			<Button
				type="link"
				onClick={generateCSV}
				style={{ transform: "translateX(-4px)", width: "fit-content" }}
			>
				Download <b>{itemTypeNameMappingsFn(itemType)}</b> file template
			</Button>
			<div style={{ marginTop: 36 }}>
				<Typography
					variant="subheadline"
					style={{ marginBottom: 12 }}
					bold
				>
					3. Upload your Excel or CSV file
				</Typography>
				<div
					className={classNames(styles.flex, styles.importdropzone)}
					{...getRootProps({
						style: {
							outline: "none",
							borderColor: error ? "var(--red)" : "",
							overflow: "hidden",
							flexDirection: "column",
						},
					})}
				>
					<input {...getInputProps()} />
					{fileData ? (
						<div style={{ width: "100%" }}>
							<Typography
								variant="label"
								className={classNames(
									styles.flex,
									styles.importfilename
								)}
								style={{ alignItems: "center" }}
							>
								<GenemodIcon
									name="file"
									decorative
									style={{ marginRight: 4 }}
								/>
								<Typography
									style={{
										maxWidth: "calc(100% - 26px - 22px)",
									}}
								>
									{fileData.filename}
								</Typography>
								<GenemodIcon
									name="cancel"
									style={{
										marginLeft: 8,
									}}
									onClick={resetPicker}
								/>
							</Typography>
						</div>
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Typography>Drop your file here or</Typography>
							<br />
							<Button size="small" type="ghost" onClick={open}>
								Choose a file
							</Button>
						</div>
					)}
					{error && (
						<Typography
							color="red"
							style={{
								textAlign: "center",
								marginTop: "8px",
							}}
						>
							{error.short}
						</Typography>
					)}
				</div>
				<div style={{ marginTop: 32, width: 260 }}>
					<Typography bold>(Optional) Date Format</Typography>
					<Select
						value={dateFormat}
						onChange={(value) => setDateFormat(value as DateFormat)}
					>
						{SUPPORTED_DATE_FORMATS.map((format) => {
							return (
								<Select.Option key={format}>
									{format}
								</Select.Option>
							);
						})}
					</Select>
				</div>
			</div>
		</div>
	);
}

type ImportStep2TypeProps = {
	/** Object containing filename and csv sheet */
	fileData: any;
	/** Auto-generated mapping from item field name to display name */
	fieldMap: any;
	/** User selected column mapping. {excel_column: fieldname} */
	columnMap: ColumnMapType;
	/** Sets the column map */
	setColumnMap: (map: ColumnMapType) => void;
	/** Parent error object */
	error: any;
	/** Sets the parent error object */
	setError: (err: any | null) => void;
	/** Initial errors object */
	initErrors: any;
	/** Sets the initial errors */
	setInitErrors: (err: any) => void;
	/** Item type id */
	itemType: number;
	dateFormat: DateFormat;
};
/**
 * Step 2 of the bulk import process where users
 * match their excel/csv columns to item fields
 */
function ImportStep2({
	fileData,
	fieldMap,
	columnMap,
	setColumnMap,
	error,
	initErrors,
	itemType,
	dateFormat,
	setError,
}: ImportStep2TypeProps): JSX.Element {
	// Assuming that there is more than 1 row in the data
	const numItems = fileData.rows.length;
	const numColumns = Object.keys(fileData.rows[0]).length;
	const [errors, setErrors] = useState<any | null>(initErrors);
	const [popupError, setPopupError] = useState<any>(null);

	/**
	 * onChange handler for mapping spreadsheet columns to Item columns.
	 *
	 * Evaluates every column mapping for errors:
	 * - Name field must be mapped
	 * - Name field must be populated for all spreadsheet rows
	 * - Multiple columns mapping to the same field
	 * - Date format errors
	 */
	// Map columns to fields and handle errors
	const handleMapping = (col: any, fieldname: string) => {
		// Failed auto-mappings from spreadsheet column to item fields
		// will be reported in initErrors.
		const newErrors = {};
		const newMapping: ColumnMapType = {
			...columnMap,
			[col]: fieldname,
		};

		// ================= Missing Mapping Errors =============================
		const noMappingErrors: Record<string, { error: string }> = {};
		Object.keys(fileData.rows[0]).forEach((column: string) => {
			if (!(column in newMapping)) {
				noMappingErrors[column] = {
					error: "Select an option.",
				};
			}
		});

		// ================= Duplicate Mapping Errors =============================
		// Mapping from Item field to boolean. True if the field is used
		// more than once. False if the field is mapped but there are no duplicates.
		const duplicateFields = Object.values(newMapping)
			.filter((field) => field !== DO_NOT_UPLOAD)
			.reduce<Record<string, boolean>>((acc, field) => {
				acc[field] = field in acc;
				return acc;
			}, {});

		// Generate an error object for each duplicate mapping.
		const duplicateMappingErrors = Object.entries(newMapping)
			.filter(([, field]) => duplicateFields[field])
			.reduce<Record<string, { error: string }>>((acc, [column]) => {
				acc[column] = {
					error: DUPLICATE_ERROR,
				};
				return acc;
			}, {});

		// ================= Rows w/ Empty Name =============================
		// Ignore this validation if there are duplicate name field mappings.
		// Those should be resolved first by the user.
		const emptyNameErrors: Record<string, { error: string }> = {};
		if ("name" in duplicateFields && !duplicateFields["name"]) {
			const nameMapping = Object.entries(newMapping).find(
				([, field]) => field === "name"
			);
			if (
				nameMapping &&
				!allRowsPopulated(
					fileData.rows.map(
						(x: Record<string, string>) => x[nameMapping[0]]
					)
				)
			) {
				emptyNameErrors[nameMapping[0]] = { error: EMPTY_NAME_ERROR };
			}
		}

		// ================= Date Format Errors =============================
		// Evaluate formatting for expiration date and created at
		const dateFormatErrors: Record<string, { error: boolean }> = {};
		const dateFields = ["expiration_date", "added_at"];
		const dateMappings = Object.entries(newMapping).filter(([, field]) =>
			dateFields.includes(field)
		);
		let containsInvalidDate = false;
		dateMappings.forEach(([column]) => {
			if (
				!checkDateFormat(
					fileData.rows.map(
						(row: Record<string, string>) => row[column]
					),
					dateFormat
				)
			) {
				containsInvalidDate = true;
				dateFormatErrors[column] = { error: true };
				// Delete the mapping so the user cannot proceed
				delete newMapping[column];
			}
		});

		// =======================================================================
		Object.assign(newErrors, noMappingErrors);
		Object.assign(newErrors, emptyNameErrors);
		Object.assign(newErrors, dateFormatErrors);
		Object.assign(newErrors, duplicateMappingErrors);

		if (containsInvalidDate) {
			setPopupError({
				visible: true,
				title: "Unrecognized date format",
				content: `The date format for columns “created on” and “expires on” must be in the format: ${dateFormat}.`,
			});
		}
		setErrors(newErrors);
		setColumnMap(newMapping);

		if (Object.keys(newErrors).length === 0) {
			setError(null);
		}
	};

	const handleDoNotUpload = () => {
		const newErrors: any = {};
		const newMapping: ColumnMapType = { ...columnMap };

		Object.keys(fileData.rows[0]).forEach((column) => {
			if (errors[column]) {
				newMapping[column] = DO_NOT_UPLOAD;
				delete newErrors[column];
			}
		});

		setColumnMap(newMapping);
		setErrors(newErrors);

		if (Object.keys(newErrors).length === 0) {
			setError(null);
		}
	};

	const handleSaveAsNotes = () => {
		const newErrors: any = {};
		const newMapping: ColumnMapType = { ...columnMap };

		Object.keys(fileData.rows[0]).forEach((column) => {
			if (errors[column]) {
				newMapping[column] = SAVE_AS_NOTES;
				delete newErrors[column];
			}
		});

		setColumnMap(newMapping);
		setErrors(newErrors);

		if (Object.keys(newErrors).length === 0) {
			setError(null);
		}
	};

	const scrollToError = () => {
		const errDivs = document.getElementsByClassName(
			styles.columnidentifier__error
		);
		if (errDivs.length) {
			errDivs[0].scrollIntoView();
		}
	};

	// # of rows needing to be matched
	const diff =
		Object.keys(fileData.rows[0]).length -
		Object.keys(columnMap).length +
		Object.keys(errors).filter(
			(x) =>
				errors[x].error === EMPTY_NAME_ERROR ||
				errors[x].error === DUPLICATE_ERROR
		).length;

	// Returns the JSX for the status text above the identifiers
	const getStatusText = () => {
		let contents = "" as string | JSX.Element;
		const missingName = !Object.keys(columnMap)
			.map((x) => columnMap[x])
			.includes("name");

		if (missingName) {
			// Name mapping required
			contents = "Please match a column to identifier “Name”";
		} else if (diff > 0) {
			// Unmatched columns
			contents = (
				<div className="flex gap-4">
					<Typography className={styles.flex} color="red-contrast">
						{`${diff} ${
							diff === 1 ? "column does not " : "columns do not "
						} match`}{" "}
						<GenemodIcon
							name="double-arrow-right"
							style={{ marginLeft: 4 }}
							onClick={scrollToError}
							stroke="red"
						/>
					</Typography>
					<ButtonV2 type="link" onClick={handleDoNotUpload}>
						Do not upload unmatched
					</ButtonV2>
					<ButtonV2 type="link" onClick={handleSaveAsNotes}>
						Save Unmatched as Notes
					</ButtonV2>
				</div>
			);
		} else {
			// No more errors
			contents = (
				<div className={styles.flex}>
					All columns are matched{" "}
					<GenemodIcon
						name="checkmark"
						decorative
						fill="blue"
						style={{ marginLeft: 8 }}
					/>
				</div>
			);
		}

		return (
			<div className={classNames(styles.flex, styles.statusText)}>
				<Typography>{contents}</Typography>
				<Typography style={{ marginRight: 16 }}>
					{numColumns} column{numColumns > 1 && "s"}, {numItems} item
					{numItems > 1 && "s"} detected
				</Typography>
			</div>
		);
	};

	return (
		<div
			className={classNames(
				styles.flex,
				styles.flexcol,
				styles.importstep2
			)}
		>
			{getStatusText()}
			<div
				className={classNames(
					styles.flexcol,
					styles.flex,
					styles.identifiers
				)}
			>
				{Object.keys(fileData.rows[0]).map((column) => {
					let err = "" as any;
					if (errors[column]) {
						err = errors[column].error;
					} else if (
						error &&
						error.title === MAP_ALL_COLUMNS_POPUP_ERROR.title &&
						columnMap[column] === undefined
					) {
						err = true;
					}
					return (
						<ColumnIdentifier
							key={column}
							columnName={column}
							example={fileData.rows[0][column]}
							options={fieldMap}
							identifier={columnMap[column]}
							onChange={(fieldname: string) =>
								handleMapping(column, fieldname)
							}
							error={err}
							itemType={itemType}
						/>
					);
				})}
			</div>
			{popupError && (
				<Modal
					visible={popupError.visible}
					closable
					okText="OK"
					hideCancelButton
					onCancel={() => setPopupError(null)}
					onOk={() => setPopupError(null)}
					title={popupError.title}
				>
					{popupError.content}
				</Modal>
			)}
		</div>
	);
}

type ColumnIdentifierTypeProps = {
	/** Name of the column to match */
	columnName: string;
	/** Shown under the column name */
	example: string;
	/** Item field name. null = Do not upload */
	identifier: string;
	/** Fieldname to display name map for the selected item type */
	options: FieldMapType;
	/** Called when user changes the identifier */
	onChange: (fieldName: string) => void;
	/** Error message or boolean */
	error: string | boolean;
	/** Item type id */
	itemType: number;
};
/**
 * Used in step 2 of the bulk import process
 * Allows user to match an excel/csv column (left) to an item
 * field (right)
 */
function ColumnIdentifier({
	columnName,
	example,
	identifier,
	options,
	onChange,
	error,
	itemType,
}: ColumnIdentifierTypeProps): JSX.Element {
	return (
		<Typography
			className={classNames(styles.columnidentifier, {
				[styles.columnidentifier__error]: !!error,
				[styles.columnidentifier__noUpload]:
					identifier === DO_NOT_UPLOAD,
			})}
		>
			<div>
				<Typography variant="body">{columnName}</Typography>
				<Typography
					style={{
						marginTop: "4px",
					}}
					variant="caption"
				>
					{example}
				</Typography>
			</div>
			<div
				className={styles.flex}
				style={{
					width: "50%",
					justifyContent: "flex-end",
					height: "100%",
					position: "relative",
				}}
			>
				<Typography
					variant="label"
					color={
						!error || !identifier
							? "text-secondary"
							: "red-contrast"
					}
					style={{
						marginRight: "32px",
						padding: "0 8px",
					}}
				>
					{!error || !identifier ? "Identifier" : error}
				</Typography>
				<Select
					className={styles.selectIdentifier}
					value={identifier}
					style={{ width: 224 }}
					placeholder="Choose an option"
					onChange={(val) => onChange(val as string)}
					isInput
					error={!!error}
				>
					<Select.Option
						value={DO_NOT_UPLOAD}
						style={{
							borderBottom: "1px solid var(--border-subtle)",
						}}
					>
						Do not upload
					</Select.Option>
					<Select.Option
						value={SAVE_AS_NOTES}
						style={{
							borderBottom: "1px solid var(--border-subtle)",
						}}
					>
						Save as notes
					</Select.Option>
					{Object.keys(options).map((option) => {
						const borderBottom =
							option === "expiration_date" || option === "notes"
								? {
										borderBottom:
											"1px solid var(--border-subtle)",
								  }
								: {};
						return (
							<Select.Option
								key={option}
								value={option}
								style={borderBottom}
							>
								{options[option]}
							</Select.Option>
						);
					})}
				</Select>
			</div>
		</Typography>
	);
}

const tableResize = () => {
	const newTableSize = window.innerWidth * 0.5;
	if (newTableSize > 1261) return 1261;
	if (newTableSize < 696) return 696;
	return newTableSize;
};

/**
 * Calculates the column and row difference between two cells
 * @function
 * @param {string} start Starting cell
 * @param {string} end Ending cell
 * @returns Object containing dColumn and dRow
 */
function calculateDifference(start: string, end: string) {
	const dColumn = end.charCodeAt(0) - start.charCodeAt(0);
	const dRow = parseInt(end.substring(1)) - parseInt(start.substring(1));
	return {
		dColumn,
		dRow,
	};
}

type ImportStep3TypeProps = {
	/** New Items */
	newItems: Item[];
	/** Parent box object */
	box: Box;
	/** Array of Item objects in the box */
	items: Item[];
	/** Sets the items to be imported */
	setNewItems: (items: any) => void;
	isNewItem: (item: Item) => boolean;
	guidenceLabel?: string;
};
/** Step 3 of bulk import process. Rearranging items */
export function ImportStep3({
	newItems,
	box,
	items,
	isNewItem,
	setNewItems,
	guidenceLabel,
}: ImportStep3TypeProps): JSX.Element {
	// TODO Ignore dragging when user holds ctrl?
	const [error, setError] = useState<ErrorDataType | null>(null);
	const [tempItems, setTempItems] = useState<Item[]>([]);
	const [tableWidth, setTableWidth] = useState(tableResize());

	useEffect(() => {
		const handleResize = () => {
			setTableWidth(tableResize());
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	// Fill in available cells with new items
	useEffect(() => {
		setTempItems([...newItems, ...items]);
	}, []);

	// Update parent component items
	useEffect(() => {
		if (!tempItems) return;
		setNewItems(tempItems?.filter((x) => isNewItem(x)));
	}, [tempItems]);

	const [isDragging, setDragging] = useState(false);
	const { selectedCells, tempCells, setSelection, cellProps } =
		useTableSelection({
			filterSelection: (selection: any) => {
				// Only allow cells with items from import to be selected
				const validCells = tempItems
					?.filter((x) => isNewItem(x))
					.map((i) => getCellId(i));
				return selection.filter((x: any) => validCells?.includes(x));
			},
			ignoreSelection: isDragging,
		});

	// Drag and drop rendering
	const [hoveredCell, setHoveredCell] = useState("");
	const [draggedCell, setDraggedCell] = useState("");
	const [dropzone, setDropzone] = useState({
		valid: false,
		cells: [] as any[],
	});
	useEffect(() => {
		// Calculate whether drop zones are valid and
		// which cells are in drop zone
		if (isDragging) {
			const { dColumn: dCol, dRow } = calculateDifference(
				draggedCell,
				hoveredCell
			);
			const dropCells = selectedCells.map((cell: any) => {
				const c = String.fromCharCode(cell.charCodeAt(0) + dCol);
				const r = parseInt(cell.substring(1)) + dRow;
				return c + r;
			});

			const existingCells = items.map((i) => getCellId(i));
			const validDrop = dropCells.reduce((acc: any, cell: any) => {
				if (!acc) {
					return false;
				}

				// Check the cell is within range of box dimensions
				const col = cell.charCodeAt(0) - 65;
				const row = parseInt(cell.substring(1)) - 1;
				const inRange =
					col >= 0 && col < box.columns && row >= 0 && row < box.rows;

				// Check if cell is over an existing item
				const targetDroppable = !existingCells.includes(cell);
				return targetDroppable && inRange;
			}, true);
			setDropzone({
				valid: validDrop,
				cells: dropCells,
			});
		}
	}, [hoveredCell]);

	const onDrop = (targetCell: any) => {
		if (dropzone.valid) {
			const { dColumn, dRow } = calculateDifference(
				draggedCell,
				targetCell
			);
			const toSwap = tempItems
				?.filter(
					(x) => isNewItem(x) && dropzone.cells.includes(getCellId(x))
				)
				.map((i) => getCellId(i));

			const newSelection = [] as any[];
			const temp = tempItems.map((item) => {
				const cell = getCellId(item);
				if (!item.location?.box_location) return item;
				if (selectedCells.includes(cell)) {
					// Forward translation of selected cells -> dropzone
					item.location.box_location.column = ((item.location
						.box_location.column || 0) + dColumn) as Column;
					item.location.box_location.row = (item.location.box_location
						.row + dRow) as Row;
					newSelection.push(cell);
				} else if (toSwap.includes(cell)) {
					// Backwards translation for items in the dropzone -> selected cell
					item.location.box_location.column = (item.location
						.box_location.column - dColumn) as Column;
					item.location.box_location.row = (item.location.box_location
						.row - dRow) as Row;
				}
				return item;
			});
			setTempItems(temp);
			setSelection(newSelection);
		} else {
			// Check which error to display
			const existingItems = tempItems
				.filter((x) => x.id !== -1)
				.map((i) => getCellId(i));
			const overlap = dropzone.cells.reduce(
				(acc, val) => (acc ? true : existingItems.includes(val)),
				false
			);
			if (overlap) {
				setError({
					title: "Items already exist",
					content: "There are existing items in this location.",
				});
			} else {
				setError({
					title: "Not enough space",
					content:
						"There is not enough space to place your items here.",
				});
			}
		}
	};

	// Reset dropzone for new drags
	useEffect(() => {
		setDropzone({
			valid: false,
			cells: [],
		});
	}, [isDragging]);

	// Renders all the table components
	const renderTable = () => {
		const jsx = [] as JSX.Element[];
		const width = `calc(calc(100% - 24px) / ${box.columns})`;
		const height = `calc(calc(100% - 24px) / ${box.rows})`;

		// Create jsx for each cell/header
		for (let row = 0; row <= box.rows; row++) {
			for (let col = 0; col <= box.columns; col++) {
				if (row === 0 && col === 0) {
					// Corner piece :)
					jsx.push(<div className={styles.corner} />);
				} else if (row === 0) {
					// Column headers
					jsx.push(
						<TableHeader width={width}>
							{getColumnLabel(col, box?.axis_direction)}
						</TableHeader>
					);
				} else if (col === 0) {
					// Row headers
					jsx.push(
						<TableHeader isRow height={height}>
							{getRowLabel(row, box?.axis_direction)}
						</TableHeader>
					);
				} else {
					// Cells
					const columnLabel = String.fromCharCode(col + 64);
					const cellID = columnLabel + row;
					const temp = tempItems.filter((x) => {
						return getCellId(x) === cellID;
					});
					let item = null;
					let selected = false;
					if (temp.length) {
						item = temp[0];
						// Only selected if non-empty
						selected = tempCells.includes(cellID);
					}
					jsx.push(
						<TableCell
							height={height}
							width={width}
							cellID={cellID}
							item={item}
							selected={selected}
							selectedCells={selectedCells}
							setDragging={setDragging}
							hoveredCell={hoveredCell}
							setHoveredCell={setHoveredCell}
							setDraggedCell={setDraggedCell}
							dropzone={dropzone}
							onDrop={onDrop}
							cellProps={cellProps}
							isNewItem={isNewItem}
						/>
					);
				}
			}
		}

		return jsx;
	};

	return (
		<div
			className={classNames(
				styles.flex,
				styles.flexcol,
				styles.importstep3,
				"items-start"
			)}
		>
			<div className={classNames(styles.flex, styles.rearrangeContent)}>
				<div
					className={classNames(styles.flex, styles.table)}
					style={{ width: tableWidth }}
				>
					{renderTable()}
				</div>
				<DragAndDropGuidance
					activeLabel={guidenceLabel || "Imported items"}
					element="items"
					shape="square"
					allowMultipleItems
				/>
			</div>
			{error && (
				<Modal
					visible
					closable
					okText="OK"
					hideCancelButton
					onCancel={() => setError(null)}
					onOk={() => setError(null)}
					title={error.title}
				>
					{error.content}
				</Modal>
			)}
		</div>
	);
}

type TableHeaderTypeProps = {
	/** If true, styled as a row header */
	isRow?: boolean;
	width?: number | string;
	height?: number | string;
	children: JSX.Element | string | number;
};
/** JSX for both row and column table headers */
function TableHeader({
	isRow,
	width,
	height,
	children,
}: TableHeaderTypeProps): JSX.Element {
	return (
		<div
			className={classNames(
				isRow ? styles.rowHeader : styles.colHeader,
				styles.flex
			)}
			style={{
				height: height || "",
				width: width || "",
			}}
		>
			{children}
		</div>
	);
}

type DropZoneType = {
	valid: boolean;
	cells: string[];
};
/**
 * Information on the current dropzone
 * @typedef {object} Dropzone
 * @prop {string[]} cells Calculated array of dropzone cells
 * @prop {boolean} valid Whether the drop is valid with the given dropzone
 */
type TableCellTypeProps = {
	height: string | number;
	width: string | number;
	/** Location of cell on the table */
	cellID: string;
	/** Item object for this cell. Null if none */
	item: Item | null;
	/** True if cell is part of the table selection */
	selected: boolean;
	/** List of actually selected cells */
	selectedCells: string[];
	/** Sets the dragging state */
	setDragging: (dragging: boolean) => void;
	/** Cell that is being hovered over during drag and drop */
	hoveredCell: string;
	/** Sets the hovered cell */
	setHoveredCell: (cell: string) => void;
	/** Sets the cell that is being dragged */
	setDraggedCell: (cell: string) => void;
	/** Information on the dropzone */
	dropzone: DropZoneType;
	/** Called on valid drops */
	onDrop: (targetCell: string) => void;
	cellProps:
		| {
				onMouseDown?: undefined;
				onMouseEnter?: undefined;
		  }
		| {
				onMouseDown: (e: any) => void;
				onMouseEnter: (e: any) => void;
		  };
	isNewItem: (item: Item) => boolean;
};
/** JSX for table cells */
function TableCell({
	height,
	width,
	item,
	cellID,
	selected,
	selectedCells,
	setDragging,
	hoveredCell,
	setHoveredCell,
	setDraggedCell,
	dropzone,
	onDrop,
	cellProps,
	isNewItem,
}: TableCellTypeProps): JSX.Element {
	const cellRef = useRef<HTMLDivElement>(null);
	const [{ isDragging }, drag] = useDrag({
		item: {
			cellID,
			type: DND_ITEM,
			item,
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		canDrag() {
			return selectedCells.includes(cellID);
		},
		isDragging() {
			return selectedCells.includes(cellID);
		},
		begin() {
			setDragging(true);
			setHoveredCell(cellID);
			setDraggedCell(cellID);
		},
		end() {
			setDragging(false);
		},
	});

	const cellData = parseCellId(cellID as CellId);

	const row = cellData.row + 1;

	const adjacentCells = [
		`${CellCols[cellData.column - 1]}${row}`,
		`${CellCols[cellData.column + 1]}${row}`,
		`${CellCols[cellData.column]}${row - 1}`,
		`${CellCols[cellData.column]}${row + 1}`,
	];

	const leftAdjacentCell = adjacentCells[0];
	const rightAdjacentCell = adjacentCells[1];
	const topAdjacentCell = adjacentCells[2];
	const bottomAdjacentCell = adjacentCells[3];

	const hasLeftAdjacentCell = selectedCells.includes(leftAdjacentCell);
	const hasRightAdjacentCell = selectedCells.includes(rightAdjacentCell);
	const hasTopAdjacentCell = selectedCells.includes(topAdjacentCell);
	const hasBottomAdjacentCell = selectedCells.includes(bottomAdjacentCell);

	const hasAdjacentCell =
		hasLeftAdjacentCell ||
		hasRightAdjacentCell ||
		hasTopAdjacentCell ||
		hasBottomAdjacentCell;

	const [, drop] = useDrop({
		accept: DND_ITEM,
		canDrop(item: Item | any) {
			return !item.item || isNewItem(item.item);
		},
		hover() {
			if (hoveredCell !== cellID) {
				setHoveredCell(cellID);
			}
		},
		drop() {
			onDrop(cellID);
		},
	});

	let name = "";
	if (item && !isDragging) {
		name = item.name || "";
	}
	const disabled = item && !isNewItem(item);
	const placeholder = item && isNewItem(item) && !isDragging;
	drag(drop(cellRef));

	return (
		<div
			className={classNames(styles.cell, {
				[styles.cell__disabled]: disabled,
				[styles.cell__placeholder]: placeholder,
				[styles.cell__placeholder__selected]: selected && !isDragging,
				[styles.cell__placeholder__left_adjacent]:
					selected && !isDragging && hasLeftAdjacentCell,
				[styles.cell__placeholder__right_adjacent]:
					selected && !isDragging && hasRightAdjacentCell,
				[styles.cell__placeholder__top_adjacent]:
					selected && !isDragging && hasTopAdjacentCell,
				[styles.cell__placeholder__bottom_adjacent]:
					selected && !isDragging && hasBottomAdjacentCell,
				[styles.cell__droppable]:
					dropzone.valid && dropzone.cells.includes(cellID),
				[styles.cell__undroppable]:
					!dropzone.valid && dropzone.cells.includes(cellID),
			})}
			style={{
				height: height || "",
				width: width || "",
			}}
			{...cellProps}
			ref={cellRef}
		>
			<Typography
				variant="caption"
				className={styles.cellBody}
				id={cellID}
			>
				{name}
			</Typography>
		</div>
	);
}

type ImportSummaryTypeProps = {
	box: Box;
	/** Results from upload */
	results: ImportResultsType;
	/** Map of csv fields to item fields */
	columnMap: ColumnMapType;
};
/** Summary of import. If any failures, allows user to download failed items */
function ImportSummary({
	box,
	results,
	columnMap,
}: ImportSummaryTypeProps): JSX.Element {
	const renderTable = () => {
		const jsx = [];
		const width = `calc(calc(100% - 24px) / ${box.columns})`;
		const height = `calc(calc(100% - 24px) / ${box.rows})`;

		// Create jsx for each cell/header
		for (let row = 0; row <= box.rows; row++) {
			for (let col = 0; col <= box.columns; col++) {
				const columnLabel = String.fromCharCode(col + 64);
				if (row === 0 && col === 0) {
					// Corner piece :)
					jsx.push(<div className={styles.corner} />);
				} else if (row === 0) {
					// Column headers
					jsx.push(
						<TableHeader width={width}>{columnLabel}</TableHeader>
					);
				} else if (col === 0) {
					// Row headers
					jsx.push(
						<TableHeader isRow height={height}>
							{row}
						</TableHeader>
					);
				} else {
					const cellID = columnLabel + row;
					const cellFilter = (item: Item) =>
						getCellId(item) === cellID;
					const existing = results?.existing?.filter(cellFilter);
					const created = results?.created?.filter(cellFilter);
					const failed = results?.failed?.filter(cellFilter);
					let item = null;
					let isNew = true;
					let failedCreation = false;

					if (existing?.length) {
						item = existing[0];
						isNew = false;
					} else if (created?.length) {
						item = created[0];
					} else if (failed?.length) {
						item = failed[0];
						failedCreation = true;
					}
					jsx.push(
						<SummaryCell
							item={item}
							isNew={isNew}
							failed={failedCreation}
							height={height}
							width={width}
						/>
					);
				}
			}
		}
		return jsx;
	};

	const generateFailedCSV = () => {
		// Reverse item fieldname to original column name
		const originalRows = results?.failedRaw?.map((data: any) => {
			// Spread nested fields into first level
			const temp = { ...data, ...data.type_data };
			delete temp.type_data;

			// Converting from our field into original
			return Object.keys(columnMap).reduce((acc: any, field) => {
				acc[field] = temp[columnMap[field]] || "";
				return acc;
			}, {});
		}) as any;

		// Generate worksheet with header
		// XlSX is a large library, force code splitting by doing an import this way
		// https://reactjs.org/docs/code-splitting.html
		import("xlsx").then((XLSX) => {
			const worksheet = XLSX.utils.json_to_sheet(originalRows);
			const csv = XLSX.utils.sheet_to_csv(worksheet);
			const timestamp = new Date().getTime();
			saveCSV(`failed_items_${timestamp}`, csv);
		});
	};

	const createdTotal = results?.created?.length || 0;
	const failedTotal = results?.failed?.length || 0;

	return (
		<div
			className={classNames(
				styles.flex,
				styles.flexcol,
				styles.importstep3
			)}
		>
			<div
				className={classNames(styles.flex, styles.rearrangeContent)}
				style={{ marginTop: "28px" }}
			>
				<div
					className={classNames(styles.flex, styles.table)}
					style={{ paddingRight: "12px" }}
				>
					{renderTable()}
				</div>
				<div
					className={classNames(
						styles.flex,
						styles.flexcol,
						styles.fullWidthChildren
					)}
					style={{ paddingLeft: "12px", overflowY: "auto" }}
				>
					<Typography
						variant="headline"
						style={{ marginBottom: "4px" }}
					>
						Uploaded
					</Typography>
					<Typography>
						<>
							{
								Object.keys(columnMap).filter(
									(x) => !!columnMap[x]
								).length
							}{" "}
							of {Object.keys(columnMap).length} columns
							<br />
							{createdTotal} of{" "}
							{`${createdTotal + failedTotal + " items"}`}
						</>
					</Typography>
					{!!results?.failed?.length && (
						<>
							<Typography
								variant="subheadline"
								color="red"
								style={{
									marginBottom: "8px",
								}}
							>
								{results?.failed?.length} item(s) could not be
								uploaded
							</Typography>
							<div
								className={styles.flex}
								style={{ marginBottom: "16px" }}
							>
								<div
									className={styles.colorBox}
									style={{
										backgroundColor: ColorCssVarMap.red,
									}}
								/>
								<Typography
									color="red"
									style={{ marginLeft: 8 }}
								>
									An item was created in this space during the
									import process
								</Typography>
							</div>
							<div style={{ marginBottom: "12px" }}>
								<Button
									type="ghost"
									onClick={generateFailedCSV}
								>
									Download as CSV
								</Button>
							</div>
							{results?.failed?.map((item, index) => {
								return (
									<FailedItemRow item={item} key={index} />
								);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

type SummaryCellTypeProps = {
	/** Height for this cell */
	height: string | number;
	/** Width for this cell */
	width: string | number;
	item: Item | null;
	/** True if the item was created via import */
	isNew: boolean;
	/** True if the item was not created due to another item being placed in its spot during import */
	failed: boolean;
};
/** Single cell for the Summary table */
function SummaryCell({
	height,
	width,
	item,
	isNew,
	failed,
}: SummaryCellTypeProps): JSX.Element {
	let name;
	if (item && !failed) {
		name = item.name;
	}
	return (
		<div
			className={classNames(styles.cell, {
				[styles.cell__disabled]: !item || !isNew,
				[styles.cell__created]: item && isNew,
				[styles.cell__failed]: failed,
			})}
			style={{
				height: height || "",
				width: width || "",
			}}
		>
			<Typography variant="label" className={styles.cellBody}>
				{name}
			</Typography>
		</div>
	);
}

type FailedItemRowTypeProps = {
	item: Item;
};
/** Failed item row */
function FailedItemRow({ item }: FailedItemRowTypeProps): JSX.Element {
	const [isExpanded, setExpanded] = useState(false);
	return (
		<div className={styles.failedItem}>
			<Typography
				className={classNames(styles.flex, styles.failedItemName)}
				style={{ justifyContent: "space-between" }}
			>
				<Typography
					style={{
						flex: "1",
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{item.name}
				</Typography>
				<GenemodIcon
					name="chevron-left"
					style={{
						flex: "0 0 auto",
						transform: isExpanded
							? "rotate(90deg)"
							: "rotate(-90deg)",
					}}
					onClick={() => setExpanded(!isExpanded)}
					stroke="text-primary"
				/>
			</Typography>
			{isExpanded ? (
				<Typography
					variant="caption"
					className={classNames(styles.flex, styles.failedItemInfo)}
				>
					<div>
						<div>Concentration: {item.concentration || "---"}</div>
					</div>
					<div>
						<div>Created On: {item.created_at || "---"}</div>
					</div>

					<div>
						<div>Volume/Mass: {item.volume || "---"}</div>
					</div>
					<div>
						<div>Expires On: {item.expiration_date || "---"}</div>
					</div>
				</Typography>
			) : null}
		</div>
	);
}

/** Evaluates an array of strings
 * and returns true if all strings are
 * formatted in the date format: MM/DD/YYYY
 * */
function checkDateFormat(dates: string[], format: DateFormat): boolean {
	const isInCorrect = dates.some((date) => {
		return date !== "" && !moment(date, format).isValid();
	});
	return !isInCorrect;
}

/** Checks if all values in the values array are
 * non-empty strings
 * @function
 * @param {string[]} values Array of strings to evaluate
 * @returns {boolean} True if all strings in values is non-empty
 * */
function allRowsPopulated(values: string[]) {
	return values.reduce((a, v) => (a === false ? false : !!v), true);
}

/**
 * Saves a string as a CSV file
 */
function saveCSV(filename: string, content: string) {
	// Byte Order Mark to force UTF-8
	// https://github.com/eligrey/FileSaver.js/issues/562#issuecomment-511892803
	const BOM = "\uFEFF";
	const csvBlob = new Blob([content + BOM], {
		type: "text/csv;charset=utf-8",
	});
	saveAs(csvBlob, `${filename}.csv`);
}

const rowContainsValue = (rows: string[]) => {
	for (let i = 0; i < rows.length; i++) {
		const row = rows[i];
		if (row.trim().length) return true;
	}
	return false;
};

const normalizeSheet = (sheet: WorkSheet) => {
	const newSheet: WorkSheet = [];
	for (let i = 0; i < sheet.length; i++) {
		const row = sheet[i];
		const test = rowContainsValue(Object.values(row));
		if (rowContainsValue(Object.values(row))) {
			newSheet.push(row);
		}
	}
	return newSheet;
};

const getNewItemsLocations = (
	emptyCells: CellId[],
	rows?: FileDataRowType[]
) => {
	if (rows?.length) {
		const newEmptyCells = [...emptyCells];
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];
			const locationIndex = newEmptyCells.findIndex(
				(ec) => ec === row.Location
			);
			if (locationIndex >= 0) {
				newEmptyCells.splice(locationIndex, 1);
			} else {
				delete row.Location;
			}
		}
		return newEmptyCells;
	}
	return emptyCells;
};
