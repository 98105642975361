import React, {
	useState,
	useEffect,
	useRef,
	HTMLProps,
	MouseEventHandler,
	MouseEvent,
} from "react";
import classNames from "classnames";
import {
	Typography,
	Button,
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
} from "@components";
import { useInputValidation } from "@helpers/InputHelper.js";
import styles from "./Input.module.scss";
import { Color } from "@common/styles/Colors";
import { useFormContext } from "react-hook-form";

export const MAX_INTEGER = 2147483647;

export type InputValidator = {
	validator: (value: any) => boolean;
	error: string | JSX.Element;
};

export type InputProps = {
	/** ID of the input field */
	id?: string;
	/** Input's name */
	name?: string;
	/** Input value */
	value?: string | number;
	/** The placeholder of the input */
	placeholder?: string;
	/** Input label */
	label?: string | React.ReactNode;
	/** Input label color */
	labelColor?: Color;
	/** Position of the input label */
	labelPosition?: "left" | "top";
	/** Whether to auto-focus the input */
	autoFocus?: boolean;
	/** Whether to disabled the input */
	disabled?: boolean;
	/** Props for the wrapper element of the input */
	wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
	/** Props for the input */
	inputProps?: React.HTMLAttributes<HTMLDivElement>;
	/** Props for the prefix */
	prefixProps?: React.HTMLAttributes<HTMLDivElement>;
	/** Prefix icon or text */
	prefix?: JSX.Element | string;
	/** Suffix icon or text */
	suffix?: JSX.Element | string;
	/** Whether to hide the error message */
	hideError?: boolean;
	/** A forced error state that displays an error message */
	error?: string | boolean | null;
	/** Whether to display the bottom margin. The margin is reserved for error message*/
	gutterBottom?: boolean;
	/** The max characters accepted by the input*/
	maxLength?: number;
	/** Input type */
	type?: string;
	/** A list of local functions that checks the validity of the input value */
	validators?: InputValidator[];
	/** Autocomplete */
	autoComplete?: string;
	/** ref of the input */
	forwardedRef?: React.RefObject<HTMLInputElement>;
	/** Called when the validity on the input changes */
	onValidityChange?: (arg0: boolean) => void;
	/** Called when the value of the input changes */
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	/** Called when users press on ENTER */
	onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	/** Called when the input loses focus */
	onBlur?: any;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	/** Called when the input is on focus */
	onFocus?: any;
	/** Use up and down number arrows */
	useNumberArrows?: boolean;
	/** Number arrows orientation */
	numberArrowsOrientation?: "horizontal" | "vertical";
	/* Icon for the number arrows **/
	arrowIcon?: "caret" | "chevron";
	/** minimum number user can enter */
	minNumber?: number;
	/** maximum number user can enter */
	maxNumber?: number;
	/** remove border around the input */
	noBorder?: boolean;
	containerClassName?: string;
	dataCy?: string;
	suffixClassnames?: string;
};
export default function Input({
	id = "",
	placeholder = "",
	label = "",
	labelColor = "text-secondary",
	labelPosition = "top",
	disabled = false,
	autoFocus = false,
	hideError = false,
	error = "",
	prefix = "",
	suffix = "",
	gutterBottom = true,
	maxLength = 200,
	wrapperProps = {},
	inputProps = {},
	prefixProps = {},
	validators = [],
	type = "text",
	autoComplete,
	onValidityChange = () => {},
	onChange = () => {},
	onPressEnter = () => {},
	onBlur = () => {},
	onKeyDown = () => {},
	onFocus = () => {},
	forwardedRef,
	useNumberArrows = false,
	minNumber = Number.MIN_SAFE_INTEGER,
	maxNumber = MAX_INTEGER,
	noBorder,
	numberArrowsOrientation,
	arrowIcon = "chevron",
	name,
	containerClassName,
	dataCy,
	suffixClassnames,
	...props
}: InputProps): JSX.Element {
	const { value } = props;
	const [inputValue, setValue] = useState(value ? value : "");
	const [isValid, showError] = useInputValidation(
		inputValue,
		[
			...(type === "number"
				? [
						{
							validator: (value: string) => +value >= minNumber,
							error: `Value must be ${minNumber} or greater`,
						},
						{
							validator: (value: string) => +value <= maxNumber,
							error: `Value must be ${maxNumber} or less`,
						},
						{
							validator: (value: string) => +value <= MAX_INTEGER,
							error: "Number is too large",
						},
				  ]
				: []),
			...validators,
		],
		onValidityChange
	);
	const canPressEnter = useRef<boolean>(true);
	const val = "value" in props ? props.value : inputValue;

	/**
	 * inputValid is a boolean that determines the valid state of an input.
	 * The validity of the input can be determined by three different
	 */
	const inputValid = hideError || (!error && isValid);

	/** Sync internal value with props */
	useEffect(() => {
		if (
			value !== null &&
			typeof value !== "undefined" &&
			value !== inputValue
		) {
			setValue(value);
		}
	}, [value, inputValue]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		if (onChange) onChange(e);
		canPressEnter.current = true;
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && onPressEnter && canPressEnter.current) {
			onPressEnter(e);
			canPressEnter.current = false;
		}
	};

	const inputRef = useRef<HTMLInputElement>(null);
	const handleClick = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current && autoFocus) {
				inputRef.current.focus();
			}
		}, 200);
	}, [inputRef, autoFocus]);

	const getNumberSuffix = () => (
		<div
			style={{
				display: "flex",
				position: "relative",
				right: "-8px",
				flexDirection:
					numberArrowsOrientation === "vertical" ? "column" : "row",
				transform:
					numberArrowsOrientation === "vertical"
						? "rotate(180deg)"
						: undefined,
			}}
		>
			<GenemodIcon
				name={arrowIcon === "caret" ? "caret-left" : "chevron-left"}
				style={{
					transform:
						numberArrowsOrientation === "vertical"
							? "rotate(90deg)"
							: undefined,
				}}
				onClick={() => {
					if (!isNaN(+inputValue) && inputValue > minNumber) {
						handleChange({
							target: { value: +inputValue - 1 + "" },
						} as React.ChangeEvent<HTMLInputElement>);
					}
				}}
				dataCy={`${dataCy}-caret-left`}
			/>
			<GenemodIcon
				name={arrowIcon === "caret" ? "caret-right" : "chevron-right"}
				style={{
					transform:
						numberArrowsOrientation === "vertical"
							? "rotate(90deg)"
							: undefined,
				}}
				onClick={() => {
					if (!isNaN(+inputValue) && inputValue < maxNumber) {
						handleChange({
							target: { value: +inputValue + 1 + "" },
						} as React.ChangeEvent<HTMLInputElement>);
					}
				}}
				dataCy={`${dataCy}-caret-right`}
			/>
		</div>
	);

	return (
		<div
			className={classNames(
				wrapperProps.className,
				{
					[styles.labelLeft]: labelPosition === "left",
					[styles.container__error]: !inputValid,
					[styles.container__noError]: hideError,
					[styles.container__noBottomMargin]: gutterBottom === false,
				},
				styles.container
			)}
			style={wrapperProps?.style}
		>
			{label && (
				<Typography
					variant="label"
					className={styles.label}
					color={labelColor}
					bold
				>
					{label}
				</Typography>
			)}
			<div className={styles.content}>
				<div
					className={classNames(
						{
							[styles["inputContainer__error"]]: !inputValid,
							[styles["inputContainer__autocomplete"]]:
								autoComplete,
							[styles.inputContainer__filled]: inputValue !== "",
							[styles.inputContainer__disabled]: disabled,
							[styles.inputContainer__noBorder]: noBorder,
						},
						styles.inputContainer,
						containerClassName
					)}
				>
					{prefix && (
						<div
							{...prefixProps}
							className={classNames(
								styles.prefix,
								prefixProps.className
							)}
							onClick={handleClick}
						>
							{prefix}
						</div>
					)}
					<input
						id={id}
						name={name}
						{...inputProps}
						ref={forwardedRef ? forwardedRef : inputRef}
						className={classNames(
							styles.input,
							inputProps && inputProps.className
						)}
						placeholder={placeholder}
						value={val}
						onChange={disabled ? undefined : handleChange}
						onKeyPress={handleKeyPress}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
						onFocus={onFocus}
						maxLength={maxLength}
						autoFocus={autoFocus}
						type={type}
						autoComplete={autoComplete}
						data-cy={dataCy}
					/>
					{(suffix || useNumberArrows) && (
						<div
							className={classNames(
								styles.suffix,
								suffixClassnames
							)}
							onClick={handleClick}
						>
							{suffix || getNumberSuffix()}
						</div>
					)}
				</div>
				{!inputValid && (
					<Typography
						className={styles.inputContainerError}
						variant="caption"
						color="red-contrast"
						data-cy={dataCy && dataCy + "-error"}
					>
						{error ? (error === true ? "" : error) : showError}
					</Typography>
				)}
			</div>
		</div>
	);
}

type Props = {
	name: string;
	label?: string;
	wrapperProps?: HTMLProps<HTMLDivElement>;
} & HTMLProps<HTMLInputElement>;

export const ValidationInput = ({
	name,
	label,
	wrapperProps,
	...props
}: Props): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	return (
		<div {...wrapperProps}>
			{label && (
				<Typography variant="label" className={styles.label} bold>
					{label}
				</Typography>
			)}
			<div
				{...wrapperProps}
				className={classNames(
					{ [styles["inputContainer__error"]]: errors[name] },
					styles.inputContainer
				)}
			>
				<input
					{...register(name)}
					{...props}
					className={classNames(styles.input, props.className)}
				/>
			</div>
			{errors[name] && (
				<Typography
					style={{ marginTop: -16 }}
					variant="caption"
					color="red-contrast"
				>
					{errors[name]?.message}
				</Typography>
			)}
		</div>
	);
};

export function INPUT_DEMO(): JSX.Element {
	const [isValid, setValid] = useState(true);
	const handleValidityChange = (value: boolean) => {
		setValid(value);
	};

	const [text, setText] = useState("");
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
	};

	const [text2, setText2] = useState("");
	const [error, setError] = useState(null);
	const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText2(e.target.value);
		setError(null);
	};
	const printText = () => {
		if (text2 !== text2.toUpperCase()) {
			throw new Error("The value is not all caps");
		}
		console.log(text2);
	};
	const handleSubmit = () => {
		try {
			printText();
		} catch (err: any) {
			setError(err.message);
		}
	};

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Basic usage" description="Basic input element">
					<Input placeholder="Enter text here" />
					<Input placeholder="Placeholder text" label="Label" />
					<div style={{ width: "200px" }}>
						<Input
							placeholder="Label top"
							label="Don't enter text here"
							value="Text :)"
							validators={[
								{
									validator: (val) => !val,
									error: "No text here",
								},
							]}
						/>
					</div>
				</Demo>
				<Demo
					title="Label positions"
					description="Use the labelPosition prop to control the position of the label. Options are left or top. Default is top"
				>
					<div style={{ width: "300px" }}>
						<Input
							placeholder="Label on the left"
							label="label"
							labelPosition="left"
						/>
					</div>
					<div style={{ width: "200px" }}>
						<Input placeholder="Label top" label="label" />
					</div>
				</Demo>
				<Demo
					title="Prefix and suffix"
					description="Add content to the beginning or end of your input"
				>
					<Input
						wrapperProps={{ style: { width: 240 } }}
						label="Price"
						placeholder="Enter a price in USD"
						prefix="$"
						validators={[
							{
								validator: (val) => !isNaN(val),
								error: "Please enter a number",
							},
						]}
					/>
					<Input
						wrapperProps={{ style: { width: 240 } }}
						label="Price"
						placeholder="Enter a price in USD"
						prefix="$"
						validators={[
							{
								validator: (val) => !isNaN(val),
								error: "Please enter a number",
							},
						]}
					/>
					<Input
						wrapperProps={{ style: { width: 240 } }}
						label="Temperature"
						placeholder="Enter a temperature"
						suffix="°C"
					/>
				</Demo>
				<Demo
					title="Input validation"
					description="Detect errors using input validation."
				>
					<Input
						label="No text allowed"
						validators={[
							{
								validator: (val) => !val,
								error: "Text is not allowed here",
							},
						]}
						placeholder="Don't enter text here"
						value="Hello"
					/>
					<Input
						label="UPPERCASE ONLY"
						placeholder="ENTER UPPERCASE LETTERS"
						validators={[
							{
								validator: (text) =>
									text === text.toUpperCase(),
								error: "ONLY UPPERCASE LETTERS",
							},
						]}
					/>
				</Demo>
				<Demo
					title="Event Listeners"
					description="Setup callback functions to listen to validity and input changes"
				>
					<h4>
						Input status:{" "}
						<span style={{ color: isValid ? "green" : "red" }}>
							{isValid ? "valid" : "invalid"}
						</span>
					</h4>
					<Input
						label="Numbers only"
						placeholder="Enter a number"
						validators={[
							{
								validator: (text) => !isNaN(text),
								error: "Enter numbers only!",
							},
						]}
						onValidityChange={handleValidityChange}
					/>
					<h4>Current text: {text}</h4>
					<Input
						label="onChange listener"
						placeholder="Enter some text"
						onChange={handleChange}
					/>
				</Demo>
			</DemoSection>
			<DemoSection>
				<Demo
					title="Show error on submission"
					description="Retrieving error from a fucntion or backend"
				>
					<p>
						Input must be more than 9 characters as we type, and on
						submit, we will check if the input is all caps.
					</p>
					<Input
						value={text2}
						onChange={handleChange2}
						validators={[
							{
								validator: (val) => val.trim().length < 9,
								error: "No more than 9 characters",
							},
						]}
						error={error}
					/>
					<Button onClick={handleSubmit}>Submit</Button>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
