import {
	InfiniteScrollingConfig,
	useInfiniteScrolling,
} from "@common/helpers/Hooks";
import { Event } from "@common/types";
import {
	Button,
	EmptyState,
	EventLogCard,
	LoadingSpinner,
	Typography,
} from "@components";
import RestrictionGradientLayout from "@containers/Freezer/components/RestrictionGradientLayout/RestrictionGradientLayout";
import { useItemGroup } from "@containers/Freezer/table/BoxTableHooks";
import { emphasizeText } from "@helpers/Formatters";
import { groupBy } from "@helpers/GroupBy";
import { EventFilters, useLazyEventsQuery } from "@redux/events";
import moment from "moment";
import React, { useEffect, useState } from "react";
import EMPTY_STATE_IMG_DARK from "./assets/activity-empty-dark.png";
import EMPTY_STATE_IMG_LIGHT from "./assets/activity-empty-light.png";
import styles from "./index.module.scss";

export type ActivityFilter = Omit<EventFilters, "page">;
/**
 * Props for the ActivityLog component
 */
type ActivityLogProps = {
	/** Name of the object: (Freezer/Shelf/Rack/Box).name */
	name?: string;
	/** Type of the object -- Freezer, Shelf, Rack, Box, etc */
	type?: string;
	/** limit of the activities to show  */
	activityLimit?: boolean;
	/** called when user click view All activities button */
	onViewAllActivities?: () => void;
	/** Activity filter to fetch Events */
	activityFilter: ActivityFilter;
	onDoneLoading?: () => void;
};

const ACTIVITY_LIMIT = 6;
export function ActivityLog({
	name,
	type,
	activityLimit = false,
	onViewAllActivities = () => {},
	activityFilter,
	onDoneLoading,
}: ActivityLogProps): JSX.Element {
	const loaderRef = React.useRef<HTMLDivElement>(null);
	const [activitiesByDate, setActivitiesByDate] = useState<
		Record<string, Event[]>
	>({});
	const [nRestrictedActivities, setNRestrictedActivity] = useState(0);
	const [showViewAll, setShowViewAll] = useState(false);
	const [totalActivities, setTotalActivities] = useState(0);

	const [fetchEvents] = useLazyEventsQuery();

	const { setItemIdInUrl: setItemId } = useItemGroup();

	/**
	 * Combine current state value `activitiesByDate` with coming values `newActivities`
	 */
	const setActivityByDateWith = (newActivity: Record<string, Event[]>) => {
		setActivitiesByDate((_prev) => {
			Object.keys(newActivity).forEach((eventData) => {
				if (eventData in activitiesByDate) {
					_prev[eventData].push(...newActivity[eventData]);
				} else {
					_prev[eventData] = newActivity[eventData];
				}
			});
			return _prev;
		});
	};
	/**
	 * Called when the user reaches the bottom of the activity log
	 */
	const handleScrollingFetch: InfiniteScrollingConfig["callback"] = ({
		page,
		pageSize,
		setLoading,
		setPage,
		setEnabled,
	}) => {
		fetchEvents({
			app: "freezer",
			...activityFilter,
			page,
			page_size: pageSize,
		})
			.unwrap()
			.then((data) => {
				onDoneLoading?.();
				if (!data) return;
				const events = data.results;
				setTotalActivities(
					(totalActivities) => totalActivities + events.length
				);
				const groupedEvents = groupBy(events, (event) =>
					moment(event.timestamp).format("MM/DD/YYYY")
				);
				setActivityByDateWith(groupedEvents);
				if (!data.next) {
					setEnabled(false);

					// Display the number of restricted activities on the last page
					if (data.num_restricted_activities) {
						setNRestrictedActivity(data.num_restricted_activities);
					}
				} else {
					if (activityLimit) {
						setEnabled(false);
						setShowViewAll(true);
					} else {
						setPage(page + 1);
					}
				}
			})
			.finally(() => setLoading(false));
	};

	const infScrolling = useInfiniteScrolling({
		target: loaderRef,
		callback: handleScrollingFetch,
		initOptions: {
			pageSize: activityLimit ? ACTIVITY_LIMIT : 50,
			firstFetch: true,
			initialLoading: true,
		},
	});

	// if there is activityLimit and totalActivities + RestrictedActivites <= ACTIVITY_LIMIT, hide upgradeSection.
	const hideUpgradeSection =
		activityLimit &&
		totalActivities + nRestrictedActivities <= ACTIVITY_LIMIT;

	useEffect(() => {
		infScrolling.setEnabled(true);
		infScrolling.setPage(1);
		setActivitiesByDate({});
	}, [JSON.stringify(activityFilter)]);

	return (
		<div className={styles.activity}>
			{type && (
				<Typography
					variant="caption"
					color="text-tertiary-v2"
					style={{ marginBottom: 8 }}
				>
					{type}
				</Typography>
			)}
			{name && (
				<Typography
					bold
					variant="headline"
					style={{ marginBottom: 16 }}
				>
					{name}
				</Typography>
			)}
			{!infScrolling.loading && !Object.keys(activitiesByDate).length && (
				<EmptyState
					style={{ marginTop: "10px" }}
					img={{
						light: EMPTY_STATE_IMG_LIGHT,
						dark: EMPTY_STATE_IMG_DARK,
					}}
					title="No activities found"
					description="Your actions from the past month will be displayed here"
				/>
			)}
			{Object.keys(activitiesByDate).map((activityDate, index) => (
				<div
					className={styles.dateContainer}
					key={`date-${activityDate}-${index}`}
				>
					<Typography
						variant="body2"
						bold
						className={styles.activityTimestamp}
					>
						{formatActivityTime(activityDate)[0]}
					</Typography>
					{activitiesByDate[activityDate].map((event, index) => (
						<EventLogCard
							key={`${event.uuid}-${index}`}
							event={event}
							avatarSize={24}
							{...(event.model === "Item" &&
								event.actions !== "deleted" && {
									onClick: () => setItemId(event.object_id),
									cursor: "pointer",
								})}
						/>
					))}
				</div>
			))}
			{nRestrictedActivities ? (
				<>
					{!hideUpgradeSection && (
						<RestrictionGradientLayout
							title={emphasizeText`Upgrade to unlock ${"activity history"}`(
								"accent-subtle"
							)}
							hideGradient={nRestrictedActivities < 1}
						/>
					)}
				</>
			) : (
				showViewAll && (
					<Button
						style={{ marginBottom: 24 }}
						onClick={onViewAllActivities}
						type="text"
					>
						View all activities
					</Button>
				)
			)}
			<LoadingSpinner centered loading={infScrolling.loading} />
			<div ref={loaderRef} key="loader" />
		</div>
	);
}

/**
 * Converts a timestamp into human readable form for the activity log component
 */
export function formatActivityTime(date: string) {
	const splitDate = date.split("/");
	const temp = date.includes("/")
		? new Date(
				Number(splitDate[2]),
				Number(splitDate[0]) - 1,
				Number(splitDate[1])
		  )
		: new Date(date);
	const now = new Date();
	const time = temp.toLocaleTimeString(navigator.language, {
		hour: "numeric",
		minute: "2-digit",
	});
	if (now.toLocaleDateString() === temp.toLocaleDateString()) {
		// Same day
		return ["Today", time];
	} else if (temp.getDate() === now.getDate() - 1) {
		// Yesterday
		return ["Yesterday", time];
	} else {
		// Any other day
		return [
			temp.toLocaleDateString(navigator.language, {
				year: "numeric",
				month: "long",
				day: "numeric",
			}),
			time,
		];
	}
}
