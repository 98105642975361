import { EditorDocumentTableId } from "@common/components/Editor/TableV2/types";
import {
	GenemodDocumentUUID,
	OrganizationId,
	OrganizationUser,
	OrganizationUserId,
} from "@common/types";
import { NominalType } from "@helpers/TypeHelpers";
import { NominalUuid } from "../helpers/TypeHelpers";
import { WorkspaceId } from "./AdminEnterprisePortal";
import { ISOString } from "./Date";
import { ExperimentId } from "./ExperimentId";
import { ProjectColorEnum, ProjectId } from "./ProjectProps";
import { SortByOptions } from "./Search";
import { Avatar } from "./User";

export type FolderId = NominalType<"ProjectFolderId", number>;

export type Folder = {
	id: FolderId;
	name: string;
	created_at: ISOString;
	created_by: Avatar;
	updated_at: ISOString;
	updated_by: Avatar;
	workspace: WorkspaceId;
	parent_project: ProjectId;
	parent_folder: FolderId | null;
	parent_folder_name: string | null;
	is_archived: boolean;
	experiment_count: number;
	collaborators: OrganizationUser[];
};

export enum ExperimentFileStatus {
	BACKLOG = 0,
	IN_PROGRESS,
	IN_REVIEW,
	COMPLETE,
}

export type ExperimentFileList = {
	id: ExperimentId;
	name: string;
	parent_folder: FolderId | null;
	updated_at: ISOString;
	created_at: ISOString;
	status: ExperimentFileStatus;
	start_date: ISOString;
	end_date: ISOString;
	owner: {
		first_name: string;
		last_name: string;
	};
};

export enum ExperimentFileReviewStatus {
	PENDING = 0,
	APPROVED,
	REJECTED,
}

export type ExperimentFileReviewer = {
	id: number;
	reviewer: OrganizationUser;
	status: ExperimentFileReviewStatus;
	requested_by: OrganizationUserId;
};

export type ExperimentFile = {
	id: ExperimentId;
	is_archived: boolean;
	created_at: ISOString;
	updated_at: ISOString;
	name: string;
	org: number;
	workspace: WorkspaceId;
	parent_project: ProjectId;
	parent_folder: FolderId | null;
	parent_folder_name: string | null;
	parent_folder_is_archived: boolean | null;
	parent_project_name: string | null;
	document: GenemodDocumentUUID;
	document_type: string;
	color: ProjectColorEnum;
	key: string;
	created_by: Avatar;
	updated_by: Avatar;
	status: ExperimentFileStatus;
	start_date: ISOString;
	end_date: ISOString;
	owner: OrganizationUser;
	owner_id: OrganizationUserId;
	reviewers: ExperimentFileReviewer[];
	review_status: ExperimentFileReviewStatus;
};

export type ExperimentFileOrdering = SortByOptions<
	"name" | "created_by" | "created_at" | "updated_at"
>;

export type ExperimentFileSearchPayload = Partial<{
	page: number;
	page_size: number;
	search: string;
	parent_project: ProjectId;
	parent_project_in: ProjectId[];
	parent_folder: FolderId;
	parent_folder_in: FolderId[];
	id__in: ExperimentId[];
	ordering: ExperimentFileOrdering;
	with_documents: boolean;
}>;

export type FolderSearchPayload = Partial<{
	page: number;
	page_size: number;
	search: string;
	parent_project: ProjectId;
	parent_project_in: ProjectId[];
	include_filter_options: boolean;
}>;

export const EXPERIMENTFILE_ACTIVITY_TYPES = Object.freeze({
	TEXT_EDIT: "TEXT_EDIT",
	TABLE: "TABLE",
	ATTACHMENT: "ATTACHMENT",
	PROTOCOL: "PROTOCOL",
	CHANGE_STATUS: "CHANGE_STATUS",
	REJECT: "REJECT",
	APPROVED: "APPROVE",
	WITHDRAW: "WITHDRAW",
});

export type ExperimentFileActivityTypeValues =
	(typeof EXPERIMENTFILE_ACTIVITY_TYPES)[keyof typeof EXPERIMENTFILE_ACTIVITY_TYPES];

export type ExperimentFileActivityMetaData = {
	table_id?: EditorDocumentTableId;
	table_name?: string;
	old_status?: ExperimentFileStatus;
	new_status?: ExperimentFileStatus;
	comment?: string;
	reviewer?: OrganizationUserId;
	was_approved?: boolean;
};

export type ExperimentFileActivityId = NominalUuid<"ExperimentFileActivityId">;

export type ExperimentFileActivity = {
	id: ExperimentFileActivityId;
	created_by: Avatar;
	created_at: ISOString;
	title_message: string;
	detail_message: string;
	type: ExperimentFileActivityTypeValues;
	organization: OrganizationId;
	experiment: ExperimentId;
	meta_data: ExperimentFileActivityMetaData;
};

export type ExperimentFileActivitySearchPayload = Partial<{
	search: string;
	ordering: SortByOptions<"created_at">;
	experiment: ExperimentId;
	experiment__in: ExperimentId[];
	type: ExperimentFileActivityTypeValues;
	type__in: ExperimentFileActivityTypeValues[];
}>;

export type ExperimentFileActivityCreatePayload = {
	title_message: string;
	detail_message: string;
	table_id?: EditorDocumentTableId;
	type: ExperimentFileActivityTypeValues;
	experiment: ExperimentId;
	meta_data: ExperimentFileActivityMetaData;
};

/**
 * Use some heuristics to determine if the experiment file is newly created.
 */
export const isExperimentFileNewlyCreated = (
	experiment: ExperimentFile | undefined
) => experiment?.name === "Untitled";
