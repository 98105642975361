import { TabListTabText } from "@common/components/TabList/TabList";
import {
	ActivityLog,
	ButtonV2,
	EditableSidePanel,
	Input,
	Modal,
	Notification,
	SidePanel,
	TabList,
	TextArea,
	Typography,
} from "@components";
import { SearchSettingTabContent } from "@containers/Freezer/components/SearchSettings/SearchSettingsPanel";
import { isItemGroup as _isItemGroup } from "@common/types";
import { truncArgs } from "@helpers/Formatters";
import { useItemTypeNameMappingsFn } from "@helpers/Hooks";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { conditionalArrayElements, IdsInConstType } from "@helpers/TypeHelpers";
import { useCommonModalState } from "@redux/CommonModals/hooks";

import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import React, { useEffect, useState } from "react";
import AnalyticsTabContent from "../AnalyticsTabContent/AnalyticsTabContent";
import "./BoxReagentSetting.scss";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	useBoxQuery,
	useBoxPatchMutation,
	useLazyGetBoxStorageSummaryQuery,
} from "@redux/inventory/Box";
import { EditableSidePanelSettings } from "@common/components/EditableSidePanel";
import InputV3 from "@common/components/InputV3/InputV3";

export type BoxReagentSettingsTabs =
	| "details"
	| "activity"
	| "analytics"
	| "customize";

export type BoxStorageProps = {
	name: string;
	items: number;
};

export type ItemGroupStorageProps = {
	name: string;
	items: number;
};

export default function BoxReagentSetting(): JSX.Element {
	const {
		genericallyNamed: { isVisible, closePanel: _closePanel, data },
	} = useCommonPanelState("boxSettings");
	const { data: box } = useBoxQuery(data.id || skipToken);

	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const [name, setName] = React.useState("");
	const [tempName, setTempName] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [tempDescription, setTempDescription] = React.useState("");
	const isItemGroup = box ? _isItemGroup(box) : false;
	const type = isItemGroup ? "Item group" : "Box";

	const [isEdit, _setEdit] = React.useState(false);
	const setEdit = (v: boolean) => {
		_setEdit(v);
		if (v) {
			setTempName(name);
			setTempDescription(description);
		}
	};
	const [showUnsavedModal, setShowUnsavedModal] = React.useState(false);
	const [boxStorageData, setBoxStorageData] = React.useState<
		BoxStorageProps[]
	>([]);
	const [itemGroupStorageData, setItemGroupStorageData] = React.useState<
		ItemGroupStorageProps[]
	>([]);
	const [key, setKey] = useState<BoxReagentSettingsTabs>(
		data.defaultTab || "details"
	);
	const [updateBox] = useBoxPatchMutation();
	const [getBoxSummary] = useLazyGetBoxStorageSummaryQuery();

	const itemTypeNameMappingsFn = useItemTypeNameMappingsFn();
	const { is_limit_reached: restrictFreezerAnalytics } =
		useFeatureRestrictionHook("freezer_analytics");
	const { id, rows, columns, item_type } = box
		? box
		: {
				id: -1,
				rows: 9,
				columns: 9,
				item_type: 1,
		  };
	const viewOnly = !!box?.is_archived;

	const { openCustomizeBoxModal } = useCommonModalState("customizeBoxModal");

	useEffect(() => {
		if (isVisible && box) {
			setName(box?.name || "");
			setKey(data.defaultTab || "details");
			getBoxStorageData();
		}
	}, [isVisible, box]);

	const closePanel = () => {
		if (isVisible && hasUnsavedChanges()) {
			setShowUnsavedModal(true);
		} else {
			setEdit(false);
			_closePanel();
		}
	};

	const handleCancel = () => {
		setEdit(false);
	};

	const hasUnsavedChanges = () => {
		return (
			isEdit &&
			(tempName.trim() !== name.trim() || tempDescription !== description)
		);
	};

	const validateForm = () => {
		return !!tempName.trim().length;
	};

	const handleSubmit = () => {
		if (!validateForm() || !box) return;

		const failure = (err?: string) => {
			console.log("Failed to update box", err);
			Notification.warning({
				message: "Failed to update box",
			});
		};
		updateBox({
			id: box.id,
			name: tempName.trim(),
		})
			.unwrap()
			.then((box) => {
				setName(box.name);
				setEdit(false);
				SegmentTrackEvent(SegmentFreezerEvents.BOX_EDIT, {
					id: box.id,
					is_item_group: false,
				});
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${box.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch((err) => {
				console.log(err);
				failure();
			});
	};

	const getBoxStorageData = () => {
		getBoxSummary({ boxId: id }).then((res) => {
			const data = res.data;
			if (!data) return;
			if (!isItemGroup) {
				setBoxStorageData(data);
			} else {
				setItemGroupStorageData(data);
			}
		});
	};

	const settingContent = [
		{
			key: "details",
			tabtitle: <TabListTabText tabTitle="Details" />,
			tabcontent: (
				<div className="box-reagent-settings">
					{!isEdit ? (
						<>
							<EditableSidePanel
								title={name}
								type={
									type.toLowerCase() as EditableSidePanelSettings
								}
								showEdit={() => setEdit(true)}
								viewOnly={viewOnly}
								dataCy="box-reagent-editable-settings"
								openCustomizeBoxModal={() =>
									openCustomizeBoxModal({
										boxId: id,
									})
								}
							>
								{isItemGroup && description && (
									<div>
										<Typography
											withLinks
											className="sidePanel-description"
										>
											{description}
										</Typography>
									</div>
								)}
								<table>
									<div className="sidePanel-infoView-layout">
										<Typography className="sidePanel-infoView-leftSide">
											Item type
										</Typography>
										<Typography>
											{itemTypeNameMappingsFn(item_type)}
										</Typography>
									</div>
									{isItemGroup ? (
										<div className="sidePanel-infoView-layout">
											<Typography className="sidePanel-infoView-leftSide">
												Container
											</Typography>
											<Typography></Typography>
										</div>
									) : (
										<>
											<div
												className="sidePanel-infoView-layout"
												data-cy="box-reagent-settings-dim"
											>
												<Typography className="sidePanel-infoView-leftSide">
													Dimension
												</Typography>
												<Typography>
													{rows} x {columns}
												</Typography>
											</div>
										</>
									)}
								</table>
							</EditableSidePanel>
						</>
					) : (
						<EditableSidePanel
							edit={isEdit}
							showEdit={() => {
								if (isVisible && hasUnsavedChanges()) {
									setShowUnsavedModal(true);
								} else {
									handleCancel();
								}
							}}
							type={
								type.toLowerCase() as EditableSidePanelSettings
							}
							onConfirm={handleSubmit}
							validateForm={validateForm}
							inlineEdit
							dataCy="box-reagent-editable-settings"
						>
							<div className="edit-form">
								<InputV3
									value={tempName}
									onChange={(e) =>
										setTempName(e.target.value)
									}
									required
									dataCy="box-reagent-settings-name-input"
								/>
							</div>
						</EditableSidePanel>
					)}
				</div>
			),
		},
		{
			key: "activity",
			tabtitle: <TabListTabText tabTitle="Activity" />,
			tabcontent: (
				<ActivityLog
					activityFilter={{
						location_model: "Box",
						location_object_id: id,
					}}
				/>
			),
		},
		{
			key: "analytics",
			tabtitle: (
				<TabListTabText
					tabTitle="Analytics"
					withUpgradeIcon={restrictFreezerAnalytics && !isItemGroup}
				/>
			),
			tabcontent: (
				<AnalyticsTabContent
					title={`Distribution in ${type}`}
					showHeader={false}
					storageSummary={{
						dataSource: isItemGroup
							? itemGroupStorageData
							: boxStorageData,
						columns: [
							{
								title: "Total space",
								dataIndex: "name",
								key: "name",
								ellipsis: true,
							},
							{
								title: "Count",
								dataIndex: "items",
								key: "items",
								align: "center",
								width: 88,
							},
						],
						mockDataSource: isItemGroup
							? itemGroupStorageData
							: boxStorageData,
					}}
					hideUpgradeSection={isItemGroup}
				/>
			),
		},
		...conditionalArrayElements(isItemGroup)({
			key: "customize",
			tabtitle: <TabListTabText tabTitle="Preference" />,
			tabcontent: (
				<SearchSettingTabContent
					visible={isVisible}
					selectedItemTypeID={item_type}
				/>
			),
		} as const),
	] as const;

	return (
		<>
			<SidePanel
				isVisible={isVisible}
				onClose={() => closePanel()}
				header={isItemGroup ? "Item group settings" : "Box settings"}
				dataCy="box-reagent-settings"
				showIcon={false}
			>
				<TabList
					inSettings
					tabListItems={settingContent as any}
					largeSize={true}
					activeKey={key}
					onChange={(k: BoxReagentSettingsTabs) => setKey(k)}
				/>
			</SidePanel>
			<UnsavedChangeModal
				isVisible={showUnsavedModal}
				setIsVisible={setShowUnsavedModal}
				closePanel={closePanel}
				handleCancel={handleCancel}
			/>
		</>
	);
}

function UnsavedChangeModal(props: {
	isVisible: boolean;
	setIsVisible: (visible: boolean) => void;
	handleCancel: (cancel: boolean) => void;
	closePanel: () => void;
}) {
	return (
		<Modal
			className="unsaved-change-modal"
			visible={props.isVisible}
			title="Unsaved changes"
			okText="Continue editing"
			cancelText="Discard"
			width={496}
			onOk={() => props.setIsVisible(false)}
			onCancel={() => {
				props.handleCancel(false);
				props.closePanel();
				props.setIsVisible(false);
			}}
		>
			Your changes will not be saved
		</Modal>
	);
}
